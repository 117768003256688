/* eslint-disable padding-line-between-statements */
/* eslint-disable react/react-in-jsx-scope */
import { Suspense, lazy, FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ROUTES } from 'app/constants/routes';
import TopBarProgress from 'react-topbar-progress-indicator';
import AddListingLayout from 'app/components/Layout/AddListingLayout';
import PropertyInformation from 'app/pages/AddListing/PropertyInformation';
import MarketingDescription from 'app/pages/AddListing/MarketingDescription';
import PersonDetails from 'app/pages/AddListing/PersonDetails';
import InvestmentDetails from 'app/pages/AddListing/InvestmentDetails';
import Gallery from 'app/pages/AddListing/Gallery';
import Confidentiality from 'app/pages/AddListing/Confidentiality';
import Preview from 'app/pages/AddListing/Preview';

import DashboardLayout from 'app/components/Layout/DashboardLayout';
import { Contacts } from 'app/pages/Dashboard/Contacts/Contacts';
import { ReminderWrapper } from 'app/pages/Dashboard/Reminder/Reminder';
import { MyProperty } from 'app/pages/Dashboard/MyProperty/MyProperty';

import { WithChildren } from 'app/constants/models';

const PrivateRoutes = () => {
  const ListingPage = lazy(() => import('../pages/Listing/ListingPage'));
  return (
    <Routes>
      <Route path="auth/*" element={<Navigate to="/" />} />
      <Route element={<AddListingLayout />}>
        <Route path={ROUTES.addListingPropertyInfo} element={<PropertyInformation />} />
        <Route path={ROUTES.addListingMarketingDesc} element={<MarketingDescription />} />
        <Route path={ROUTES.addListingInvestmentDetails} element={<InvestmentDetails />} />
        <Route path={ROUTES.addListingGallery} element={<Gallery />} />
        <Route path={ROUTES.addListingConfidentiality} element={<Confidentiality />} />
        <Route path={ROUTES.addListingPersonDetails} element={<PersonDetails />} />
        <Route path={ROUTES.addListingPreview} element={<Preview />} />
      </Route>
      <Route element={<DashboardLayout />}>
        <Route path={ROUTES.dashboard.contacts} element={<Contacts />} />
        <Route path={ROUTES.dashboard.reminders} element={<ReminderWrapper />} />
        <Route path={ROUTES.dashboard.myProperties} element={<MyProperty />} />
      </Route>
      <Route
        path="listings/*"
        element={
          <SuspensedView>
            <ListingPage />
          </SuspensedView>
        }
      />
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = '#21364B';
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

/* eslint-disable no-bitwise */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, ReactNode } from 'react';
import { Button, Drawer, Box, TextField, CircularProgress } from '@mui/material';
import CustomInput from 'app/components/CustomInput';
import { drawerBody, drawerTextArea } from './Details.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { Setting4, Calendar, ProfileCircle, Flag, RowVertical, Danger } from 'app/components/SvgIcons';
import { useListView } from '../../core/ListViewProvider';
import { useQuery } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { isNotEmpty } from 'app/constants/helpers';
import { getReminderByID } from '../../core/_requests';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import logger from 'app/utils/logger';
import format from 'date-fns/format';
import { typesData, priorityData } from '../../core/_helper';
import { Contact } from '../../core/_models';
import { priorityIcons } from '../reminderList/ReminderCard';

const Details = () => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    isOpenDetails,
    setIsOpenDetails,
    setIsOpenCreateDrawer,
  } = useListView();

  const onClose = () => {
    setItemIdForUpdate(undefined);
    setIsOpenDetails(false);
  };

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const {
    isFetching,
    data: reminder,
    error,
  } = useQuery(
    `${QUERIES.CONTACT_LIST}-contact-${itemIdForUpdate}`,
    () => {
      return getReminderByID(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        onClose();
        logger.error(err);
      },
    }
  );

  let renderDetails: ReactNode;
  if (!isFetching && !error && reminder)
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-end mb-4">
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onClose}>
            <ClearIcon />
          </Button>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <h3 className="fw-bold fs-5 text-black p-0 m-0">{reminder.subject}</h3>
          </div>
          <div className="d-flex align-items-center">
            <Button
              variant="text"
              className="p-0 m-0 ms-1 text-end"
              startIcon={<Setting4 className="fs-5" />}
              onClick={() => {
                setItemIsUpdate(true);
                setIsOpenCreateDrawer(true);
                setIsOpenDetails(false);
              }}
            >
              Edit
            </Button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Calendar className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Date</span>
          </div>
          <span className="fs-6 text-black ps-1">{format(new Date(reminder.time), 'MM.dd.yyyy')}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <RowVertical className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Type</span>
          </div>
          <Button className="bg-light-success text-success fw-bold ms-1">{typesData[reminder.type]}</Button>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Flag className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Priorty</span>
          </div>
          <Button
            className={`text-white ${priorityData[reminder.priority].class}`}
            startIcon={priorityIcons[reminder.priority]}
          >
            {priorityData[reminder.priority].status}
          </Button>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <ProfileCircle className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Contact</span>
          </div>
          <AvatarGroup max={4}>
            {reminder?.contacts?.map((contact: Contact, index: number) => (
              <Avatar key={index} alt={`${contact.name} ${contact.lastName}`} src="/static/images/avatar/1.jpg" />
            ))}
          </AvatarGroup>
        </div>

        <div className="w-100 my-3">
          <TextField multiline={true} sx={drawerTextArea} placeholder="Add a note" label="Note" value={reminder.note} />
        </div>

        <div className="w-100 mt-4 mb-3">
          <CustomInput label="Related Deal" value="57th AVE" />
        </div>

        <div className="w-100 d-flex flex-column">
          <h3 className="fs-5 text-black p-0 m-0 mb-2">Related Property</h3>

          <div className="p-3 border rounded mb-2">
            <div className="d-flex flex-column">
              <h4 className="fs-6 text-body-secondary">5908 STATE ROUTE 224 ALPINE NY 14805 - 9526 USA</h4>
              <small className="text-body-tertiary">Residential</small>
            </div>
          </div>
          <div className="p-3 border rounded mb-2">
            <div className="d-flex flex-column">
              <h4 className="fs-6 text-body-secondary">5908 STATE ROUTE 224 ALPINE NY 14805 - 9526 USA</h4>
              <small className="text-body-tertiary">Residential</small>
            </div>
          </div>
        </div>
      </Box>
    );
  else
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress />
        </div>
      </Box>
    );

  return (
    <>
      <Drawer anchor="right" variant="temporary" open={isOpenDetails} onClose={onClose}>
        {renderDetails}
      </Drawer>
    </>
  );
};

export { Details };

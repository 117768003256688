/* eslint-disable curly */
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { moveToSchema } from './_schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { moveTo } from './_requests';
import { useListView } from './ListViewProvider';
import logger from 'app/utils/logger';

const useMoveToList = (tools: { [key: string]: any }) => {
  const queryClient = useQueryClient();
  const { selected, clearSelected } = useListView();

  const {
    handleSubmit,
    control,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(moveToSchema) });

  const moveToMutation = useMutation((data: any) => moveTo(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_LIST}-MOVE-TO`]);
      setValue('listId', undefined);
      clearSelected();
      reset();
      tools.onOpenSnackbar(true);
      tools.onCloseMoveToDrawer(false);
    },
    onError: () => {
      tools.onOpenSnackbar(true);
    },
  });

  const onSubmit = handleSubmit((data: any) => {
    const body = {
      listId: data?.listId?.id,
      contacsId: selected,
    };
    moveToMutation.mutate(body);
  });

  return {
    control,
    register,
    getValues,
    errors,
    reset,
    onSubmit,
    moveToMutation,
  };
};

export { useMoveToList };

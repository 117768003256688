import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOwnerlookup = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="M14.383 14.328a7.875 7.875 0 1 0-.799.798l2.892 2.892a.564.564 0 1 0 .798-.8zM1.688 9.18a6.75 6.75 0 1 1 13.5 0 6.75 6.75 0 0 1-13.5 0"
    />
    <path
      fill="currentColor"
      d="M14.063 9.743a.56.56 0 0 0 .562-.562 6.187 6.187 0 0 0-6.187-6.188.563.563 0 1 0 0 1.125A5.06 5.06 0 0 1 13.5 9.181a.563.563 0 0 0 .563.562"
    />
  </svg>
);
export default SvgOwnerlookup;

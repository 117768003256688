/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/array-type */
import { FC, useState, createContext, useContext, useMemo } from 'react';
import { ID, initialListView, ListViewContextProps, WithChildren } from 'app/constants/models';
import {
  groupingOnSelect,
  groupingOnSelectAll,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
} from 'app/constants/helpers';
import { useQueryResponse, useQueryResponseData } from './QueryResponseProvider';

const ListViewContext = createContext<ListViewContextProps>(initialListView);

const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected);
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate);
  const [itemIsUpdate, setItemIsUpdate] = useState<boolean>(initialListView.itemIsUpdate);
  const [isOpenCreateDrawer, setIsOpenCreateDrawer] = useState<boolean>(initialListView.isOpenCreateDrawer);
  const [isOpenDetails, setIsOpenDetails] = useState<boolean>(initialListView.isOpenDetails);
  const { isLoading } = useQueryResponse();
  const data = useQueryResponseData();
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data]);
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected]);

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        itemIsUpdate,
        setItemIdForUpdate,
        setItemIsUpdate,
        isOpenCreateDrawer,
        setIsOpenCreateDrawer,
        isOpenDetails,
        setIsOpenDetails,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected);
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data);
        },
        clearSelected: () => {
          setSelected([]);
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
};

const useListView = () => useContext(ListViewContext);

export { ListViewProvider, useListView };

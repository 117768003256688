/* eslint-disable curly */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { contactSchema } from './_schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { MyProperty, ContactList } from './_models';
import { createMyProperty, getMyPropertyById, updateMyProperty, getMyPropertyList } from './_requests';
import { getContacts } from '../../Contacts/core/_requests';
import { useQueryResponse } from './QueryResponseProvider';
import { useMyPropertyListQueryResponse } from './MyPropertyListQueryResponse';
import { isNotEmpty } from 'app/constants/helpers';
import { useLoadScript } from '@react-google-maps/api';
import { httpClient } from 'app/api/http/http-client';
import logger from 'app/utils/logger';


const useMyProperty = (tools: { [key: string]: any }) => {
  const { refetch } = useQueryResponse();
  const myPropertyListData = useMyPropertyListQueryResponse();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactSchema) });

  const [searchResult, setSearchResult] = useState(null);

  const enabledQuery: boolean = isNotEmpty(tools.itemIdForUpdate);
  const getMyPropertyByIdQuery = useQuery(
    `${QUERIES.MYPROPERTY_LIST}-property-${tools.itemIdForUpdate}`,
    () => {
      return getMyPropertyById(tools.itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery && tools.itemIsUpdate,
      onSuccess: (data) => {
        if (tools.itemIsUpdate) {
          setValue('ownerName', data.ownerName || '');
          setValue('groupId', data.groupId || '');
          setValue('myContactId', { id: data.myContactId, name: data.myContactName });
          setValue('typeId', { id: data.typeId, name: data.typeName });
          setValue('title', data.title || '');
          setValue('note', data.note || '');
          setValue('address.street', data?.address?.street || '');
          setValue('address.city', data?.address?.city || '');
          setValue('address.country', data?.address?.country || '');
          setValue('address.county', data?.address?.county || '');
          setValue('address.postalCode', data?.address?.postalCode || '');
          setValue('address.state', data?.address?.state || '');
          setValue('address.latitude', data?.address?.latitude);
          setValue('address.longitude', data?.address?.longitude);
          setValue('address.fullAddress', data?.address?.fullAddress);

          const findGroupItem = myPropertyListData.response?.find(
            (contact: ContactList) => contact.id === data.groupId
          );
          if (findGroupItem) setValue('groupId', findGroupItem);
          else setValue('groupId', undefined);
        }
      },
      onError: (err) => {
        logger.error(err);
      },
    }
  );

  const createMyPropertyMutation = useMutation((data: MyProperty) => createMyProperty(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.MYPROPERTY_LIST}-CREATE`]);
      setValue('note', '');
      reset();
      tools.onCloseCreate();
      tools.onOpenScankbar(true);
      refetch();
    },
  });

  const updateMyPropertyMutation = useMutation((data: MyProperty) => updateMyProperty(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.MYPROPERTY_LIST}-UPDATE-${tools.itemIdForUpdate}`]);
      setValue('note', '');
      reset();
      tools.onCloseCreate();
      tools.onOpenScankbar(true);
      refetch();
      getMyPropertyByIdQuery.refetch();
    },
  });

  const loadMyPropertyListOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = (await getMyPropertyList()) as unknown as any[];

    return {
      options: response,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadContactsOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = (await getContacts(`PageNumber=${page * 10}&PageSize=10`)) as unknown as any;

    return {
      options: response.values,
      hasMore: response?.totalPages !== page,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadPropertyTypeOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = await httpClient.get('/PropertyDetails/propertyType');

    return {
      options: response,
      hasMore: false,
    };
  };



  const onPlaceChanged = (place: google.maps.places.PlaceResult | null) => {
    if (place != null) {
      const latitude = place.geometry.location.lat();
      const longtitude = place.geometry.location.lng();
      let street = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let country = '';
      let county = '';

      if (place.address_components.find(({ types }: any) => types[0] === 'street_number')) {
        street = place.address_components.find(({ types }: any) => types[0] === 'street_number').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'route')) {
        street = `${street} ${place.address_components.find(({ types }: any) => types[0] === 'route').long_name}`;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'locality')) {
        city = place.address_components.find(({ types }: any) => types[0] === 'locality').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_1')) {
        state = place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_1').long_name;
      }

      if (place.address_components.find(({ types }: any) => types[0] === 'country')) {
        country = place.address_components.find(({ types }: any) => types[0] === 'country').long_name;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'postal_code')) {
        zipCode = place.address_components.find(({ types }: any) => types[0] === 'postal_code').long_name;
      }
      if (place.address_components.find(({ types }: any) => types[0] === 'administrative_area_level_2')) {
        county = place.address_components.find(
          ({ types }: any) => types[0] === 'administrative_area_level_2'
        ).long_name;
      }

      setValue('address.street', street);
      setValue('address.city', city);
      setValue('address.country', country);
      setValue('address.county', county);
      setValue('address.postalCode', zipCode);
      setValue('address.state', state);
      setValue('address.latitude', String(latitude));
      setValue('address.longitude', String(longtitude));
      setValue('address.fullAddress', place.formatted_address);
    } else {
      alert('Please enter text');
    }
  };

  const onSubmit = handleSubmit((data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isEdit = element.dataset.isedit;
    const groupId = data.groupId.id;
    const myContactId = data.myContactId.id;
    const typeId = data.typeId.id;



    if (isEdit === 'true')
      updateMyPropertyMutation.mutate({ ...data, groupId, id: tools.itemIdForUpdate, myContactId, typeId });
    else createMyPropertyMutation.mutate({ ...data, groupId, myContactId, typeId });
  });

  return {
    control,
    register,
    getValues,
    errors,
    reset,
    onSubmit,
    createMyPropertyMutation,
    getMyPropertyByIdQuery,
    loadMyPropertyListOptions,
    loadContactsOptions,
    onPlaceChanged,
    loadPropertyTypeOptions,
  };
};

export { useMyProperty };

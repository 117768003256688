import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  TextField,
  Typography,
} from '@mui/material';
import { black2, blue, gray6, white } from 'app/components/ThemeProvider/colors';
import CustomFileUpload from 'app/components/CustomFileUpload';
import useConfidentiality from './useConfidentiality';
import { styled } from '@mui/material/styles';
import {
  btnSectionBox,
  checkedRadioBtn,
  container,
  draftBtn,
  radioBtn,
  saveBtn,
  sectionBox,
} from './Confidentiality.sx';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { loaderBox } from 'app/pages/AddListing/MarketingDescription/MarketingDescription.sx';
import FieldError from 'app/components/FieldError';
import { Controller } from 'react-hook-form';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  backgroundColor: white,
  border: `2px solid ${gray6}`,
  padding: '0 !important',

  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19, 124, 189, .6)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: blue,
  border: `2px solid ${white}`,

  '&:before': {
    display: 'block',
    width: '16px',
    height: '16px',
    backgroundImage: 'radial-gradient(#fff, #fff, 28%, transparent 32%)',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  'input:hover ~ &': {
    backgroundColor: blue,
  },
});

const BpRadio = (props: RadioProps) => {
  return <Radio disableRipple checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />;
};

const Confidentiality = () => {
  const {
    showTextField,
    setShowTextField,
    register,
    getValues,
    handleChangeRadio,
    radioButtonValue,
    setOpenConfigDocs,
    openConfigDocs,
    setOpenAgreement,
    openAgreement,
    handleClick,
    isMobile,
    fileAgreement,
    fileFromBackAgreement,
    onDropAgreement,
    fileFromBackDocs,
    fileDocs,
    onDropDocs,
    handleDelete,
    onSubmit,
    isLoadingDraft,
    isLoadingContinue,
    isLoadingGetAddListingData,
    errors,
    control,
    agreementError,
    docsError,
  } = useConfidentiality();

  if (isLoadingGetAddListingData) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit} data-isdraft="false">
      <Box sx={container}>
        <Box sx={sectionBox}>
          <Box sx={{ width: '100%', mb: '20px' }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Confidentiality Agreement</Typography>
          </Box>
          <Box
            onClick={() => handleClick(setOpenAgreement, openAgreement)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Controller
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} value={radioButtonValue === undefined ? '' : radioButtonValue}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', mb: '5px' }}>
                      <FormControlLabel
                        control={<BpRadio checked={radioButtonValue === 0} />}
                        label={'Public'}
                        value={0}
                        onChange={() => handleChangeRadio(0)}
                        sx={radioButtonValue === 0 ? checkedRadioBtn : radioBtn}
                      />

                      <FormControlLabel
                        control={<BpRadio checked={radioButtonValue === 1} />}
                        label={'Private'}
                        value={1}
                        onChange={() => handleChangeRadio(1)}
                        sx={radioButtonValue === 1 ? checkedRadioBtn : radioBtn}
                      />

                      <FormControlLabel
                        control={<BpRadio checked={radioButtonValue === 2} />}
                        label={'User Approval'}
                        value={2}
                        onChange={() => handleChangeRadio(2)}
                        sx={radioButtonValue === 2 ? checkedRadioBtn : radioBtn}
                      />
                    </Box>
                    <FieldError error={errors.status} />
                  </RadioGroup>
                )}
                name={'status'}
              />
              <Button
                sx={{
                  background: white,
                  color: blue,
                  border: `1px solid ${blue}`,
                  height: '46px',
                  '&:hover': { background: white, color: blue, border: `1px solid ${blue}` },
                }}
                disabled={radioButtonValue === 0}
                onClick={() => setShowTextField(!showTextField)}
              >
                Upload File / Enter Text
              </Button>
            </Box>
            {isMobile && (openAgreement ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openAgreement}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {showTextField ? (
                <Box>
                  <TextField
                    multiline={true}
                    type={'text'}
                    error={errors.desc !== undefined}
                    disabled={radioButtonValue === 0}
                    {...register('desc')}
                    sx={{
                      width: '100%',
                      height: '243px',
                      '& .MuiInputBase-root ': {
                        height: '243px !important',
                        '& textarea': {
                          height: '243px !important',
                          padding: '12px 0',
                        },
                      },
                    }}
                  />
                  <FieldError error={errors.desc} />
                </Box>
              ) : (
                <CustomFileUpload
                  file={fileAgreement}
                  fileFromBack={fileFromBackAgreement}
                  onDrop={onDropAgreement}
                  isMulti={false}
                  extension={'pdf'}
                  maxSize={10}
                  error={agreementError ? agreementError : errors.agreementFile}
                  control={control}
                  name={{
                    ...register('agreementFile', {
                      value: getValues('agreementFile') === undefined ? '' : getValues('agreementFile'),
                    }),
                  }}
                />
              )}
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenConfigDocs, openConfigDocs)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>
              Add Confidential Documents
            </Typography>
            {isMobile && (openConfigDocs ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openConfigDocs}>
            <CustomFileUpload
              file={fileDocs}
              fileFromBack={fileFromBackDocs}
              onDrop={onDropDocs}
              isMulti={true}
              deleteItem={handleDelete}
              extension={'pdf'}
              maxSize={10}
              error={docsError}
            />
          </Collapse>
        </Box>
        <Box sx={btnSectionBox}>
          <Button
            sx={saveBtn}
            onClick={onSubmit}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingDraft && <CircularProgress size={20} />}
            data-isdraft="true"
          >
            Save to draft
          </Button>
          <Button
            sx={draftBtn}
            type={'submit'}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingContinue && <CircularProgress size={20} />}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Confidentiality;

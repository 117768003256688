import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMoreRotate = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3.25 10c0-.502.414-.916.917-.916.502 0 .916.414.916.916a.92.92 0 0 1-.916.917A.92.92 0 0 1 3.25 10ZM14.917 10c0-.502.414-.916.916-.916.503 0 .917.414.917.916a.92.92 0 0 1-.917.917.92.92 0 0 1-.917-.917ZM9.084 10c0-.502.414-.916.916-.916.503 0 .917.414.917.916a.92.92 0 0 1-.917.917.92.92 0 0 1-.916-.917Z"
    />
  </svg>
);
export default SvgMoreRotate;

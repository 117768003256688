import { object, string, ref } from 'yup';

const getCharacterValidationError = (str: string) => {
  return `Your password must have at least 1 ${str} character`;
};

export const schema = object({
  password: string()
    .required('Password is required')
    .min(8, 'At least 8 characters')
    .matches(/[0-9]/, getCharacterValidationError('digit'))
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase'))
    .matches(/[!@#$%?^&*_]/, getCharacterValidationError('special')),
  newPassword: string()
    .required('Password is required')
    .oneOf([ref('password'), null], 'Passwords must match'),
});

/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC, useState } from 'react';
import { Button, Drawer, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import { drawerBody } from './MoveToListStyles.sx';
import ClearIcon from '@mui/icons-material/Clear';
import CustomSelect from 'app/components/CustomSelect';
import { useMoveToList } from '../../core/useMoveToList';
import { useMyPropertyListQueryResponse } from '../../core/MyPropertyListQueryResponse';

type Props = {
  isOpenDrawer: boolean;
  setIsOpenDrawer: (isOpenDrawer: boolean) => void;
};

const MoveToList: FC<Props> = ({ isOpenDrawer, setIsOpenDrawer }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const myProperties = useMyPropertyListQueryResponse();

  const { control, errors, register, onSubmit, moveToMutation } = useMoveToList({
    onCloseMoveToDrawer: setIsOpenDrawer,
    onOpenSnackbar: setIsSnackbarOpen,
  });

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={moveToMutation.isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {moveToMutation.isError ? (moveToMutation.error as any)?.message : `Your list moved successlufy!`}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" variant="temporary" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
        <Box sx={drawerBody}>
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="fs-5 fw-bold text-black">Move to list</h2>
            <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={() => setIsOpenDrawer(false)}>
              <ClearIcon />
            </Button>
          </div>
          <form className="row mt-5" onSubmit={onSubmit}>
            <div className="col-12 mb-2">
              <CustomSelect
                label="Move To"
                control={control}
                rest={{
                  ...register('listId'),
                }}
                options={myProperties.response}
                error={errors.listId}
              />
            </div>

            <div className="col-12">
              <Button
                type="submit"
                className="w-100"
                size="large"
                disabled={moveToMutation.isLoading}
                startIcon={moveToMutation.isLoading && <CircularProgress size={20} />}
              >
                Move to
              </Button>
            </div>
          </form>
        </Box>
      </Drawer>
    </>
  );
};

export { MoveToList };

export const white = '#FFFFFF';

export const black = '#000000';
export const black1 = '#0B090A';
export const black2 = '#2B2B2B';
export const black3 = '#211F25';
export const black4 = '#3D3A41';
export const black5 = '#383B46';

export const gray = '#8F8F8F';
export const gray1 = '#D4D7E3';
export const gray2 = '#9A9A9A';
export const gray3 = '#C4C4C4';
export const gray4 = '#888A8D';
export const gray5 = '#79797D';
export const gray6 = '#B2B9C0';
export const gray7 = '#DFE5EA';
export const gray8 = '#ABABAB';
export const gray9 = '#AFB1B5';
export const gray10 = '#AAAAAA';
export const gray11 = '#EBEBEC';
export const gray12 = '#888990';
export const gray13 = '#969696';

export const blue = '#21364B';
export const blue1 = '#0C1421';
export const blue2 = '#313957';
export const blue3 = '#1E4AE9';
export const blue4 = '#006A98';
export const blue5 = '#3A6B9D';
export const blue6 = '#204162';
export const blue7 = '#2A4B6B';

export const red = '#F14336';

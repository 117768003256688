import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSearchNormal = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#search-normal_svg__a)"
    >
      <path d="M9.584 17.5a7.917 7.917 0 1 0 0-15.833 7.917 7.917 0 0 0 0 15.833M18.334 18.333l-1.667-1.666" />
    </g>
    <defs>
      <clipPath id="search-normal_svg__a">
        <path fill="currentColor" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSearchNormal;

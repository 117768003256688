/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { ContactListButton } from './ContactListButton';
import { useContactListQueryResponse } from '../../core/ContactListQueryResponse';
import { ContactList as CL } from '../../core/_models';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { DeleteModal } from './DeleteModal';
const ContactList = () => {
  const [itemId, setItemId] = useState<number | null>(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setItemId(null);
    setShow(false);
  };
  const handleShow = (id: number) => {
    setItemId(id);
    setShow(true);
  };

  const {
    state: { GroupId },
    updateState,
  } = useQueryRequest();
  const { response } = useContactListQueryResponse();
  const data = response as any;

  const updatedState = (groupId?: number) => updateState({ GroupId: groupId });

  return (
    <div className="w-100 d-flex flex-wrap gap-3 mt-4 mt-md-0">
      <ContactListButton title="All contacts" updatedState={() => updatedState()} />
      {data?.map((item: CL) => (
        <ContactListButton
          isEditableMode
          key={item.id}
          id={item.id}
          title={item.name}
          isActive={GroupId === item.id}
          updatedState={() => updatedState(item.id)}
          onDeleteShow={handleShow}
        />
      ))}
      <ContactListButton isCreateMode />
      <DeleteModal show={show} handleClose={handleClose} id={itemId} />
    </div>
  );
};

export { ContactList };

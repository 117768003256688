import { SxProps } from '@mui/material';
import { black, blue1, blue2 } from 'app/components/ThemeProvider/colors';

export const authCard: SxProps = {
  display: 'flex',
  pl: '150px',
  justifyContent: 'space-between',
  gap: '10%',
  height: '100vh',

  '@media screen and (min-width: 1550px)': {
    gap: '150px',
    width: 'calc(100vw - 150px)',
    marginLeft: '150px',
  },

  '@media screen and (max-width: 886px)': {
    gap: 0,
    p: 0,
    justifyContent: 'center',
    m: '24px',
  },
};

export const arrowIcon: SxProps = {
  fill: black,
  '@media screen and (min-width: 886px)': {
    display: 'none',
  },
};

export const logoBox: SxProps = {
  '@media screen and (max-width: 886px)': {
    width: 'calc(100% - 40px)',
    display: 'flex',
    justifyContent: 'center',
  },
};

export const contentBox: SxProps = {
  '@media screen and (max-width: 886px)': {
    textAlign: 'center',
  },
};

export const pageHeader: SxProps = {
  fontSize: '36px',
  fontWeight: '600',
  mt: '20px',
  mb: '28px',
  color: blue1,
  '@media screen and (max-width: 886px)': {
    fontSize: '22px',
    fontWeight: 700,
    mt: '30px',
  },
};

export const pageDesc: SxProps = {
  fontSize: '14px',
  color: blue2,
  mb: '30px',
  '@media screen and (max-width: 886px)': {
    fontWeight: 400,
  },
};

import * as React from 'react';

import { EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import 'app/components/HomePageCarousel/style.css';

const images = [
  '/images/carousel/img.png',
  '/images/carousel/img_1.png',
  '/images/carousel/img_2.png',
  '/images/carousel/img_3.png',
  '/images/carousel/img_4.png',
];

const HomePageCarousel = () => {
  return (
    <div className="swiper-container">
      <Swiper
        className={'myswiper'}
        modules={[EffectCoverflow, Autoplay]}
        effect={'coverflow'}
        // grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 3,
          slideShadows: true,
        }}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        spaceBetween={5}
      >
        {images.map((data, index) => (
          <SwiperSlide key={index} className="myswiper-slider">
            <img src={data} alt="Carousel" />
          </SwiperSlide>
        ))}
      </Swiper>
      <img src="/images/carousel/img_5.png" alt="" className={'laptop-img'} />
    </div>
  );
};

export default HomePageCarousel;

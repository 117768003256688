/* eslint-disable curly */
/* eslint-disable react/react-in-jsx-scope */
import { Create } from '../create/Create';
import { Button } from '@mui/material';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import clsx from 'clsx';

const ReminderHeader = () => {
  const { state, updateState } = useQueryRequest();
  const status = state.Status;

  const updatedState = (Status: 1 | 2 | 3) => {
    updateState({ Status });
  };

  return (
    <div className="row">
      <div className="col-12  d-flex justify-content-md-end align-items-center align-items-md-start flex-column flex-sm-row">
        <Create />
      </div>

      <div className="d-flex align-items-center mb-5 mt-5">
        <Button
          variant={status === 1 ? 'contained' : 'text'}
          size="large"
          className={clsx(status !== 1 && 'text-muted')}
          onClick={() => updatedState(1)}
        >
          Upcoming
        </Button>
        <Button
          variant={status === 2 ? 'contained' : 'text'}
          size="large"
          className={clsx(status !== 2 && 'text-muted')}
          onClick={() => updatedState(2)}
        >
          Overdue
        </Button>
        <Button
          variant={status === 3 ? 'contained' : 'text'}
          size="large"
          className={clsx(status !== 3 && 'text-muted')}
          onClick={() => updatedState(3)}
        >
          Completed
        </Button>
      </div>
    </div>
  );
};

export { ReminderHeader };

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

interface Fill {
  fill: string;
}

const ConfidentialityIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  const sxStyle = sx as Fill;

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: '20px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <g clipPath="url(#clip0_1253_4822)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.625 5.625C0.625 2.86357 2.86357 0.625 5.625 0.625H14.375C17.1364 0.625 19.375 2.86357 19.375 5.625V14.375C19.375 17.1364 17.1364 19.375 14.375 19.375H5.625C2.86357 19.375 0.625 17.1364 0.625 14.375V5.625ZM14.5441 7.27337C14.7641 7.00744 14.7268 6.61347 14.4609 6.39344C14.1949 6.17341 13.801 6.21066 13.5809 6.47663L8.90391 12.1302L6.42844 8.98822C6.21481 8.71706 5.82184 8.67044 5.55072 8.88406C5.27956 9.09769 5.23294 9.49066 5.44656 9.76178L8.40112 13.5118C8.518 13.6602 8.69572 13.7477 8.88459 13.75C9.07347 13.7522 9.25322 13.6689 9.37362 13.5234L14.5441 7.27337Z"
          fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1253_4822">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ConfidentialityIcon;

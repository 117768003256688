import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLeadActivity = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <g clipPath="url(#leadActivity_svg__a)">
      <mask
        id="leadActivity_svg__b"
        width={18}
        height={19}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="currentColor" d="M18 .996H0v18h18z" />
      </mask>
      <g fill="currentColor" mask="url(#leadActivity_svg__b)">
        <path d="M7.957 6.654a.616.616 0 0 0-.615.615v3.35c0 .955.777 1.732 1.732 1.732h2.233a.616.616 0 0 0 0-1.231H9.074a.5.5 0 0 1-.501-.501v-3.35a.615.615 0 0 0-.616-.615" />
        <path d="M15.187 4.059A8.7 8.7 0 0 0 9 1.496a8.7 8.7 0 0 0-3.666.804l.181-.464a.616.616 0 0 0-1.146-.448l-.76 1.942a.845.845 0 0 0 .533 1.11l1.989.623a.61.61 0 0 0 .771-.402.615.615 0 0 0-.403-.772l-.982-.308A7.5 7.5 0 0 1 9 2.728a7.47 7.47 0 0 1 5.316 2.202 7.47 7.47 0 0 1 2.203 5.317 7.47 7.47 0 0 1-2.203 5.317A7.47 7.47 0 0 1 9 17.766a7.47 7.47 0 0 1-5.317-2.202 7.47 7.47 0 0 1-2.202-5.317 7.44 7.44 0 0 1 .807-3.394.616.616 0 0 0-1.098-.557 8.66 8.66 0 0 0-.94 3.95c0 2.337.91 4.535 2.563 6.187A8.7 8.7 0 0 0 9 18.996c2.337 0 4.534-.91 6.187-2.563a8.7 8.7 0 0 0 2.563-6.187c0-2.337-.91-4.534-2.563-6.187" />
      </g>
    </g>
    <defs>
      <clipPath id="leadActivity_svg__a">
        <path fill="currentColor" d="M0 .496h18v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLeadActivity;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp_otpInput__-zNC\\+{
    width: 48px !important;
    height: 48px;
    border: 1px solid #D4D7E3;
    border-radius: 12px;
    font-size: 24px;
    color: #000;
}

.otp_otpInput__-zNC\\+:focus{
    outline: none;
}

.otp_otpInputError__U5i\\+J{
    width: 48px !important;
    height: 48px;
    border: 1px solid #F14336;
    border-radius: 12px;
    font-size: 24px;
    color: #000;
}

.otp_otpInputError__U5i\\+J:focus{
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Authorization/Confirm/otp.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".otpInput{\n    width: 48px !important;\n    height: 48px;\n    border: 1px solid #D4D7E3;\n    border-radius: 12px;\n    font-size: 24px;\n    color: #000;\n}\n\n.otpInput:focus{\n    outline: none;\n}\n\n.otpInputError{\n    width: 48px !important;\n    height: 48px;\n    border: 1px solid #F14336;\n    border-radius: 12px;\n    font-size: 24px;\n    color: #000;\n}\n\n.otpInputError:focus{\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otpInput": `otp_otpInput__-zNC+`,
	"otpInputError": `otp_otpInputError__U5i+J`
};
export default ___CSS_LOADER_EXPORT___;

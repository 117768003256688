import { array, boolean, lazy, number, object, string, ref } from 'yup';

export const residentialSchema = object().shape({
  marketingDesc: string().required('This field is required'),
  unpriced: boolean(),
  minPrice: number()
    .transform((value, originalValue) => {
      return originalValue === '' ? undefined : value;
    })
    .typeError('Must be a number')
    .when('unpriced', {
      is: true, // alternatively: (val) => val == true
      then: (schema) => schema.required('This field is required'),
      otherwise: (schema) => schema.notRequired(),
    })
    .max(ref('maxPrice'), 'Minimum price cannot be more than maximum price')
    .min(0, 'Minimum price must be non-negative'),
  maxPrice: number()
    .transform((value, originalValue) => {
      return originalValue === '' ? undefined : value;
    })
    .typeError('Must be a number')
    .when('unpriced', {
      is: true, // alternatively: (val) => val == true
      then: (schema) => schema.required('This field is required'),
      otherwise: (schema) => schema.notRequired(),
    })
    .min(ref('minPrice'), 'Maximum price cannot be less than minimum price'),
  price: number()
    .transform((value, originalValue) => {
      return originalValue === '' ? undefined : value;
    })
    .typeError('Must be a number')
    .when('unpriced', {
      is: false, // alternatively: (val) => val == true
      then: (schema) => schema.required('This field is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  dueDeligence: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  inspectionDate: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  closingDate: string(),
  financialAccepted: lazy((value) => (value === '' ? string() : array())),
});

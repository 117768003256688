import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useAddListingDraft,
  useDeleteFileFromBack,
  useGetAddListingData,
  useAddListeningContinue,
} from 'app/utils/services/addListingService';
import { useGetAllProperties } from 'app/utils/services/propertyService';
import { yupResolver } from '@hookform/resolvers/yup';
import { confidentialitySchema } from 'app/pages/AddListing/Confidentiality/schema';
import logger from 'app/utils/logger';

const useConfidentiality = () => {
  const [showTextField, setShowTextField] = useState(true);
  const [radioButtonValue, setRadioButtonValue] = useState<number>(0);

  const [openAgreement, setOpenAgreement] = useState(true);
  const [openConfigDocs, setOpenConfigDocs] = useState(true);
  const isMobile = useMediaQuery('(max-width: 992px)');
  const isMobilePhone = useMediaQuery('(max-width: 500px)');

  const [fileAgreement, setFileAgreement] = useState(null);
  const [fileDocs, setFileDocs] = useState(null);
  const [fileFromBackAgreement, setFileFromBackAgreement] = useState();
  const [fileFromBackDocs, setFileFromBackDocs] = useState();
  const [agreementError, setAgreementError] = useState(false);
  const [docsError, setDocsError] = useState(false);

  const onDropAgreement = useCallback((acceptedFiles: any, fileRejection: any) => {
    if (fileRejection[0]?.errors[0]?.code === 'file-too-large') {
      setAgreementError(true);
    } else {
      setAgreementError(false);
      setFileFromBackAgreement(undefined);
      setFileAgreement(acceptedFiles);
      setValue('agreementFile', acceptedFiles[0]?.name);
    }
    trigger('agreementFile');
  }, []);

  const onDropDocs = useCallback(
    (acceptedFiles: any, fileRejection: any) => {
      if (fileRejection.length > 0) {
        fileRejection.forEach((file: any) => {
          file.errors.forEach((err: any) => {
            if (err.code === 'file-too-large') {
              setDocsError(true);
            }
          });
        });
      } else {
        if (fileDocs === null) {
          setDocsError(false);
          setFileDocs(acceptedFiles);
        } else {
          setFileDocs([...fileDocs.concat(acceptedFiles)]);
        }
      }
    },
    [fileDocs]
  );

  const location = useLocation();
  const role = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const sessionId = sessionStorage.getItem(role === 'commercial' ? 'commercialId' : 'residentialId');

  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(confidentialitySchema),
    context: { showTextField, file: fileAgreement, fileBack: fileFromBackAgreement },
    defaultValues: { status: 0 },
  });

  const { isLoadingGetAddListingData, dataGetAddListingData, refetchGetAllListingData, dataGetAddListingError } =
    useGetAddListingData(role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident', sessionId, {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    });
  const { isLoadingAllProperties, dataAllProperties } = useGetAllProperties(
    role === 'residential' ? 2 : role === 'commercial' && 1
  );

  const { isLoadingDraft, isSuccessDraft, dataDraft, draftMutation } = useAddListingDraft();
  const { isSuccessDelete, dataDelete, deleteMutation } = useDeleteFileFromBack();
  const { isLoadingContinue, isSuccessContinue, dataContinue, continueMutation } = useAddListeningContinue();

  useEffect(() => {
    if (!isMobile && !openAgreement) {
      setOpenAgreement(true);
    }
    if (!isMobile && !openConfigDocs) {
      setOpenConfigDocs(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (sessionId !== null && dataGetAddListingData !== undefined && dataGetAddListingData?.aggreement !== null) {
      setValue('desc', dataGetAddListingData?.aggreement.confidentialAggrementText || '');
      setValue('status', dataGetAddListingData?.aggreement.visibility ?? '');
      setRadioButtonValue(dataGetAddListingData?.aggreement.visibility);
      setFileFromBackDocs(
        dataGetAddListingData.aggreement?.documents?.map((item: any) => ({
          originalName: item,
          name: item?.substring(+item?.indexOf('.') + 1),
        }))
      );
      if (dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.length > 0) {
        setValue('desc', '');
        setShowTextField(false);
      }
      setValue(
        'agreementFile',
        dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.substring(
          +dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.indexOf('.') + 1
        )
      );
      setFileFromBackAgreement(
        dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.substring(
          +dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.indexOf('.') + 1
        )
      );
    }
    if ((dataGetAddListingError as any)?.status === 404) {
      sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
      // eslint-disable-next-line curly
      if (window !== undefined) window.location.reload();
    }
  }, [dataGetAddListingData]);

  useEffect(() => {
    if (
      (isSuccessDraft && dataDraft !== undefined && !dataDraft.message) ||
      (isSuccessContinue && dataContinue !== undefined && !dataContinue.message)
    ) {
      sessionStorage.setItem(
        role === 'commercial' ? 'commercialId' : 'residentialId',
        dataDraft ? String(dataDraft) : String(dataContinue)
      );
      navigate(`/add-listing/${role}/person-details`);
    }
  }, [dataDraft, dataContinue]);

  const handleClick = (set: any, value: boolean) => {
    if (isMobile) {
      set(!value);
    }
  };

  const handleChangeRadio = (value: number) => {
    setRadioButtonValue(value);
    setValue('status', value);
  };

  const handleDelete = (itemIndex: string, fromBack: boolean, name?: string) => {
    if (fromBack) {
      deleteMutation({
        id: sessionId,
        path: name,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      setFileDocs(fileDocs.filter((item: any, index: string) => index !== itemIndex));
    }
  };

  const submitForm = (data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isDraft = element?.dataset?.isdraft === 'true';
    const residentialFormData = new FormData();
    if (fileDocs === null) {
      residentialFormData.append('Aggreement.Documents', '');
    } else {
      for (const row of fileDocs) {
        residentialFormData.append('Aggreement.Documents', row);
      }
    }

    residentialFormData.append('Aggreement.Visibility', `${radioButtonValue}`);
    residentialFormData.append(
      'Aggreement.ConfidentialAggrementText',
      data.status === 0 ? '' : fileAgreement ? '' : data.desc
    );
    residentialFormData.append('Aggreement.ConfidentialAgreementFile', fileAgreement === null ? '' : fileAgreement[0]);
    residentialFormData.append('Id', sessionId === null ? '' : sessionId);

    if (isDraft) {
      draftMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      continueMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  const onSubmit = handleSubmit((data: any, event: any) => submitForm(data, event));

  return {
    showTextField,
    setShowTextField,
    register,
    getValues,
    handleChangeRadio,
    radioButtonValue,
    isMobile,
    setOpenConfigDocs,
    setOpenAgreement,
    openAgreement,
    openConfigDocs,
    handleClick,
    fileAgreement,
    fileFromBackAgreement,
    onDropAgreement,
    fileDocs,
    fileFromBackDocs,
    onDropDocs,
    handleDelete,
    onSubmit,
    isLoadingDraft,
    isLoadingContinue,
    isLoadingGetAddListingData,
    errors,
    control,
    agreementError,
    docsError,
  };
};

export default useConfidentiality;

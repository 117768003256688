import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDealPipeline = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <mask
      id="dealPipeline_svg__a"
      width={18}
      height={19}
      x={0}
      y={1}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'luminance',
      }}
    >
      <path fill="currentColor" d="M18 1.245H0v18h18z" />
    </mask>
    <g fill="currentColor" mask="url(#dealPipeline_svg__a)">
      <path d="M14.213 12.334c-.353-.737-1.617-1.76-2.838-2.747-.68-.55-1.324-1.07-1.734-1.481a.38.38 0 0 0-.393-.088c-.252.092-.408.162-.531.217-.188.084-.25.112-.504.138a.38.38 0 0 0-.274.164c-.53.793-1.08.725-1.446.602-.117-.039-.138-.089-.15-.137-.078-.33.317-1.096.83-1.608 1.218-1.22 1.847-1.525 3.174-.931a44 44 0 0 0 3.03 1.206.375.375 0 1 0 .247-.71 43 43 0 0 1-2.97-1.182c-1.72-.769-2.663-.262-4.011 1.086-.513.513-1.21 1.551-1.03 2.312.077.324.305.564.644.676.85.28 1.626.012 2.203-.754.239-.037.368-.091.563-.179.07-.031.153-.068.263-.112.429.402 1.008.871 1.618 1.364 1.102.892 2.352 1.902 2.634 2.488a.44.44 0 0 1-.112.563c-.149.127-.352.168-.462.09a.375.375 0 0 0-.586.35c.026.238-.193.374-.287.422-.24.121-.49.1-.584.013a.375.375 0 0 0-.63.24.71.71 0 0 1-.442.574c-.115.043-.281.07-.43-.067a.374.374 0 0 0-.606.15c-.015.037-.047.127-.398.127-.25 0-.7-.169-.919-.314-.263-.174-1.914-1.405-3.34-2.6-.2-.168-.546-.53-.852-.85a12 12 0 0 0-.648-.648.375.375 0 0 0-.484.573c.116.098.341.336.59.595.334.35.68.711.912.906 1.397 1.17 3.075 2.432 3.41 2.651.276.182.87.437 1.33.437q.556-.001.852-.25c.264.102.564.103.853-.01.341-.132.622-.398.78-.72.296.063.634.018.942-.136.3-.152.518-.383.626-.654.299.016.606-.09.86-.309.43-.368.55-.94.3-1.457" />
      <path d="M7.5 6.12H4.125a.375.375 0 0 0 0 .75H7.5a.375.375 0 0 0 0-.75M15.312 11.536a.374.374 0 0 0-.52-.102l-1.097.736a.375.375 0 0 0 .419.623l1.096-.737a.375.375 0 0 0 .102-.52M12.983 13.325c-.269-.211-1.47-1.44-2.212-2.215a.374.374 0 1 0-.542.519c.193.201 1.894 1.974 2.289 2.286a.373.373 0 0 0 .527-.063.375.375 0 0 0-.062-.527M11.485 14.077c-.45-.359-1.578-1.558-1.833-1.836a.376.376 0 0 0-.553.508c.013.014 1.36 1.469 1.917 1.914a.377.377 0 0 0 .527-.058.376.376 0 0 0-.058-.528M9.992 14.833c-.535-.451-1.63-1.616-1.843-1.845a.375.375 0 1 0-.548.513c.307.329 1.354 1.439 1.907 1.905a.374.374 0 0 0 .529-.045.375.375 0 0 0-.045-.528" />
      <path d="M4.383 5.473C3.74 4.863 1.17 4.666.398 4.62A.37.37 0 0 0 0 4.995v6.75c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .356-.259c.055-.168 1.347-4.134 1.517-6.084a.37.37 0 0 0-.115-.304M2.351 11.37H.75V5.397c1.205.095 2.506.302 2.978.532-.198 1.642-1.098 4.56-1.377 5.44M17.625 5.37c-2.944 0-4.578.754-4.646.785a.373.373 0 0 0-.159.538c.464.746 1.915 4.89 2.194 5.902a.375.375 0 0 0 .361.275h2.25a.375.375 0 0 0 .375-.375v-6.75a.375.375 0 0 0-.375-.375m-.375 6.75h-1.594c-.356-1.158-1.406-4.178-1.991-5.426.572-.194 1.801-.528 3.585-.57z" />
    </g>
  </svg>
);
export default SvgDealPipeline;

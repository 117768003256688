import React from 'react';
import { Box, Button, CircularProgress, Collapse, Typography } from '@mui/material';
import { black2 } from 'app/components/ThemeProvider/colors';
import CustomInput from 'app/components/CustomInput';
import usePersonDetails from './usePersonDetails';
import { btnSectionBox, container, draftBtn, loaderBox, rowContainer, saveBtn, sectionBox } from './PersonDetails.sx';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const PersonDetails = () => {
  const {
    isLoadingContinue,
    isLoadingDraft,
    isLoadingGetAddListingData,
    onSubmit,
    getValues,
    register,
    handleChangePhone,
    phoneNumberValue,
    errors,
    setOpenDetails,
    openDetails,
    isMobile,
    handleClick,
  } = usePersonDetails();

  if (isLoadingGetAddListingData) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit} data-isdraft="false">
      <Box sx={container}>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDetails, openDetails)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Listing Person Details</Typography>
            {isMobile && (openDetails ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse
            in={openDetails}
            sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }}
          >
            <Box sx={rowContainer}>
              <CustomInput
                id={'name'}
                label={'Name'}
                type={'text'}
                error={errors.name}
                rest={{
                  ...register('name', {
                    value: getValues('name') === undefined ? '' : getValues('name'),
                  }),
                }}
              />
              <CustomInput
                id={'lastname'}
                label={'Last Name'}
                type={'text'}
                error={errors.lastName}
                rest={{
                  ...register('lastName', { value: getValues('lastName') === undefined ? '' : getValues('lastName') }),
                }}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'phone-number'}
                label={'Phone Number'}
                type={'text'}
                value={phoneNumberValue}
                error={errors.phoneNumber}
                inputProps={{ maxLength: 14 }}
                rest={{
                  ...register('phoneNumber', {
                    onChange: (e: any) => handleChangePhone(e),
                    value: phoneNumberValue,
                  }),
                }}
              />
              <CustomInput
                id={'email'}
                label={'Email'}
                type={'text'}
                error={errors.email}
                rest={{
                  ...register('email', { value: getValues('email') === undefined ? '' : getValues('email') }),
                }}
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={btnSectionBox}>
          <Button
            sx={saveBtn}
            onClick={onSubmit}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingDraft && <CircularProgress size={20} />}
            data-isdraft="true"
          >
            Save to draft
          </Button>
          <Button
            sx={draftBtn}
            type={'submit'}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingContinue && <CircularProgress size={20} />}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default PersonDetails;

/* eslint-disable curly */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from 'react';
import { ReminderCard } from './ReminderCard';
import { useQueryResponseData, useQueryResponseError } from '../../core/QueryResponseProvider';
import { Reminder } from '../../core/_models';
import { Snackbar, Alert } from '@mui/material';
import { Details } from '../details/Details';
const ReminderList = () => {
  const reminders = useQueryResponseData();
  const errors = useQueryResponseError();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  useEffect(() => {
    if (errors) setIsSnackbarOpen(true);
  }, [errors]);

  return (
    <div className="mt-5">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert onClose={() => setIsSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {errors?.errors?.status?.map((message, index) => (
            <span className="text-black fw-bold d-block" key={index}>
              {message}
            </span>
          ))}
        </Alert>
      </Snackbar>
      <Details />
      {reminders.map((reminder: Reminder, index: number) => (
        <ReminderCard key={reminder.id} reminder={reminder} />
      ))}
    </div>
  );
};

export { ReminderList };

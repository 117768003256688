/* eslint-disable curly */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, ReactNode } from 'react';
import { Button, Drawer, Box, TextField, CircularProgress, Snackbar, Alert } from '@mui/material';
import { Add } from 'app/components/SvgIcons';
import { drawerBody, drawerTextArea } from './CreateStyle.sx';
import CustomInput from 'app/components/CustomInput';
import CustomSelect from 'app/components/CustomSelect';
import ClearIcon from '@mui/icons-material/Clear';
// import DatePicker from 'react-datepicker';
// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useReminder } from '../../core/useReminder';
import { reminderTypes, reminderPriority } from '../../core/_helper';
import { Controller } from 'react-hook-form';
import FieldError from 'app/components/FieldError';
import { useQuery } from 'react-query';
import { getContacts } from 'app/pages/Dashboard/Contacts/core/_requests';
import { QUERIES } from 'app/constants/consts';
import { useListView } from '../../core/ListViewProvider';
import Flatpickr from 'react-flatpickr';
import format from 'date-fns/format';
import { useSearchParams } from 'react-router-dom';
import { AsyncSelectPagination } from 'app/components/CustomSelect/AsyncSelectPagination';

const Create = () => {
  const [params, setSearchParams] = useSearchParams();
  const contactId = params.get('ContactId');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const {
    itemIdForUpdate,
    itemIsUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    isOpenCreateDrawer,
    setIsOpenCreateDrawer,
  } = useListView();

  useEffect(() => {
    if (contactId) setIsOpenCreateDrawer(true);
  }, [contactId]);

  const {
    register,
    getValues,
    setValue,
    errors,
    control,
    onSubmit,
    createReminderMutation,
    getReminderByIdQuery,
    updateReminderMutation,
    reset,
    defaultContactOptions,
    loadContactOptions,
  } = useReminder({
    onCloseCreate: onCloseDrawer,
    onSetIsSnackbarOpen: setIsSnackbarOpen,
    itemIdForUpdate,
    itemIsUpdate,
    contactId,
    params,
    setSearchParams,
  });

  function onCloseDrawer() {
    setItemIdForUpdate(undefined);
    setItemIsUpdate(false);
    setIsOpenCreateDrawer(false);
    setIsSnackbarOpen(false);
    reset();
  }

  let renderCreate: ReactNode;
  if (getReminderByIdQuery.isFetching)
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress size={40} />
        </div>
      </Box>
    );
  else
    renderCreate = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="fs-3 fw-bold text-black">{itemIsUpdate ? 'Edit' : 'Create New'} Reminder</h2>
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onCloseDrawer}>
            <ClearIcon />
          </Button>
        </div>
        <form className="row mt-5" onSubmit={onSubmit} data-isedit={itemIsUpdate}>
          <div className="col-12 mb-2">
            <CustomInput
              label="Subject"
              id="subject"
              type="text"
              error={errors.subject}
              rest={{
                ...register('subject', {
                  value: getValues('subject') || '',
                }),
              }}
            />
          </div>
          <div className="col-12 mb-5">
            <Flatpickr
              className="form-control mb-2"
              placeholder="Choose a date"
              options={{
                enableTime: true,
                dateFormat: 'Y-m-d H:i',
                minDate: Date.now(),
                minTime: new Date(new Date().setHours(new Date().getHours() + 4)),
              }}
              value={new Date(getValues('time'))}
              onChange={([date]) => setValue('time', format(date, "yyyy-MM-dd'T'HH:mm:ss"))}
            />
          </div>
          <div className="col-12 col-md-6 mb-2">
            <CustomSelect
              label="Reminder Type"
              control={control}
              options={reminderTypes}
              rest={{
                ...register('type'),
              }}
              error={errors.type}
            />
          </div>
          <div className="col-12 col-md-6 mb-2">
            <CustomSelect
              label="Reminder Priorty"
              control={control}
              options={reminderPriority}
              rest={{
                ...register('priority'),
              }}
              error={errors.priority}
            />
          </div>
          <div className="col-12 mb-4">
            <Controller
              control={control}
              name="note"
              render={({ field: { onBlur, onChange, value } }) => (
                <>
                  <TextField
                    multiline={true}
                    sx={drawerTextArea}
                    placeholder="Add a note"
                    label="Add a note"
                    id="note"
                    value={value}
                    error={errors.note !== undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <FieldError error={errors.note} />
                </>
              )}
            />
          </div>
          <div className="col-12 mb-2">
            <CustomInput label="Related Property" />
          </div>
          <div className="col-12 mb-2">
            <CustomInput label="Related Deal" />
          </div>
          <div className="col-12 mb-2">
            <AsyncSelectPagination
              label="Select Contact"
              control={control}
              rest={{
                ...register('contactsId'),
              }}
              error={errors.contactsId}
              isMulti
              defaultValue={defaultContactOptions}
              loadOptions={loadContactOptions}
            />
          </div>
          <div className="col-12">
            <Button
              type="submit"
              className="w-100"
              size="large"
              disabled={createReminderMutation.isLoading}
              startIcon={createReminderMutation.isLoading && <CircularProgress size={20} />}
            >
              {itemIsUpdate ? 'Edit' : 'Create New'} Reminder
            </Button>
          </div>
        </form>
      </Box>
    );

  return (
    <>
      <Button size="large" startIcon={<Add />} onClick={() => setIsOpenCreateDrawer(true)}>
        Create New Reminder
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={createReminderMutation.isSuccess || updateReminderMutation.isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {createReminderMutation.isError || updateReminderMutation.isError
            ? (createReminderMutation.error as any)?.message || (updateReminderMutation.error as any)?.message
            : `Your contact ${itemIsUpdate ? 'updated' : 'created'} successlufy!`}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" variant="temporary" open={isOpenCreateDrawer} onClose={onCloseDrawer}>
        {renderCreate}
      </Drawer>
    </>
  );
};

export { Create };

import * as Yup from 'yup';

const validationScheme = Yup.object({
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!'),
  emailAddress: Yup.string().email().required('Email is required!'),
  subject: Yup.string().required('Subject is required!'),
  message: Yup.string().required('Message is required!'),
});

export { validationScheme };

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

interface Fill {
  fill: string;
}

const InvestmentDetailsIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  const sxStyle = sx as Fill;

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: '20px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M14.1537 17.8417L13.8743 18.984C13.0359 19.3149 12.3663 19.5668 11.8676 19.7399C11.3684 19.9136 10.7884 20 10.1275 20C9.11256 20 8.32315 19.7514 7.76013 19.2576C7.19711 18.7618 6.91551 18.1335 6.91551 17.3713C6.91551 17.0763 6.93594 16.7728 6.97843 16.4636C7.02127 16.154 7.08938 15.8054 7.18259 15.4157L8.2303 11.7074C8.32351 11.3523 8.40273 11.0159 8.46619 10.6977C8.53054 10.3815 8.56155 10.0906 8.56155 9.82873C8.56155 9.35515 8.4635 9.0239 8.2683 8.83766C8.0731 8.65196 7.70062 8.55768 7.14764 8.55768C6.87679 8.55768 6.59842 8.60106 6.31467 8.68494C6.02966 8.76919 5.78607 8.85057 5.58154 8.92621L5.86171 7.78297C6.54805 7.50352 7.2041 7.26422 7.83165 7.06561C8.4592 6.86647 9.05215 6.76681 9.61302 6.76681C10.6209 6.76681 11.3987 7.01041 11.9449 7.4976C12.491 7.98516 12.764 8.61719 12.764 9.39602C12.764 9.55717 12.7461 9.8411 12.7076 10.2469C12.6699 10.6536 12.5998 11.0263 12.4977 11.3652L11.4544 15.0587C11.3689 15.3553 11.292 15.6945 11.2252 16.0761C11.1562 16.4552 11.1232 16.7448 11.1232 16.9392C11.1232 17.4296 11.2325 17.7644 11.4517 17.9424C11.6726 18.1204 12.0529 18.209 12.5932 18.209C12.8468 18.209 13.1354 18.164 13.4561 18.0756C13.7759 17.9872 14.0091 17.9096 14.1537 17.8417ZM14.4183 2.33561C14.4183 2.97911 14.1758 3.52868 13.6884 3.98057C13.2023 4.43407 12.6165 4.661 11.9312 4.661C11.2438 4.661 10.6566 4.43407 10.1649 3.98057C9.67414 3.52851 9.42821 2.97911 9.42821 2.33561C9.42821 1.69336 9.67414 1.14289 10.1649 0.685267C10.6557 0.228362 11.244 0 11.9312 0C12.6163 0 13.2023 0.2289 13.6884 0.685267C14.1761 1.14289 14.4183 1.69354 14.4183 2.33561Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
    </SvgIcon>
  );
};

export default InvestmentDetailsIcon;

/* eslint-disable react/react-in-jsx-scope */
import { Column } from 'react-table';
import { ContactSelectionCell } from './ContactSelectionCell';
import { ContactSelectionHeader } from './ContactSelectionHeader';
import { ContactCustomHeader } from './ContactCustomHeader';
import { ContactCustomCell } from './ContactCustomCell';
import { ContactActionCell } from './ContactActionsCell';
import { Contact } from '../../../core/_models';

const contactsColumns: ReadonlyArray<Column<Contact>> = [
  {
    Header: (props) => <ContactSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <ContactSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ContactCustomHeader tableProps={props} title="Name" className="min-w-150px" />,
    id: 'name',
    Cell: ({ ...props }) => <ContactCustomCell title={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => <ContactCustomHeader tableProps={props} title="Surname" className="min-w-150px text-center" />,
    id: 'lastName',
    Cell: ({ ...props }) => <ContactCustomCell title={props.data[props.row.index].lastName} className="text-center" />,
  },
  {
    Header: (props) => <ContactCustomHeader tableProps={props} title="Tags" className="min-w-150px text-center" />,
    id: 'tags',
    Cell: ({ ...props }) => (
      <ContactCustomCell
        title={props.data[props.row.index]?.tags?.map((t: { name: string }) => t?.name).join(',')}
        className="text-center"
      />
    ),
  },
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title="Phone number" className="min-w-150px text-center" />
    ),
    id: 'phoneNumber',
    Cell: ({ ...props }) => (
      <ContactCustomCell title={props.data[props.row.index]?.phoneNumber} className="text-center" />
    ),
  },
  {
    Header: (props) => <ContactCustomHeader tableProps={props} title="Email" className="min-w-150px text-center" />,
    id: 'email',
    Cell: ({ ...props }) => <ContactCustomCell title={props.data[props.row.index]?.email} className="text-center" />,
  },
  {
    Header: (props) => <ContactCustomHeader tableProps={props} title="Address" className="min-w-150px text-center" />,
    id: 'address',
    Cell: ({ ...props }) => <ContactCustomCell title={props.data[props.row.index]?.address} className="text-center" />,
  },
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title="Company name" className="min-w-150px text-center" />
    ),
    id: 'companyName',
    Cell: ({ ...props }) => (
      <ContactCustomCell title={props.data[props.row.index]?.companyName} className="text-center" />
    ),
  },
  {
    Header: (props) => <ContactCustomHeader tableProps={props} title="Title" className="min-w-150px text-center" />,
    id: 'title',
    Cell: ({ ...props }) => <ContactCustomCell title={props.data[props.row.index]?.title} className="text-center" />,
  },
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title="Related property" className="min-w-150px text-center" />
    ),
    id: 'relatedProperty',
    Cell: ({ ...props }) => (
      <ContactCustomCell title={props.data[props.row.index]?.relatedProperty} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <ContactCustomHeader tableProps={props} title="Related deal" className="min-w-150px text-center" />
    ),
    id: 'relatedDeal',
    Cell: ({ ...props }) => (
      <ContactCustomCell title={props.data[props.row.index]?.relatedDeal} className="text-center" />
    ),
  },
  {
    Header: (props) => <ContactCustomHeader tableProps={props} title="Actions" className="min-w-150px text-center" />,
    id: 'actions',
    Cell: ({ ...props }) => <ContactActionCell id={props.data[props.row.index].id} />,
  },
];

export { contactsColumns };

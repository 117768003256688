import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { black, blue1, blue2, blue3, gray2 } from 'app/components/ThemeProvider/colors';
import FieldError from 'app/components/FieldError';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useRegistration from './useRegistration';
import { BpCheckedIcon, BpIcon } from 'app/components/CustomCheckbox';
import ConfirmPage from 'app/pages/Authorization/Confirm';
import CustomInput from 'app/components/CustomInput';
import AuthCard from 'app/components/AuthCard';

const Registration = () => {
  const {
    handleChangePhone,
    phoneNumberValue,
    openSnackbar,
    setOpenSnackbar,
    register,
    showPassword,
    onFormSubmit,
    errors,
    handleMouseDownPassword,
    handleClickShowPassword,
    dataRegistration,
    confirm,
    getValues,
  } = useRegistration();

  if (confirm === 'email') {
    return <ConfirmPage email={getValues('email')} forgotPass={false} />;
  } else {
    return (
      <AuthCard
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        data={dataRegistration}
        header={'Register'}
        desc={'Enter the fields below to get started'}
      >
        <form style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} onSubmit={onFormSubmit}>
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <CustomInput
              id={'register-name'}
              label={'Name'}
              type={'text'}
              error={errors.name}
              rest={{ ...register('name') }}
            />
            <CustomInput
              id={'register-lastname'}
              label={'Lastname'}
              type={'text'}
              error={errors.lastname}
              rest={{ ...register('lastname') }}
            />
          </Box>
          <CustomInput
            id={'register-email'}
            label={'Email'}
            type={'email'}
            error={errors.email}
            rest={{ ...register('email') }}
          />
          <CustomInput
            id={'register-phone'}
            label={'Phone Number'}
            type={'text'}
            error={errors.phoneNumber}
            value={phoneNumberValue}
            inputProps={{ maxLength: 14 }}
            rest={{
              ...register('phoneNumber', {
                onChange: (e: any) => handleChangePhone(e),
                value: phoneNumberValue,
              }),
            }}
          />
          <CustomInput
            id={'register-pass'}
            label={'Password'}
            type={showPassword ? 'text' : 'password'}
            error={errors.password}
            rest={{ ...register('password') }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormControl sx={{ mt: '5px' }}>
            <FormControlLabel
              sx={{ mr: 0, display: 'flex', gap: '12px', ml: 0, '& .MuiCheckbox-root': { p: 0 } }}
              control={<Checkbox checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...register('terms')} />}
              label={
                <Typography sx={{ fontSize: '14px', color: gray2, fontWeight: 500 }}>
                  By clicking Sign Up, accept the&nbsp;
                  <Link to={'#'} style={{ textDecoration: 'none', color: black, fontWeight: 600 }}>
                    Privacy Policy & Terms and Conditions
                  </Link>
                </Typography>
              }
            />
            <FieldError error={errors.terms} />
          </FormControl>

          <Button type={'submit'} sx={{ mt: '21px', mb: '24px' }}>
            Register
          </Button>
        </form>
        <Typography sx={{ textAlign: 'center', fontSize: '18px', color: blue2 }}>
          Do you have an account?&nbsp;
          <Link to={'/auth/login'} style={{ textDecoration: 'none', color: blue3 }}>
            Sign in
          </Link>
        </Typography>
      </AuthCard>
    );
  }
};

export default Registration;

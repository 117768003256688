/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC, useState } from 'react';
import { Button, Drawer, Box, TextField, Checkbox, Snackbar, Alert, CircularProgress } from '@mui/material';
import { drawerBody, drawerTextArea } from './EmailStyles.sx';
import ClearIcon from '@mui/icons-material/Clear';
import CustomInput from 'app/components/CustomInput';
import CustomSelect from 'app/components/CustomSelect';
import CustomFileUpload from 'app/components/CustomFileUpload';
import { useEmail } from '../../core/useEmail';
import { useQueryResponseData } from '../../core/QueryResponseProvider';
import { useQuery } from 'react-query';
import { getProperties } from 'app/pages/Dashboard/Property/core/_requests';
import { QUERIES } from 'app/constants/consts';
import { Controller } from 'react-hook-form';
import FieldError from 'app/components/FieldError';

type Props = {
  isOpenDrawer: boolean;
  setIsOpenDrawer: (isOpenDrawer: boolean) => void;
};

const SendEmail: FC<Props> = ({ isOpenDrawer, setIsOpenDrawer }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const contacts = useQueryResponseData();
  const getAllProperties = useQuery(
    `${QUERIES.CONTACT_LIST}-properties`,
    () => {
      return getProperties();
    },
    {
      cacheTime: 20 * 60 * 1000,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    sendEmailMutation,
    errors,
    register,
    control,
    getValues,
    onSubmit,
    file,
    fileFromBack,
    fileError,
    onDropFile,
  } = useEmail({
    onCloseEmailDrawer: setIsOpenDrawer,
    onOpenSnackbar: setIsSnackbarOpen,
  });

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={sendEmailMutation.isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {sendEmailMutation.isError ? (sendEmailMutation.error as any)?.message : `Your email sent successlufy!`}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" variant="temporary" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
        <Box sx={drawerBody}>
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="fs-5 fw-bold text-black">Send Email</h2>
            <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={() => setIsOpenDrawer(false)}>
              <ClearIcon />
            </Button>
          </div>
          <form className="row mt-5" onSubmit={onSubmit}>
            <div className="col-12 mb-2">
              <CustomInput
                label="Subject"
                id="title"
                type="text"
                error={errors.title}
                rest={{
                  ...register('title', {
                    value: getValues('title') || '',
                  }),
                }}
              />
            </div>

            <div className="col-12 mb-2">
              <CustomSelect
                label="Reply to Email Address"
                control={control}
                rest={{
                  ...register('contactsId'),
                }}
                options={contacts}
                error={errors.contactsId}
                isMulti
              />
            </div>
            <div className="col-12 mb-4">
              <Controller
                control={control}
                name="message"
                render={({ field: { onBlur, onChange, value } }) => (
                  <>
                    <TextField
                      multiline={true}
                      sx={drawerTextArea}
                      placeholder="Message"
                      label="Message"
                      id="message"
                      value={value}
                      error={errors.message !== undefined}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                    <FieldError error={errors.message} />
                  </>
                )}
              />
            </div>
            <div className="col-12 mb-2">
              <CustomSelect
                label="Related Property"
                control={control}
                rest={{
                  ...register('propertyId'),
                }}
                options={getAllProperties.data || []}
                error={errors.propertyId}
              />
            </div>
            <div className="col-12 mb-2">
              <div className="form-check form-check-custom form-check-solid">
                <input className="form-check-input" type="checkbox" {...register('isSendFile')} />
                <span className="d-inline-block ms-2">Upload your file</span>
              </div>
            </div>
            <div className="col-12 mb-3">
              <CustomFileUpload
                file={file}
                fileFromBack={fileFromBack}
                onDrop={onDropFile}
                maxSize={10}
                extension="pdf"
                isMulti={false}
                error={fileError ? fileError : errors.file}
                control={control}
                name={{
                  ...register('file', {
                    value: getValues('file') === undefined ? '' : getValues('file'),
                  }),
                }}
              />
            </div>

            <div className="col-12">
              <Button
                className="w-100"
                size="large"
                type="submit"
                disabled={sendEmailMutation.isLoading}
                startIcon={sendEmailMutation.isLoading && <CircularProgress size={20} />}
              >
                Send Email
              </Button>
            </div>
          </form>
        </Box>
      </Drawer>
    </>
  );
};

export { SendEmail };

/* eslint-disable react/react-in-jsx-scope */
import { Box, Button, Typography } from '@mui/material';
import { black } from 'app/components/ThemeProvider/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HomePageMainArrowIcon from 'app/components/Icons/HomePageMainArrowIcon';
import { contactSection, mainSection, mainSectionBox, headerText, descText } from './HomePageMain.sx';
import { ContactUs } from 'app/modules/ContactUs/ContactUs';

const HomePageMain = () => {
  return (
    <>
      {/* <HomePageCarousel /> */}
      <Box sx={mainSection}>
        <Box sx={mainSectionBox}>
          <Box
            sx={{
              width: '600px',
              '@media screen and (max-width: 768px)': {
                width: '90%',
              },
            }}
          >
            <Typography sx={headerText}>In-Depth Property Data and Advanced Search</Typography>
            <Typography sx={descText}>
              Unearth comprehensive insights for any property across the US. Incorporating historical prices, zoning
              details...
            </Typography>
            <Button variant="outlined" size="large" endIcon={<ArrowForwardIosIcon />}>
              Read more
            </Button>
          </Box>
          <img src="/images/mainSectionImages/img.png" alt="img" />
        </Box>
        <Box sx={mainSectionBox}>
          <img src="/images/mainSectionImages/img.png" alt="img" />
          <Box
            sx={{
              width: '600px',
              '@media screen and (max-width: 768px)': {
                width: '90%',
              },
            }}
          >
            <Typography sx={headerText}>Direct Owner Lookup</Typography>
            <Typography sx={descText}>
              With our Direct Owner Lookup, real estate research has never been this easy or efficient. Gain immediate
              access to vital owner details such as phone numbers and emails...
            </Typography>
            <Button variant="outlined" size="large" endIcon={<ArrowForwardIosIcon />}>
              Read more
            </Button>
          </Box>
        </Box>
        <Box sx={mainSectionBox}>
          <Box
            sx={{
              width: '600px',
              '@media screen and (max-width: 768px)': {
                width: '90%',
              },
            }}
          >
            <Typography sx={headerText}>Buyer Information Access</Typography>
            <Typography sx={descText}>
              Expand your network and unlock new opportunities by tapping into our expansive buyer database...
            </Typography>
            <Button variant="outlined" size="large" endIcon={<ArrowForwardIosIcon />}>
              Read more
            </Button>
          </Box>
          <img src="/images/mainSectionImages/img.png" alt="img" />
        </Box>
        <Box sx={mainSectionBox}>
          <img src="/images/mainSectionImages/img.png" alt="img" />
          <Box
            sx={{
              width: '600px',
              '@media screen and (max-width: 768px)': {
                width: '90%',
              },
            }}
          >
            <Typography sx={headerText}>Dynamic Listing with Confidentiality Control</Typography>
            <Typography sx={descText}>
              Enjoy the flexibility to list properties with desired confidentiality settings. Once listed, our platform
              notifies relevant investors...
            </Typography>
            <Button variant="outlined" size="large" endIcon={<ArrowForwardIosIcon />}>
              Read more
            </Button>
          </Box>
        </Box>
        <Box sx={mainSectionBox}>
          <Box
            sx={{
              width: '600px',
              '@media screen and (max-width: 768px)': {
                width: '90%',
              },
            }}
          >
            <Typography sx={headerText}>Multichannel Communication Suite</Typography>
            <Typography sx={descText}>
              Our Multichannel Communication Suite is designed for real estate professionals seeking diversified yet
              targeted outreach...
            </Typography>
            <Button variant="outlined" size="large" endIcon={<ArrowForwardIosIcon />}>
              Read more
            </Button>
          </Box>
          <img src="/images/mainSectionImages/img.png" alt="img" />
        </Box>
      </Box>
      <Box sx={contactSection}>
        <img
          src="/images/mainSectionImages/img_1.png"
          alt="img"
          style={{
            position: 'absolute',
            bottom: '-74px',
            left: '0',
            width: '481px',
            height: '558px',
          }}
        />
        <Box
          sx={{
            width: '90%',
            display: 'flex',
            margin: '0 auto',
            '@media screen and (min-width: 1300px)': { width: '1274px' },
            '@media screen and (max-width: 992px)': { flexDirection: 'column' },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <HomePageMainArrowIcon
              sx={{ '@media screen and (max-width: 992px)': { width: '30px !important', height: '30px !important' } }}
            />
            <Box
              sx={{
                textTransform: 'capitalize',
                ml: '46px',
                mr: '86px',
                width: ' 262px',

                '@media screen and (max-width: 992px)': { ml: '14px' },
              }}
            >
              <Typography
                sx={{
                  fontSize: '58px',
                  fontWeight: 600,
                  color: black,
                  lineHeight: 1,
                  '@media screen and (max-width: 992px)': { fontSize: '30px' },
                }}
              >
                Contact
              </Typography>
              <Typography
                sx={{
                  fontSize: '58px',
                  fontWeight: 600,
                  color: black,
                  '@media screen and (max-width: 992px)': { fontSize: '30px', mb: '28px' },
                }}
              >
                us
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <ContactUs />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomePageMain;

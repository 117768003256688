import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Menu, MenuItem, SwipeableDrawer, Typography } from '@mui/material';
import UrexLogoLight from 'app/components/Icons/UrexLogoLight';
import { NavLink, useNavigate } from 'react-router-dom';
import { headerBox, loginBtn, registerBtn, style, mobileHeader } from './Header.sx';
import LoginProfileIcon from 'app/components/Icons/LoginProfileIcon';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { blue, white } from 'app/components/ThemeProvider/colors';
import { jwtDecode } from 'jwt-decode';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from 'app/pages/Authorization/hook/AuthProvider';

interface JwtPayload {
  user_name: string;
}

const Header = () => {
  const { updateState, state } = useAuth();
  const menus = [
    { title: 'Platform', link: '/listings' },
    { title: 'Features', link: '/features' },
    { title: 'Who we serve', link: '/about' },
    { title: 'Resources', link: '/resources' },
  ];

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const [userEmail, setUserEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMobile = useMediaQuery('(max-width: 1100px)');

  useEffect(() => {
    if (token !== null) {
      const email = jwtDecode(token) as JwtPayload;
      setUserEmail(email.user_name);
    }
  }, []);

  const handleRedirect = (to: string) => {
    navigate(`/${to}`);
  };

  const signOut = () => {
    localStorage.removeItem('token');
    updateState({ ...state, isAuth: false });
    navigate('/auth/login');
  };

  return (
    <>
      {!isMobile ? (
        <Box sx={headerBox}>
          <Box sx={{ display: 'flex' }}>
            {menus.map((item, index) => (
              <NavLink key={index} to={item.link} style={({ isActive }) => (isActive ? style.activeLink : style.link)}>
                {item.title}
              </NavLink>
            ))}
          </Box>
          <UrexLogoLight sx={{ position: 'absolute', left: 'calc(50% - 52px)' }} />
          {token === null ? (
            <Box sx={{ display: 'flex', gap: '30px' }}>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => handleRedirect('auth/registration')}
              >
                Register
              </Button>
              <Button sx={loginBtn} onClick={() => handleRedirect('auth/login')}>
                Sign in <LoginProfileIcon />
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                variant={'text'}
                sx={{ display: 'flex', gap: '10px', alignItems: 'center', padding: 0 }}
                onClick={(event: React.MouseEvent<any>) => {
                  setAnchorEl(event.currentTarget);
                  setOpenMenu(true);
                }}
                id="basic-button"
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
              >
                <AccountCircleOutlinedIcon sx={{ width: '40px !important', height: '40px !important', fill: white }} />
                <Typography sx={{ color: white, fontWeight: 700 }}>{userEmail}</Typography>
                <KeyboardArrowDownIcon sx={{ fill: white }} />
              </Button>
              <Menu
                sx={{ '& .MuiPaper-root': { width: '196px', mt: '10px' } }}
                id="basic-menu"
                open={openMenu}
                anchorEl={anchorEl}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                onClose={() => setOpenMenu(false)}
              >
                <MenuItem onClick={() => handleRedirect('dashboard/contacts')}>Go to Dashboard</MenuItem>
                <MenuItem onClick={() => handleRedirect('account')}>Account Settings</MenuItem>
                <MenuItem onClick={signOut}>Sign out</MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={mobileHeader}>
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon sx={{ fill: white }} />
          </IconButton>

          <UrexLogoLight sx={{ position: 'absolute', left: 'calc(50% - 52px)' }} />
        </Box>
      )}

      <SwipeableDrawer anchor="left" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            background: blue,
            pt: '60px',
            pl: '24px',
            pr: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            alignItems: 'center',
            overflowY: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <UrexLogoLight sx={{ width: '60px', height: '62px' }} />
            <CloseIcon sx={{ fill: white }} onClick={() => setOpen(false)} />
          </Box>
          <Box sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column', gap: '36px' }}>
            {menus.map((item, index) => (
              <NavLink key={index} to={item.link} style={({ isActive }) => (isActive ? style.activeLink : style.link)}>
                {item.title}
              </NavLink>
            ))}
          </Box>
          <Button sx={loginBtn} onClick={() => handleRedirect('login')}>
            Sign in <LoginProfileIcon />
          </Button>
          <Button sx={registerBtn} onClick={() => handleRedirect('registration')}>
            Register
          </Button>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default Header;

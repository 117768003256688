/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { Dispatch, SetStateAction, ReactNode } from 'react';
import { Error } from 'app/constants/models';

export type Reminder = {
  id: number;
  subject?: string;
  time?: string;
  note?: string;
  type?: 1 | 2 | 3 | 4 | 5;
  priority?: 1 | 2 | 3;
  status?: 1 | 2 | 3;
  contacts?: Contact[];
  newContactsId?: number[];
  removeContactsId?: number[];
};

export type Contact = {
  id: number;
  address: string;
  email: string;
  lastName: string;
  name: string;
  phoneNumber: string;
};

export type RemindersQueryResponse = Array<Reminder>;

export const initialReminder: Reminder = {
  id: 1,
  subject: 'Reminder test',
  time: '2024-04-07T12:28:00.25Z',
  type: 1,
  priority: 1,
  status: 1,
};

export type ID = undefined | null | number;

export type WithChildren = {
  children?: ReactNode;
};

export type SearchState = {
  Status?: 1 | 2 | 3;
};

export type QueryState = SearchState;

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
  Status: 1,
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};

export type QueryResponseContextProps<T> = {
  response?: Array<T>;
  refetch: () => void;
  isLoading: boolean;
  query?: string;
  error?: Error;
};

export const initialQueryResponse = { refetch: () => {}, isLoading: false, query: '' };

export type ListViewContextProps = {
  selected: Array<ID>;
  onSelect: (selectedId: ID) => void;
  onSelectAll: () => void;
  clearSelected: () => void;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID;
  itemIsUpdate?: boolean;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  setItemIsUpdate: Dispatch<SetStateAction<boolean>>;
  isAllSelected: boolean;
  disabled: boolean;
  isOpenCreateDrawer?: boolean;
  setIsOpenCreateDrawer: Dispatch<SetStateAction<boolean>>;
  isOpenDetails?: boolean;
  setIsOpenDetails: Dispatch<SetStateAction<boolean>>;
};

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemIsUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  itemIsUpdate: false,
  isOpenCreateDrawer: false,
  setIsOpenCreateDrawer: () => {},
  isOpenDetails: false,
  setIsOpenDetails: () => {},
};

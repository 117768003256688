import { SxProps } from '@mui/material';
import { black5 } from 'app/components/ThemeProvider/colors';
export const drawerBody: SxProps = {
  minWidth: '320px',
  maxWidth: '100%',
  padding: '20px',

  '@media only screen and (min-width: 576px)': {
    padding: '60px',
    maxWidth: '570px',
    width: '570px',
  },
};

export const drawerTextArea: SxProps = {
  width: '100%',
  fontSize: '12px',
  '& .MuiInputBase-root ': {
    height: '95px !important',
    fontSize: '12px',
    color: black5,
    '& textarea': {
      height: '95px !important',
      padding: '12px 0',
    },
  },
};

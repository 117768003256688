/* eslint-disable padding-line-between-statements */
/* eslint-disable curly */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { FC, PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';
import { HeaderProps } from 'react-table';
import { Contact } from '../../../core/_models';
import { useQueryRequest } from '../../../core/QueryRequestProvider';
import { capitalizeFirstLetter } from 'app/constants/helpers';
import logger from 'app/utils/logger';

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<Contact>>;
};

const ContactCustomHeader: FC<Props> = ({ className, title, tableProps }) => {
  const id = capitalizeFirstLetter(tableProps.column.id);
  const { state, updateState } = useQueryRequest();

  const isSelectedForSorting = useMemo(() => state.OrderByParameter && state.OrderByParameter === id, [state, id]);

  const order: 0 | 1 = useMemo(() => state.OrderByType, [state]);

  const sortColumn = () => {
    if (id === 'Actions' || id === 'Selection') return;
    updateState({ ...state, OrderByParameter: id, OrderByType: order === 0 ? 1 : 0 });
    return;
  };

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      style={{ cursor: 'pointer' }}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order === 1 ? 'asc' : 'desc'}`,
        'cursor-pointer'
      )}
      onClick={sortColumn}
    >
      {title}
    </th>
  );
};

export { ContactCustomHeader };

export const reminderTypes = [
  { id: 1, name: 'Todo' },
  { id: 2, name: 'Follow up' },
  { id: 3, name: 'Email' },
  { id: 4, name: 'Text' },
  { id: 5, name: 'Phone call' },
];

export const reminderPriority = [
  { id: 1, name: 'Low' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'High' },
];

export const priorityData = {
  1: { status: 'Low', class: 'bg-warning-reminder' },
  2: { status: 'Medium', class: 'bg-success-reminder' },
  3: { status: 'High', class: 'bg-danger-reminder' },
};

export const typesData = {
  1: 'Todo',
  2: 'Follow up',
  3: 'Email',
  4: 'Text',
  5: 'Phone call',
};

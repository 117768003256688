/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { FC, useState, useEffect, createContext, useContext, ReactNode } from 'react';

type State = {
  token: string;
  refreshToken: string;
  isAuth?: boolean | null;
};

interface AuthContextProps {
  state: State;
  updateState: (updates: State) => void;
}

type WithChildren = {
  children?: ReactNode;
};
const initialContextState: AuthContextProps = {
  state: { token: '', refreshToken: '', isAuth: Boolean(localStorage.getItem('token')) },
  updateState: () => {},
};

const AuthContext = createContext<AuthContextProps>(initialContextState);

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<State>(initialContextState.state);

  const updateState = (updates: State) => {
    setState(() => updates);
  };

  const token = localStorage.getItem('token');

  useEffect(() => {
    updateState({ ...state, isAuth: Boolean(token) });
  }, [token]);

  return <AuthContext.Provider value={{ state, updateState }}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface Fill {
  fill: string;
}

const MarketingDescriptionIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  const sxStyle = sx as Fill;

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: '20px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M18.2449 8.30384L10.4446 0.502376C9.98457 0.043469 9.24038 0.0488987 8.78636 0.50179C8.32976 0.959565 8.32976 1.70285 8.78636 2.16004L16.5878 9.96095C17.0449 10.418 17.7879 10.418 18.2449 9.96095C18.7032 9.50267 18.7032 8.76204 18.2449 8.30384Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
      <path
        d="M8.20761 3.23853L8.1489 3.53282C7.73535 5.6015 6.73027 7.56678 5.37085 9.15963L9.61488 13.4037C11.2075 12.0445 13.1457 11.0124 15.2145 10.5984L15.5093 10.5397L8.20761 3.23853Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
      <path
        d="M4.59278 10.0386L1.69227 12.9385C1.00692 13.6239 1.00407 14.7365 1.69227 15.4247L3.34938 17.0818C4.03474 17.7672 5.14739 17.77 5.8356 17.0818L8.73584 14.1816L4.59278 10.0386ZM5.00708 14.5962C4.77821 14.8251 4.40739 14.8251 4.17852 14.5962C3.94966 14.3673 3.94966 13.9965 4.17852 13.7676L5.00708 12.9391C5.23595 12.7102 5.60677 12.7102 5.83564 12.9391C6.06451 13.168 6.06451 13.5388 5.83564 13.7676L5.00708 14.5962Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
      <path
        d="M11.1823 17.3937L11.9794 16.5966C12.6676 15.9085 12.6654 14.7958 11.9794 14.111L11.3874 13.5186C11.0602 13.7384 10.7477 13.9772 10.4438 14.232L11.1509 14.9401C11.3792 15.1673 11.3808 15.537 11.1509 15.7681L10.3406 16.5783L9.14993 15.4247L7.49274 17.0819L10.1512 19.6579C10.6049 20.1128 11.3498 20.1154 11.8077 19.6574C12.2649 19.2002 12.2649 18.4569 11.8077 17.9997L11.1823 17.3937Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
      <path
        d="M13.5494 0C13.2255 0 12.9634 0.262071 12.9634 0.585939V1.75782C12.9634 2.08168 13.2255 2.34375 13.5494 2.34375C13.8732 2.34375 14.1353 2.08168 14.1353 1.75782V0.585939C14.1353 0.262071 13.8732 0 13.5494 0Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
      <path
        d="M18.2369 4.6875H17.0651C16.7412 4.6875 16.4791 4.94957 16.4791 5.27344C16.4791 5.59731 16.7412 5.85938 17.0651 5.85938H18.2369C18.5608 5.85938 18.8229 5.59731 18.8229 5.27344C18.8229 4.94957 18.5608 4.6875 18.2369 4.6875Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
      <path
        d="M17.4793 1.34353C17.2504 1.11466 16.8796 1.11466 16.6507 1.34353L15.4788 2.5154C15.25 2.74427 15.25 3.11509 15.4788 3.34396C15.7077 3.57283 16.0785 3.57287 16.3074 3.34396L17.4793 2.17208C17.7081 1.94321 17.7081 1.57239 17.4793 1.34353Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
    </SvgIcon>
  );
};

export default MarketingDescriptionIcon;

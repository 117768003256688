import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useForgotPassService } from 'app/utils/services/authService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logger from 'app/utils/logger';

const useForgotPass = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const confirm = searchParams.get('confirm');
  const newPass = searchParams.get('new_password');
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm({ resolver: yupResolver(schema) });
  const { isSuccessForgotPass, dataForgotPass, forgotPassMutation } = useForgotPassService();

  useEffect(() => {
    if (dataForgotPass?.message !== undefined) {
      setOpenSnackbar(true);
    }
  }, [dataForgotPass]);

  useEffect(() => {
    if (isSuccessForgotPass) {
      navigate('/auth/reset-password?confirm=password_reset');
    }
  }, [isSuccessForgotPass]);

  const onSubmit = (data: any) => {
    forgotPassMutation({ email: data.email });
  };

  const onFormSubmit = handleSubmit(onSubmit);

  return {
    openSnackbar,
    setOpenSnackbar,
    register,
    errors,
    onFormSubmit,
    dataForgotPass,
    confirm,
    getValues,
    newPass,
  };
};

export default useForgotPass;

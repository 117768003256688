import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const UrexLogo = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 60 62"
      sx={{
        height: '62px !important',
        width: '60px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M13.0356 25.2677C12.8578 25.9121 12.7689 26.712 12.7689 27.6674V35.0663C12.7689 35.9329 12.8578 36.7439 13.0356 37.4993H10.9025C10.9692 37.2549 11.0136 37.0216 11.0358 36.7994C11.0581 36.555 11.0914 36.3106 11.1358 36.0662C10.136 37.1327 8.98058 37.6659 7.66966 37.6659C6.38095 37.6659 5.32555 37.1993 4.50344 36.2661C3.68134 35.3107 3.27028 33.9776 3.27028 32.2667V27.6674C3.27028 26.8897 3.20363 26.0898 3.07031 25.2677H5.17001C4.99226 26.0676 4.90338 26.8675 4.90338 27.6674V32.2667C4.90338 33.511 5.10336 34.4331 5.5033 35.033C6.08099 35.9662 6.9031 36.4328 7.96961 36.4328C9.03613 36.4328 9.88045 36.0106 10.5026 35.1663C10.947 34.4997 11.1692 33.5332 11.1692 32.2667V27.6674C11.1692 26.8675 11.0803 26.0676 10.9025 25.2677H13.0356Z"
        fill="#21364B"
      />
      <path
        d="M17.6969 26.2676C17.6302 27.0008 17.5969 27.4674 17.5969 27.6674V31.5335H18.5634C19.3633 31.5335 19.9965 31.3002 20.4631 30.8336C20.952 30.3448 21.1964 29.7115 21.1964 28.9339C21.1964 28.1562 20.9742 27.523 20.5298 27.0341C19.9743 26.5231 19.3189 26.2676 18.5634 26.2676H17.6969ZM19.1633 32.5333L18.5634 32.5667H17.5969V35.0663C17.5969 35.9329 17.6858 36.7439 17.8635 37.4993H15.7638C15.8971 36.6994 15.9638 35.8884 15.9638 35.0663V27.6674C15.9638 26.8897 15.8971 26.0898 15.7638 25.2677H18.5634C19.8966 25.2677 20.952 25.6121 21.7296 26.3009C22.5517 26.9897 22.9628 27.8673 22.9628 28.9339C22.9628 29.9782 22.5517 30.8447 21.7296 31.5335C21.5741 31.6446 21.4186 31.7557 21.263 31.8668C21.1075 31.9779 20.9409 32.0667 20.7631 32.1334L23.0628 35.5996C23.5294 36.2884 24.0404 36.9216 24.5959 37.4993H22.1629C21.7407 36.5217 21.3519 35.7995 20.9964 35.3329L19.1633 32.5333Z"
        fill="#21364B"
      />
      <path
        d="M27.8249 35.0663C27.8249 35.2219 27.8582 35.6996 27.9249 36.4994H30.0913C30.98 36.4994 31.791 36.4217 32.5242 36.2661V37.4993H25.9918C26.1252 36.6994 26.1918 35.8884 26.1918 35.0663V27.6674C26.1918 26.8897 26.1252 26.0898 25.9918 25.2677H31.5577V26.5009C30.6245 26.3453 29.8135 26.2676 29.1247 26.2676H27.9249C27.9027 26.512 27.8805 26.7564 27.8582 27.0008C27.836 27.2452 27.8249 27.4674 27.8249 27.6674V30.8669H29.1247C29.6802 30.8669 30.4912 30.778 31.5577 30.6003V32.1001C30.8689 31.9445 30.0579 31.8668 29.1247 31.8668H27.8249V35.0663Z"
        fill="#21364B"
      />
      <path
        d="M33.6643 37.4993C34.1531 37.0549 34.6864 36.4328 35.2641 35.6329L38.1637 31.5668L35.1974 27.1674C34.7753 26.5675 34.2642 25.9343 33.6643 25.2677H36.1306C36.3528 25.9565 36.7194 26.6786 37.2305 27.4341L39.1302 30.2003L41.1299 27.4007C41.5743 26.7786 41.9742 26.0676 42.3298 25.2677H44.2295C43.6296 25.8454 43.0963 26.4564 42.6297 27.1008L39.7301 31.1669L42.6964 35.5996C43.0074 36.0884 43.5185 36.7216 44.2295 37.4993H41.7632C41.5188 36.8772 41.1521 36.1551 40.6633 35.3329L38.7636 32.5333L36.7639 35.3663C36.2751 36.0106 35.864 36.7216 35.5307 37.4993H33.6643Z"
        fill="#21364B"
      />
      <path
        d="M55.0673 28.5006C54.1119 26.9008 52.9454 26.1009 51.5678 26.1009C50.2125 26.1009 49.1348 26.6453 48.3349 27.734C47.5351 28.8228 47.1351 30.0448 47.1351 31.4002C47.1351 32.7555 47.5128 33.9443 48.2683 34.9663C49.1348 36.0773 50.2013 36.6328 51.4678 36.6328C53.112 36.6328 54.5118 35.7995 55.6672 34.1331H55.7672V35.8329C54.5452 37.0549 53.1009 37.6659 51.4345 37.6659C49.7681 37.6659 48.3349 37.066 47.1351 35.8662C45.9575 34.6442 45.3687 33.1555 45.3687 31.4002C45.3687 29.6449 45.9575 28.1562 47.1351 26.9341C48.3127 25.7121 49.6903 25.1011 51.2679 25.1011C52.8454 25.1011 54.1341 25.5232 55.134 26.3676V28.5006H55.0673Z"
        fill="#21364B"
      />
      <path
        d="M53.808 17.086C46.2152 3.93487 29.3989 -0.571049 16.2477 7.02178C13.1157 8.83006 10.4769 11.1588 8.37462 13.8344C6.05771 16.7833 4.39118 20.1544 3.43671 23.716H0C1.02406 19.4044 2.97079 15.3175 5.75393 11.7753C8.11428 8.77124 11.0752 6.15966 14.5813 4.13544C29.3265 -4.37773 48.1812 0.674363 56.6944 15.4196C58.2301 18.0796 59.3244 20.8734 60 23.716H56.5615C55.9519 21.4463 55.0389 19.218 53.808 17.086Z"
        fill="url(#paint0_linear_1122_1443)"
      />
      <path
        d="M51.3414 23.716C50.8697 22.3064 50.2506 20.9229 49.4785 19.5857C43.2662 8.82564 29.5074 5.13897 18.7473 11.3513C13.7887 14.2142 10.3322 18.6798 8.65448 23.716H12.2019C13.7414 19.8602 16.5393 16.4746 20.4138 14.2376C29.5797 8.94565 41.3002 12.0861 46.5922 21.2521C47.0562 22.0558 47.4554 22.8791 47.7913 23.716H51.3414Z"
        fill="url(#paint1_linear_1122_1443)"
      />
      <path
        d="M47.4663 38.7138H51.0681C49.3049 43.4372 45.9514 47.5992 41.2441 50.3169C30.4841 56.5292 16.7253 52.8425 10.513 42.0825C9.8819 40.9895 9.353 39.8655 8.92328 38.7217H3.6488C4.25054 40.7255 5.09203 42.6917 6.18344 44.5821C9.83282 50.903 15.6095 55.2252 22.0903 57.1693C29.0958 59.2707 36.9127 58.5903 43.7438 54.6464C50.0723 50.9926 54.3988 45.2031 56.3386 38.7138H59.8007C57.7751 46.3747 52.8149 53.2576 45.4102 57.5327C37.7514 61.9545 28.9809 62.7159 21.1327 60.3616C13.8704 58.1831 7.388 53.3342 3.2971 46.2485C1.89865 43.8264 0.86632 41.2935 0.182851 38.7138H0.186538V37.8885H23.8443V38.7197C26.9607 41.1491 31.3624 41.5904 34.995 39.4931C35.4041 39.2569 35.7902 38.9962 36.1525 38.7138H40.7533C39.6919 40.1645 38.3182 41.4229 36.6615 42.3794C30.7113 45.8148 23.2138 44.142 19.2466 38.7217H12.5287C12.7882 39.2945 13.0783 39.86 13.3993 40.416C18.6913 49.582 30.4117 52.7225 39.5777 47.4305C43.195 45.3421 45.8738 42.2525 47.4663 38.7138Z"
        fill="url(#paint2_linear_1122_1443)"
      />
      <path
        d="M41.2681 23.716C37.4689 17.6904 29.5531 15.6958 23.33 19.2887C21.3896 20.409 19.8374 21.9435 18.7205 23.716H22.9706C23.5641 23.131 24.2411 22.6112 24.9965 22.1751C28.9836 19.8731 33.8972 20.6293 37.021 23.716H41.2681Z"
        fill="url(#paint3_linear_1122_1443)"
      />
      <path d="M37.0292 24.5505L60 24.5505V23.7173L37.0292 23.7173V24.5505Z" fill="url(#paint4_linear_1122_1443)" />
      <defs>
        <linearGradient
          id="paint0_linear_1122_1443"
          x1="30"
          y1="5.92548e-08"
          x2="27.2841"
          y2="77.5797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1122_1443"
          x1="30"
          y1="5.92548e-08"
          x2="27.2841"
          y2="77.5797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1122_1443"
          x1="30"
          y1="5.92548e-08"
          x2="27.2841"
          y2="77.5797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1122_1443"
          x1="30"
          y1="5.92548e-08"
          x2="27.2841"
          y2="77.5797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1122_1443"
          x1="30"
          y1="5.92548e-08"
          x2="27.2841"
          y2="77.5797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default UrexLogo;

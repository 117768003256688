import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const UrexLogoSecond = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <SvgIcon
      onClick={() => navigate('/')}
      viewBox="0 0 108 27"
      sx={{
        height: '27px !important',
        width: '108px !important',
        fill: 'none',
        cursor: 'pointer',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M20.4233 0.766332C20.059 2.0869 19.8769 3.72624 19.8769 5.68433V20.8481C19.8769 22.6241 20.059 24.2862 20.4233 25.8344H16.0518C16.1884 25.3335 16.2795 24.8554 16.325 24.4C16.3705 23.8991 16.4388 23.3982 16.5299 22.8973C14.4808 25.0831 12.1128 26.176 9.42615 26.176C6.78501 26.176 4.622 25.2197 2.93713 23.3071C1.25227 21.349 0.409833 18.6168 0.409833 15.1105V5.68433C0.409833 4.09053 0.273222 2.4512 0 0.766332H4.30324C3.93895 2.40566 3.7568 4.04499 3.7568 5.68433V15.1105C3.7568 17.6605 4.16663 19.5503 4.9863 20.7798C6.17026 22.6924 7.85513 23.6487 10.0409 23.6487C12.2267 23.6487 13.9571 22.7835 15.2321 21.0531C16.1429 19.6869 16.5982 17.7061 16.5982 15.1105V5.68433C16.5982 4.04499 16.4161 2.40566 16.0518 0.766332H20.4233Z"
        fill="white"
      />
      <path
        d="M29.9765 2.8155C29.8399 4.31822 29.7716 5.27449 29.7716 5.68433V13.6078H31.7524C33.3918 13.6078 34.6896 13.1296 35.6458 12.1733C36.6477 11.1715 37.1486 9.87373 37.1486 8.27993C37.1486 6.68614 36.6932 5.38833 35.7825 4.38652C34.644 3.33917 33.3007 2.8155 31.7524 2.8155H29.9765ZM32.9819 15.6569L31.7524 15.7252H29.7716V20.8481C29.7716 22.6241 29.9537 24.2862 30.318 25.8344H26.0148C26.288 24.1951 26.4246 22.533 26.4246 20.8481V5.68433C26.4246 4.09053 26.288 2.4512 26.0148 0.766332H31.7524C34.4847 0.766332 36.6477 1.47216 38.2415 2.8838C39.9263 4.29545 40.7688 6.09416 40.7688 8.27993C40.7688 10.4202 39.9263 12.1961 38.2415 13.6078C37.9227 13.8354 37.6039 14.0631 37.2852 14.2908C36.9664 14.5185 36.6249 14.7006 36.2606 14.8373L40.9737 21.941C41.9299 23.3527 42.9773 24.6505 44.1157 25.8344H39.1294C38.2642 23.8308 37.4673 22.3509 36.7387 21.3946L32.9819 15.6569Z"
        fill="white"
      />
      <path
        d="M50.7335 20.8481C50.7335 21.1669 50.8018 22.1459 50.9384 23.7853H55.3782C57.1997 23.7853 58.8618 23.6259 60.3645 23.3071V25.8344H46.9767C47.2499 24.1951 47.3865 22.533 47.3865 20.8481V5.68433C47.3865 4.09053 47.2499 2.4512 46.9767 0.766332H58.3837V3.29363C56.4711 2.97487 54.809 2.8155 53.3974 2.8155H50.9384C50.8929 3.3164 50.8473 3.81731 50.8018 4.31822C50.7562 4.81912 50.7335 5.27449 50.7335 5.68433V12.2416H53.3974C54.5358 12.2416 56.1979 12.0595 58.3837 11.6952V14.769C56.972 14.4502 55.3099 14.2908 53.3974 14.2908H50.7335V20.8481Z"
        fill="white"
      />
      <path
        d="M62.7011 25.8344C63.7029 24.9237 64.7958 23.6487 65.9797 22.0093L71.9223 13.6761L65.8431 4.65974C64.9779 3.43024 63.9306 2.13244 62.7011 0.766332H67.7557C68.2111 2.17798 68.9624 3.65793 70.0098 5.20619L73.9032 10.8755L78.0015 5.13788C78.9122 3.86285 79.7319 2.40566 80.4605 0.766332H84.3539C83.1244 1.95029 82.0315 3.20256 81.0752 4.52313L75.1327 12.8564L81.2119 21.941C81.8494 22.9428 82.8967 24.2406 84.3539 25.8344H79.2993C78.7984 24.5594 78.047 23.0794 77.0452 21.3946L73.1518 15.6569L69.0535 21.4629C68.0517 22.7835 67.2092 24.2406 66.5262 25.8344H62.7011Z"
        fill="white"
      />
      <path
        d="M106.566 7.39196C104.607 4.1133 102.217 2.47397 99.3935 2.47397C96.6158 2.47397 94.4072 3.58962 92.7679 5.82094C91.1286 8.05225 90.3089 10.5568 90.3089 13.3345C90.3089 16.1123 91.083 18.5485 92.6313 20.6432C94.4072 22.9201 96.593 24.0585 99.1886 24.0585C102.558 24.0585 105.427 22.3509 107.795 18.9356H108V22.4192C105.495 24.9237 102.536 26.176 99.1203 26.176C95.705 26.176 92.7679 24.9465 90.3089 22.4875C87.8954 19.9829 86.6887 16.932 86.6887 13.3345C86.6887 9.73711 87.8954 6.68614 90.3089 4.1816C92.7223 1.67707 95.5456 0.424805 98.7788 0.424805C102.012 0.424805 104.653 1.29001 106.702 3.02041V7.39196H106.566Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default UrexLogoSecond;

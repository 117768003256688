/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, ChangeEvent } from 'react';
import CustomInput from 'app/components/CustomInput';
import { Actions } from '../actions/Actions';
import { Create } from '../create/Create';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { useDebounce } from 'app/constants/helpers';

const MyPropertyHeader = () => {
  const { updateState } = useQueryRequest();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      updateState({ NameSurname: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <CustomInput
          label="Search by name or last name"
          value={searchTerm}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
        />
      </div>
      <div className="col-12 col-md-8 d-flex justify-content-md-end align-items-center align-items-md-start flex-column flex-sm-row">
        <Create />
        <Actions />
      </div>
    </div>
  );
};

export { MyPropertyHeader };

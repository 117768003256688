/* eslint-disable padding-line-between-statements */
/* eslint-disable curly */
import * as Yup from 'yup';

export const contactSchema = Yup.object({
  ownerName: Yup.string().required('This field is required'),
  groupId: Yup.object().required('This field is required!'),
  myContactId: Yup.object(),
  typeId: Yup.object(),
  title: Yup.string(),
  note: Yup.string(),
  address: Yup.object({
    fullAddress: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    county: Yup.string(),
    city: Yup.string(),
    street: Yup.string(),
    postalCode: Yup.string(),
    longitude: Yup.string(),
    latitude: Yup.string(),
  }),
});

export const moveToSchema = Yup.object({
  listId: Yup.object().required('This field is required'),
});

/* eslint-disable curly */
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useForm } from 'react-hook-form';
import { useGetAllProperties } from 'app/utils/services/propertyService';
import { useAddListingDraft, useGetAddListingData, usePublish } from 'app/utils/services/addListingService';
import { useLocation, useNavigate } from 'react-router-dom';


const usePreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = location.pathname.split('/')[2];
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [lotSizeValue, setLotSizeValue] = useState('');
  const [switchValue, setSwitchValue] = useState('SF');
  const [closingResDate, setClosingResDate] = useState('');
  const [checked, setChecked] = useState(false);
  const [fileFromBack, setFileFromBack] = useState<string>();
  const [radioButtonValue, setRadioButtonValue] = useState<number>();
  const [fileFromBackAgreement, setFileFromBackAgreement] = useState<any>();
  const [fileFromBackDocs, setFileFromBackDocs] = useState<any>();
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const [showTextField, setShowTextField] = useState(true);

  const [openInfo, setOpenInfo] = useState(true);
  const [openAddress, setOpenAddress] = useState(true);
  const [openDetail, setOpenDetail] = useState(true);
  const [openDesc, setOpenDesc] = useState(true);
  const [openPrice, setOpenPrice] = useState(true);
  const [openBrochure, setOpenBrochure] = useState(true);
  const [openDescInvest, setOpenDescInvest] = useState(true);
  const [openCondition, setOpenCondition] = useState(true);
  const [openPhoto, setOpenPhoto] = useState(true);
  const [openVideo, setOpenVideo] = useState(true);
  const [openAgreement, setOpenAgreement] = useState(true);
  const [openConfigDocs, setOpenConfigDocs] = useState(true);
  const [openDetails, setOpenDetails] = useState(true);

  const isMobile = useMediaQuery('(max-width: 992px)');
  const isMobilePhone = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    if (!isMobile && !openInfo) {
      setOpenInfo(true);
    }
    if (!isMobile && !openAddress) {
      setOpenAddress(true);
    }
    if (!isMobile && !openDetail) {
      setOpenDetail(true);
    }
    if (!isMobile && !openDesc) {
      setOpenDesc(true);
    }
    if (!isMobile && !openPrice) {
      setOpenPrice(true);
    }
    if (!isMobile && !openBrochure) {
      setOpenBrochure(true);
    }
    if (!isMobile && !openDescInvest) {
      setOpenDescInvest(true);
    }
    if (!isMobile && !openCondition) {
      setOpenCondition(true);
    }
    if (!isMobile && !openPhoto) {
      setOpenPhoto(true);
    }
    if (!isMobile && !openVideo) {
      setOpenVideo(true);
    }
    if (!isMobile && !openAgreement) {
      setOpenAgreement(true);
    }
    if (!isMobile && !openConfigDocs) {
      setOpenConfigDocs(true);
    }
    if (!isMobile && !openDetails) {
      setOpenDetails(true);
    }
  }, [isMobile]);

  const handleClick = (set: any, value: boolean) => {
    if (isMobile) {
      set(!value);
    }
  };

  const token = localStorage.getItem('token');
  const sessionId = sessionStorage.getItem(role === 'commercial' ? 'commercialId' : 'residentialId');

  const { handleSubmit, control, register, setValue, getValues } = useForm();

  const { isLoadingAllProperties, dataAllProperties } = useGetAllProperties(
    role === 'residential' ? 2 : role === 'commercial' && 1
  );

  const { isLoadingGetAddListingData, dataGetAddListingData, dataGetAddListingError } = useGetAddListingData(
    role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
    sessionId,
    {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  );

  const { isSuccessPublish, publishData, publishMutate } = usePublish();

  useEffect(() => {
    if (
      sessionId !== null &&
      dataGetAddListingData !== undefined &&
      !isLoadingGetAddListingData &&
      !isLoadingAllProperties
    ) {
      setValue('propertyName', dataGetAddListingData?.information?.name || '');
      setValue('fullAddress', dataGetAddListingData?.information?.fullAddress || '');
      setValue('street', dataGetAddListingData?.information?.street || '');
      setValue('city', dataGetAddListingData?.information?.city || '');
      setValue('state', dataGetAddListingData?.information?.state || '');
      setValue('zipCode', dataGetAddListingData?.information?.postalCode || '');
      setValue('country', dataGetAddListingData?.information?.country || '');
      setValue('county', dataGetAddListingData?.information?.county || '');
      setValue('propertyType', dataGetAddListingData?.information?.type || '');
      setValue('occupancy', dataGetAddListingData?.information?.occupancy || '');
      setValue('totalSqFt', dataGetAddListingData?.information?.totalSqFt || '');
      setValue('usableSqFT', dataGetAddListingData?.information?.usableSqFT || '');
      setValue('units', dataGetAddListingData?.information?.units || '');
      setValue('buildingClass', dataGetAddListingData?.information?.buildingClass || '');
      setValue('stories', dataGetAddListingData?.information?.stories || '');
      setValue('parkingSpaces', dataGetAddListingData?.information?.parkingSpaces || '');
      setValue('zoning', dataGetAddListingData?.information?.zoning || '');
      setValue('yearRenovated', dataGetAddListingData?.information?.yearRenovated || '');
      setValue('bathroom', dataGetAddListingData?.information?.bathroom || '');
      setValue('bedroom', dataGetAddListingData?.information?.bedroom || '');
      setValue('yearBuilt', dataGetAddListingData?.information?.yearBuilt || '');
      setValue('lotSize', dataGetAddListingData?.information?.lotSize || '');
      setValue('parking', dataGetAddListingData?.information?.parking || '');
      setValue('style', dataGetAddListingData?.information?.style || '');
      setValue('heating', dataGetAddListingData?.information?.parking || '');
      setValue('roofing', dataGetAddListingData?.information?.roofing || '');
      setValue('cooling', dataGetAddListingData?.information?.parking || '');
      setValue('construction', dataGetAddListingData?.information?.construction || '');
      setValue('foundation', dataGetAddListingData?.information?.foundation || '');
      setValue('basement', dataGetAddListingData?.information?.basement || '');
      setLotSizeValue(dataGetAddListingData?.information?.lotSize || '');
      setLat(dataGetAddListingData?.information?.latitude || '');
      setLng(dataGetAddListingData?.information?.longitude || '');

      // Find object fields index and set datas
      const typeIndex = dataAllProperties?.type?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.type
      );
      if (typeIndex > -1) {
        setValue('propertyType', {
          id: dataAllProperties?.type[typeIndex]?.id,
          name: dataAllProperties?.type[typeIndex]?.name,
        });
      } else {
        setValue('propertyType', undefined);
      }
      const ocupancyIndex = dataAllProperties?.ocuppancy?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.occupancy
      );

      if (ocupancyIndex > -1) {
        setValue('occupancy', {
          id: dataAllProperties?.ocuppancy[ocupancyIndex]?.id,
          name: dataAllProperties?.ocuppancy[ocupancyIndex]?.name,
        });
      } else {
        setValue('occupancy', undefined);
      }

      const buildingClassIndex = dataAllProperties?.buildingClass?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.buildingClass
      );
      if (buildingClassIndex > -1) {
        setValue('buildingClass', {
          id: dataAllProperties?.buildingClass[buildingClassIndex]?.id,
          name: dataAllProperties?.buildingClass[buildingClassIndex]?.name,
        });
      } else {
        setValue('buildingClass', undefined);
      }

      const parkingClassIndex = dataAllProperties?.parking?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.parking
      );
      if (parkingClassIndex > -1) {
        setValue('parking', {
          id: dataAllProperties?.parking[parkingClassIndex]?.id,
          name: dataAllProperties?.parking[parkingClassIndex]?.name,
        });
        setValue('heating', {
          id: dataAllProperties?.heating[parkingClassIndex]?.id,
          name: dataAllProperties?.heating[parkingClassIndex]?.name,
        });
        setValue('cooling', {
          id: dataAllProperties?.cooling[parkingClassIndex]?.id,
          name: dataAllProperties?.cooling[parkingClassIndex]?.name,
        });
      } else {
        setValue('parking', undefined);
        setValue('heating', undefined);
        setValue('cooling', undefined);
      }

      const styleClassIndex = dataAllProperties?.style?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.style
      );
      if (styleClassIndex > -1) {
        setValue('style', {
          id: dataAllProperties?.style[parkingClassIndex]?.id,
          name: dataAllProperties?.style[parkingClassIndex]?.name,
        });
      } else {
        setValue('style', undefined);
      }

      const roofingClassIndex = dataAllProperties?.roofing?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.roofing
      );
      if (roofingClassIndex > -1) {
        setValue('roofing', {
          id: dataAllProperties?.roofing[roofingClassIndex]?.id,
          name: dataAllProperties?.roofing[roofingClassIndex]?.name,
        });
      } else {
        setValue('roofing', undefined);
      }

      const constructionClassIndex = dataAllProperties?.construction?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.construction
      );
      if (constructionClassIndex > -1) {
        setValue('construction', {
          id: dataAllProperties?.construction[constructionClassIndex]?.id,
          name: dataAllProperties?.construction[constructionClassIndex]?.name,
        });
      } else {
        setValue('construction', undefined);
      }

      const foundationClassIndex = dataAllProperties?.foundation?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.foundation
      );
      if (foundationClassIndex > -1) {
        setValue('foundation', {
          id: dataAllProperties?.foundation[foundationClassIndex]?.id,
          name: dataAllProperties?.foundation[foundationClassIndex]?.name,
        });
      } else {
        setValue('foundation', undefined);
      }

      const basementClassIndex = dataAllProperties?.basement?.findIndex(
        ({ id }: { id: number }) => id === dataGetAddListingData?.information.basement
      );
      if (basementClassIndex > -1) {
        setValue('basement', {
          id: dataAllProperties?.basement[basementClassIndex]?.id,
          name: dataAllProperties?.basement[basementClassIndex]?.name,
        });
      } else {
        setValue('basement', undefined);
      }

      setValue('marketingDesc', dataGetAddListingData?.marketing?.marketingDescription || '');
      setValue('price', dataGetAddListingData?.marketing?.askingPrice || '');
      setValue('minPrice', dataGetAddListingData?.marketing?.unpricedMin || '');
      setValue('maxPrice', dataGetAddListingData?.marketing?.unpricedMax || '');
      setValue('dueDeligence', dataGetAddListingData?.marketing?.dueDeligence || '');
      setValue('inspectionDate', dataGetAddListingData?.marketing?.inspectionPeriodByDay || '');
      setValue('financialAccepted', dataGetAddListingData?.marketing?.financialAccepted || '');
      if (dataGetAddListingData?.marketing?.brochure !== undefined) {
        setFileFromBack(
          dataGetAddListingData?.marketing?.brochure?.substring(
            +dataGetAddListingData?.marketing?.brochure?.indexOf('.') + 1
          )
        );
      }
      setClosingResDate(dataGetAddListingData?.marketing?.closingDate || '');
      setChecked(dataGetAddListingData?.marketing?.unpriced || '');

      setValue('investmentDesc', dataGetAddListingData?.investment?.description || '');
      setValue('afterRepairValue', dataGetAddListingData?.investment?.afterRepairValue || '');
      setValue('basementCondition', dataGetAddListingData?.investment?.basementCondition || '');
      setValue('constructionCondition', dataGetAddListingData?.investment?.constructionCondition || '');
      setValue('coolingCondition', dataGetAddListingData?.investment?.coolingCondition || '');
      setValue('estimatedProfit', dataGetAddListingData?.investment?.estimatedProfit || '');
      setValue('estimatedRepairsCost', dataGetAddListingData?.investment?.estimatedRepairsCost || '');
      setValue('estimatedTimeForRepairs', dataGetAddListingData?.investment?.estimatedTimeForRepairs || '');
      setValue('foundationCondition', dataGetAddListingData?.investment?.foundationCondition || '');
      setValue('heatingCondition', dataGetAddListingData?.investment?.heatingCondition || '');
      setValue('rentalIncomePotentialMax', dataGetAddListingData?.investment?.rentalIncomePotentialMax || '');
      setValue('rentalIncomePotentialMin', dataGetAddListingData?.investment?.rentalIncomePotentialMin || '');
      setValue('roofCondition', dataGetAddListingData?.investment?.roofCondition || '');
      setValue('investmentType', dataGetAddListingData?.investment?.types || '');
      setValue('noi', dataGetAddListingData?.investment?.noi || '');
      setValue('capRate', dataGetAddListingData?.investment?.capRate || '');
      setValue('income', dataGetAddListingData?.investment?.income || '');
      setValue('expenses', dataGetAddListingData?.investment?.expenses || '');
      setValue('noiProForma', dataGetAddListingData?.investment?.proFormaNOI || '');
      setValue('capRateProForma', dataGetAddListingData?.investment?.proFormaCapRate || '');
      setValue('incomeProForma', dataGetAddListingData?.investment?.proFormaIncome || '');
      setValue('expensesProForma', dataGetAddListingData?.investment?.proFormaExpenses || '');

      setValue('videoUrl', dataGetAddListingData?.gallery?.videoUrl || '');

      setValue('desc', dataGetAddListingData?.aggreement?.confidentialAggrementText || '');
      setRadioButtonValue(dataGetAddListingData?.aggreement?.visibility);
      if (dataGetAddListingData.aggreement?.documents !== undefined) {
        setFileFromBackDocs(
          dataGetAddListingData.aggreement?.documents?.map((item: any) => ({
            originalName: item,
            name: item?.substring(+item?.indexOf('.') + 1),
          }))
        );
      }
      if (dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.length > 0) {
        setValue('desc', '');
        setShowTextField(false);
      }

      if (dataGetAddListingData?.aggreement?.confidentialAggrementDocument !== undefined) {
        setFileFromBackAgreement(
          dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.substring(
            +dataGetAddListingData?.aggreement?.confidentialAggrementDocument?.indexOf('.') + 1
          )
        );
      }

      setValue('name', dataGetAddListingData?.personalDetails?.name || '');
      setValue('lastName', dataGetAddListingData?.personalDetails?.lastName || '');
      setValue('email', dataGetAddListingData?.personalDetails?.email || '');
      const value = dataGetAddListingData?.personalDetails?.phoneNumber;

      if (value !== undefined) {
        const cleanedValue = value.substring(1).replace(/[^\d]/g, ''); // Remove non-digit characters

        let formattedString = '';
        if (cleanedValue.length <= 3) {
          formattedString = cleanedValue;
        } else if (cleanedValue.length <= 6) {
          formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3)}`;
        } else {
          formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
        }

        setPhoneNumberValue(formattedString);
      }
    }

    if ((dataGetAddListingError as any)?.status === 404) {
      sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
      if (window !== undefined) window.location.reload();
    }
  }, [dataGetAddListingData, isLoadingGetAddListingData, isLoadingAllProperties]);

  const submit = () => {
    publishMutate({
      url:
        role === 'residential'
          ? `https://test.urexc.com/api/Resident/publish/${sessionId}`
          : `https://test.urexc.com/api/Commercial/publish/${sessionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  };

  const onSubmit = handleSubmit(submit);

  const handleChangeSwitch = (value: string) => {
    setSwitchValue(value);

    if (value === 'AC') {
      setLotSizeValue(`${+lotSizeValue / 43560}`);
      setValue('lotSize', `${+lotSizeValue / 43560}`);
    }

    if (value === 'SF') {
      setLotSizeValue(`${+lotSizeValue * 43560}`);
      setValue('lotSize', `${+lotSizeValue * 43560}`);
    }
  };

  useEffect(() => {
      if(isSuccessPublish) {
        sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
        navigate("/")
      }
  }, [isSuccessPublish]);

  return {
    handleChangeSwitch,
    isMobile,
    register,
    handleClick,
    isLoadingGetAddListingData,
    dataGetAddListingData,
    openAddress,
    openInfo,
    openDetail,
    setOpenDetail,
    setOpenAddress,
    setOpenInfo,
    control,
    role,
    switchValue,
    getValues,
    dataAllProperties,
    openDesc,
    setOpenDesc,
    openPrice,
    setOpenPrice,
    openBrochure,
    setOpenBrochure,
    checked,
    closingResDate,
    isMobilePhone,
    fileFromBack,
    openDescInvest,
    setOpenDescInvest,
    openCondition,
    setOpenCondition,
    openPhoto,
    setOpenPhoto,
    openVideo,
    setOpenVideo,
    openConfigDocs,
    setOpenConfigDocs,
    openAgreement,
    setOpenAgreement,
    radioButtonValue,
    fileFromBackDocs,
    fileFromBackAgreement,
    openDetails,
    setOpenDetails,
    phoneNumberValue,
    showTextField,
    onSubmit,
  };
};

export default usePreview;

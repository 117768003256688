/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC, PropsWithChildren } from 'react';
import { HeaderProps } from 'react-table';
import { Contact } from '../../../core/_models';
import { useListView } from '../../../core/ListViewProvider';

type Props = {
  tableProps: PropsWithChildren<HeaderProps<Contact>>;
};

const ContactSelectionHeader: FC<Props> = ({ tableProps }) => {
  const { isAllSelected, onSelectAll } = useListView();

  return (
    <th {...tableProps.column.getHeaderProps()} className="w-10px pe-2">
      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
        <input className="form-check-input" type="checkbox" checked={isAllSelected} onChange={onSelectAll} />
      </div>
    </th>
  );
};

export { ContactSelectionHeader };

import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
  user_name: string;
  user_email: string;
  user_role: string;
}

const useUser = () => {
  const [user, setUser] = useState<JwtPayload>({ user_email: '', user_name: '', user_role: '' });
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      const parseUser = jwtDecode(token) as JwtPayload;
      setUser(parseUser);
    }
  }, [token]);

  return user;
};

export { useUser };

import React from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUpload from 'app/components/Icons/CloudUpload';
import { Box, Button, Typography } from '@mui/material';
import { white } from 'app/components/ThemeProvider/colors';
import FileIcon from 'app/components/Icons/FileIcon';
import DeleteIcon from 'app/components/Icons/DeleteIcon';
import FieldError from 'app/components/FieldError';

interface Props {
  file?: any;
  onDrop?: any;
  fileFromBack?: any;
  isMulti?: boolean;
  deleteItem?: (itemIndex: string, fromBack: boolean, name?: string) => void;
  maxSize?: number;
  extension?: string;
  error?: any;
  name?: any;
  control?: any;
  value?: string;
}

const CustomFileUpload = (props: Props) => {
  const { file, onDrop, fileFromBack, isMulti, deleteItem, maxSize, extension, error, name } = props;
  const { getRootProps, getInputProps } = useDropzone({
    multiple: isMulti,
    onDrop,
    accept:
      extension === 'pdf'
        ? { 'application/pdf': ['.pdf'] }
        : extension === 'img'
          ? { 'image/*': ['.jpeg', '.png', '.jpg'] }
          : {},
    maxSize: maxSize * 1000000,
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '30px',
          width: '100%',
          '@media screen and (max-width: 500px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            width: '100%',
            height: '293px',
            border: '1px dotted rgba(0, 0, 0, 0.25)',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '24px',

            '@media screen and (max-width: 500px)': {
              width: '100%',
            },
          }}
        >
          <input {...getInputProps()} {...name} />

          <CloudUpload />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              alignItems: 'center',
            }}
          >
            <Box sx={{ textAlign: 'center', display: 'flex', gap: '12px', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '13px', color: 'black' }}>Select a file or drag and drop here</Typography>
              <Typography sx={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.40)' }}>
                File size no more than {maxSize} Mb
              </Typography>
            </Box>
          </Box>
          <Button
            type="button"
            sx={{
              color: '#496780',
              border: '1px solid #496780',
              background: white,
              fontSize: '10px',
              textTransform: 'uppercase',
              borderRadius: '5px',
              padding: '12px 16px',
              lineHeight: 1,

              '&:hover': {
                backgroundColor: white,
              },
            }}
          >
            Select file
          </Button>
        </Box>
        {!isMulti ? (
          <>
            {((file !== null && file?.length > 0) || (fileFromBack !== undefined && fileFromBack !== '')) && (
              <Box
                sx={{
                  border: '1px dashed grey',
                  height: '40px',
                  paddingLeft: '16px',
                  borderRadius: '10px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',

                  '@media screen and (max-width: 500px)': {
                    width: '100%',
                  },
                }}
              >
                <FileIcon sx={{ marginRight: '16px' }} />
                {fileFromBack
                  ? fileFromBack?.length > 50
                    ? `${fileFromBack.substring(0, 50)} ...`
                    : fileFromBack
                  : file !== null && file.length > 0
                    ? file[0]?.name?.length > 50
                      ? `${file[0]?.name.substring(0, 50)} ...`
                      : file[0]?.name
                    : null}
              </Box>
            )}
          </>
        ) : (
          <>
            {(file !== null || (fileFromBack !== undefined && fileFromBack !== '')) && (
              <Box
                sx={{
                  height: '293px',
                  paddingLeft: '16px',
                  marginBottom: '10px',
                  width: '50%',
                  overflowY: 'auto',
                }}
              >
                {fileFromBack?.map((item: any, index: string) => (
                  <Box
                    key={item.originalName}
                    sx={{
                      border: '1px dashed grey',
                      padding: '16px',
                      borderRadius: '10px',
                      marginBottom: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <FileIcon sx={{ marginRight: '16px' }} />
                      {item?.name?.length > 50 ? `${item?.name.substring(0, 50)} ...` : item?.name}
                    </Box>
                    <DeleteIcon
                      onClick={() => deleteItem(index, true, item.originalName)}
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                    />
                  </Box>
                ))}
                {file !== null &&
                  file.map((item: any, index: string) => (
                    <Box
                      key={item.name}
                      sx={{
                        border: '1px dashed grey',
                        padding: '16px',
                        borderRadius: '10px',
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <FileIcon sx={{ marginRight: '16px' }} />
                        {item.name?.length > 50 ? `${item.name.substring(0, 50)} ...` : item.name}
                      </Box>
                      <DeleteIcon onClick={() => deleteItem(index, false)} sx={{ '&:hover': { cursor: 'pointer' } }} />
                    </Box>
                  ))}
              </Box>
            )}
          </>
        )}
      </Box>

      <FieldError error={error === true ? { message: 'File too large' } : error} />
    </Box>
  );
};

export default CustomFileUpload;

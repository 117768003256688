import React from 'react';
import { Box, Typography } from '@mui/material';
import { infoCard, propertyCard } from './AuthPropertyCard.sx';
import { blue, blue4, white } from 'app/components/ThemeProvider/colors';

const AuthPropertyCard = () => {
  const propertyData = [
    {
      id: 1,
      img: '/images/propertyImg.png',
      amount: '$1,100,0000',
      desc: 'Fully Furnished Smart Studio Apartment',
      type: 'Industrial',
      status: 'Cape Rate',
    },
    {
      id: 2,
      img: '/images/propertyImg.png',
      amount: '$1,100,0000',
      desc: 'Fully Furnished Smart Studio Apartment',
      type: 'Industrial',
      status: 'Cape Rate',
    },
    {
      id: 3,
      img: '/images/propertyImg.png',
      amount: '$1,100,0000',
      desc: 'Fully Furnished Smart Studio Apartment',
      type: 'Industrial',
      status: 'Cape Rate',
    },
    {
      id: 4,
      img: '/images/propertyImg.png',
      amount: '$1,100,0000',
      desc: 'Fully Furnished Smart Studio Apartment',
      type: 'Industrial',
      status: 'Cape Rate',
    },
  ];

  return (
    <Box sx={propertyCard}>
      {/*<Box sx={{ columnCount: 2, margin: '0 auto', columnGap: '35px' }}>*/}
      {propertyData.map((item: any) => (
        <Box sx={infoCard} key={item.id}>
          <img src={item.img} alt="property" width="263" height="195" />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Box>
              <Typography sx={{ color: blue4, fontSize: '24px', fontWeight: 600 }}>{item.amount}</Typography>
              <Typography sx={{ fontWeight: 500, color: blue }}>{item.desc}</Typography>
            </Box>
            <Typography sx={{ fontSize: '10px', color: blue }}>{item.type}</Typography>
            <Typography sx={{ fontSize: '12px', color: blue }}>{item.status}</Typography>
          </Box>
        </Box>
      ))}
      {/*</Box>*/}
    </Box>
  );
};

export default AuthPropertyCard;

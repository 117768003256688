/* eslint-disable curly */
import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { emailSchema } from './_schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendEmail } from './_requests';
import { useUser } from 'app/hooks/useUser';

const useEmail = (tools: { [key: string]: any }) => {
  const user = useUser();
  const queryClient = useQueryClient();
  const [file, setFile] = useState(null);
  const [fileFromBack, setFileFromBack] = useState();
  const [fileError, setfileError] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
    context: { file, fileBack: fileFromBack },
    defaultValues: { senderEmail: user.user_email },
  });

  const onDropFile = useCallback((acceptedFiles: any, fileRejection: any) => {
    if (fileRejection[0]?.errors[0]?.code === 'file-too-large') {
      setfileError(true);
    } else {
      setfileError(false);
      setFileFromBack(undefined);
      setFile(acceptedFiles);
      setValue('file', acceptedFiles[0]?.name);
    }
    trigger('file');
  }, []);

  const sendEmailMutation = useMutation((data: any) => sendEmail(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_LIST}-SEND-EMAIL`]);
      setValue('message', '');
      setValue('contactsId', undefined);
      setValue('propertyId', undefined);
      setValue('file', undefined);
      setFile(null);
      setFileFromBack(undefined);
      setfileError(false);
      reset();
      tools.onOpenSnackbar(true);
      tools.onCloseEmailDrawer(false);
    },
    onError: () => {
      tools.onOpenSnackbar(true);
    },
  });

  const onSubmit = handleSubmit((data: any) => {
    const formData = new FormData();
    formData.append('Message', data?.message);
    formData.append('PropertyId', data?.propertyId?.id);
    formData.append('Title', data?.title);
    formData.append('SenderEmail', data?.senderEmail);
    if (data.isSendFile) formData.append('File', file);
    for (const [key, value] of Object.entries(data.contactsId)) formData.append(`ContactsId[${key}]`, String(value));
    sendEmailMutation.mutate(formData);
  });

  return {
    file,
    fileFromBack,
    fileError,
    onDropFile,
    control,
    register,
    getValues,
    errors,
    reset,
    onSubmit,
    sendEmailMutation,
  };
};

export { useEmail };

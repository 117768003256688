/* eslint-disable react/react-in-jsx-scope */
import { QueryResponseProvider } from './core/QueryResponseProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { MyPropertyListQueryResponseProvider } from './core/MyPropertyListQueryResponse';
import { ListViewProvider, useListView } from './core/ListViewProvider';
import { MyPropertyHeader } from './components/header/Header';
import { MyPropertyTable } from './components/table/MyPropertyTable';
import { Details } from './components/details/Details';
import { MyPropertyList } from './components/myPropertyList/MyPropertyList';

const MyPropertyPage = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <MyPropertyHeader />
      <MyPropertyList />
      <MyPropertyTable />
      {itemIdForUpdate !== undefined && <Details />}
    </>
  );
};

const MyProperty = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <MyPropertyListQueryResponseProvider>
          <ListViewProvider>
            <MyPropertyPage />
          </ListViewProvider>
        </MyPropertyListQueryResponseProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export { MyProperty };

/* eslint-disable curly */
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useAddListingDraft,
  useDeleteFileFromBack,
  useGetAddListingData,
  useAddListeningContinue,
} from 'app/utils/services/addListingService';
// import { useGetAllProperties } from 'app/utils/services/propertyService';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'app/pages/AddListing/Gallery/schema';

const useGallery = () => {
  const location = useLocation();
  const role = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const sessionId = sessionStorage.getItem(role === 'commercial' ? 'commercialId' : 'residentialId');

  const isMobilePhone = useMediaQuery('(max-width: 500px)');
  const isMobile = useMediaQuery('(max-width: 992px)');

  const [openPhoto, setOpenPhoto] = useState(true);
  const [openVideo, setOpenVideo] = useState(true);

  const [file, setFile] = useState(null);
  const [fileFromBack, setFileFromBack] = useState();
  const [galleryError, setGalleryError] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: any, fileRejection: any) => {
      if (fileRejection.length > 0) {
        fileRejection.forEach((fileGallery: any) => {
          fileGallery.errors.forEach((err: any) => {
            if (err.code === 'file-too-large') {
              setGalleryError(true);
            }
          });
        });
      } else {
        if (file === null) {
          setFile(acceptedFiles);
          setGalleryError(false);
        } else {
          setFile([...file.concat(acceptedFiles)]);
        }
      }
    },
    [file]
  );

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { isLoadingGetAddListingData, dataGetAddListingData, refetchGetAllListingData, dataGetAddListingError } =
    useGetAddListingData(role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident', sessionId, {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    });
  // const { isLoadingAllProperties, dataAllProperties } = useGetAllProperties(
  //   role === 'residential' ? 2 : role === 'commercial' && 1
  // );

  const { isLoadingDraft, isSuccessDraft, dataDraft, draftMutation } = useAddListingDraft();
  const { isLoadingDelete, isSuccessDelete, deleteMutation } = useDeleteFileFromBack();
  const { isLoadingContinue, isSuccessContinue, dataContinue, continueMutation } = useAddListeningContinue();

  useEffect(() => {
    if (!isMobile && !openPhoto) {
      setOpenPhoto(true);
    }
    if (!isMobile && !openVideo) {
      setOpenVideo(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isSuccessDelete) {
      refetchGetAllListingData();
    }
  }, [isSuccessDelete]);

  useEffect(() => {
    if (sessionId !== null && dataGetAddListingData !== undefined) {
      setValue('videoUrl', dataGetAddListingData?.gallery.videoUrl);
      setFileFromBack(
        dataGetAddListingData.gallery?.photos?.map((item: any) => ({
          originalName: item,
          name: item?.substring(+item?.indexOf('.') + 1),
        }))
      );
    }

    if ((dataGetAddListingError as any)?.status === 404) {
      sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
      if (window !== undefined) window.location.reload();
    }
  }, [dataGetAddListingData]);

  const handleClick = (set: any, value: boolean) => {
    if (isMobile) {
      set(!value);
    }
  };

  const handleDelete = (itemIndex: string, fromBack: boolean, name?: string) => {
    if (fromBack) {
      deleteMutation({
        id: sessionId,
        path: name,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      setFile(file.filter((item: any, index: string) => index !== itemIndex));
    }
  };

  useEffect(() => {
    if (
      (isSuccessDraft && dataDraft !== undefined && !dataDraft.message) ||
      (isSuccessContinue && dataContinue !== undefined && !dataContinue.message)
    ) {
      sessionStorage.setItem(
        role === 'commercial' ? 'commercialId' : 'residentialId',
        dataDraft ? String(dataDraft) : String(dataContinue)
      );
      navigate(`/add-listing/${role}/confidentiality`);
    }
  }, [dataDraft, dataContinue]);

  const submitForm = (data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isDraft = element?.dataset?.isdraft === 'true';
    const residentialFormData = new FormData();
    if (!file || file?.length === 0) {
      residentialFormData.append('Gallery.Photos', null);
    } else {
      for (const row of file) {
        residentialFormData.append('Gallery.Photos', row);
      }
    }
    residentialFormData.append('Gallery.VideoUrl', data.videoUrl);
    residentialFormData.append('Id', sessionId === null ? '' : sessionId);

    if (isDraft) {
      draftMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      continueMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  const onSubmit = handleSubmit((data: any, event: any) => submitForm(data, event));

  return {
    isLoadingContinue,
    isLoadingDraft,
    isMobilePhone,
    isMobile,
    openPhoto,
    setOpenPhoto,
    openVideo,
    setOpenVideo,
    file,
    fileFromBack,
    onDrop,
    handleClick,
    isLoadingGetAddListingData,
    onSubmit,
    register,
    getValues,
    handleDelete,
    errors,
    galleryError,
  };
};

export default useGallery;

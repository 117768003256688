import { useEffect, useState } from 'react';

const useCountdownTimer = () => {
  const [seconds, setSeconds] = useState(180);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return { remainingSeconds, seconds, setSeconds, minutes };
};

export default useCountdownTimer;

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const UrexLogoLight = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 104 108"
      sx={{
        height: '108px !important',
        width: '104px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M22.5127 44.3874C22.2057 45.5002 22.0522 46.8817 22.0522 48.5317V61.31C22.0522 62.8066 22.2057 64.2072 22.5127 65.5119H18.8288C18.944 65.0898 19.0207 64.6869 19.0591 64.3032C19.0974 63.881 19.155 63.4589 19.2318 63.0368C17.505 64.8788 15.5095 65.7997 13.2455 65.7997C11.0199 65.7997 9.19714 64.9939 7.77732 63.3822C6.35751 61.7321 5.64761 59.4297 5.64761 56.475V48.5317C5.64761 47.1887 5.53249 45.8072 5.30225 44.3874H8.92852C8.62154 45.7688 8.46804 47.1503 8.46804 48.5317V56.475C8.46804 58.6239 8.8134 60.2164 9.50412 61.2525C10.5018 62.8642 11.9216 63.67 13.7636 63.67C15.6055 63.67 17.0637 62.9409 18.1381 61.4827C18.9056 60.3315 19.2893 58.6623 19.2893 56.475V48.5317C19.2893 47.1503 19.1358 45.7688 18.8288 44.3874H22.5127Z"
        fill="white"
      />
      <path
        d="M30.563 46.1142C30.4479 47.3805 30.3903 48.1864 30.3903 48.5317V55.2087H32.0595C33.441 55.2087 34.5346 54.8058 35.3404 53.9999C36.1847 53.1557 36.6068 52.0621 36.6068 50.719C36.6068 49.3759 36.223 48.2823 35.4556 47.4381C34.4962 46.5555 33.3642 46.1142 32.0595 46.1142H30.563ZM33.0956 56.9355L32.0595 56.993H30.3903V61.31C30.3903 62.8066 30.5438 64.2072 30.8508 65.5119H27.2245C27.4547 64.1305 27.5699 62.7298 27.5699 61.31V48.5317C27.5699 47.1887 27.4547 45.8072 27.2245 44.3874H32.0595C34.3619 44.3874 36.1847 44.9822 37.5277 46.1718C38.9475 47.3613 39.6574 48.8771 39.6574 50.719C39.6574 52.5225 38.9475 54.0191 37.5277 55.2087C37.2591 55.4005 36.9905 55.5924 36.7219 55.7843C36.4533 55.9761 36.1655 56.1296 35.8585 56.2448L39.8301 62.231C40.636 63.4206 41.5186 64.5142 42.4779 65.5119H38.276C37.5469 63.8235 36.8754 62.5764 36.2614 61.7705L33.0956 56.9355Z"
        fill="white"
      />
      <path
        d="M48.0546 61.31C48.0546 61.5786 48.1121 62.4037 48.2272 63.7851H51.9686C53.5036 63.7851 54.9042 63.6508 56.1705 63.3822V65.5119H44.8888C45.119 64.1305 45.2341 62.7298 45.2341 61.31V48.5317C45.2341 47.1887 45.119 45.8072 44.8888 44.3874H54.5013V46.5171C52.8896 46.2485 51.489 46.1142 50.2994 46.1142H48.2272C48.1889 46.5363 48.1505 46.9584 48.1121 47.3805C48.0738 47.8026 48.0546 48.1864 48.0546 48.5317V54.0575H50.2994C51.2587 54.0575 52.6594 53.904 54.5013 53.597V56.1872C53.3117 55.9186 51.9111 55.7843 50.2994 55.7843H48.0546V61.31Z"
        fill="white"
      />
      <path
        d="M58.1395 65.5119C58.9837 64.7444 59.9046 63.67 60.9024 62.2886L65.9101 55.2662L60.7872 47.6683C60.0581 46.6322 59.1756 45.5386 58.1395 44.3874H62.3989C62.7826 45.577 63.4158 46.8241 64.2984 48.1288L67.5793 52.9063L71.0329 48.0712C71.8004 46.9968 72.4911 45.7688 73.1051 44.3874H76.386C75.3499 45.3851 74.4289 46.4404 73.6231 47.5532L68.6154 54.5755L73.7382 62.231C74.2755 63.0752 75.158 64.1688 76.386 65.5119H72.1265C71.7044 64.4375 71.0713 63.1903 70.2271 61.7705L66.9462 56.9355L63.4925 61.8281C62.6483 62.9409 61.9384 64.1688 61.3628 65.5119H58.1395Z"
        fill="white"
      />
      <path
        d="M95.1034 49.9707C93.4534 47.2078 91.4388 45.8264 89.0596 45.8264C86.7189 45.8264 84.8578 46.7666 83.4763 48.6468C82.0949 50.5271 81.4042 52.6377 81.4042 54.9784C81.4042 57.3192 82.0565 59.3722 83.3612 61.1374C84.8577 63.056 86.6997 64.0154 88.8869 64.0154C91.7266 64.0154 94.1441 62.5764 96.1395 59.6984H96.3122V62.6339C94.2017 64.7444 91.7074 65.7997 88.8294 65.7997C85.9514 65.7997 83.4763 64.7636 81.4042 62.6915C79.3704 60.5809 78.3535 58.0099 78.3535 54.9784C78.3535 51.9469 79.3704 49.3759 81.4042 47.2654C83.4379 45.1549 85.8171 44.0996 88.5416 44.0996C91.2661 44.0996 93.4917 44.8287 95.2185 46.2869V49.9707H95.1034Z"
        fill="white"
      />
      <path
        d="M92.9289 30.2568C79.8158 7.54412 50.7732 -0.237815 28.0605 12.8753C22.6514 15.9983 18.0941 20.0201 14.4633 24.6411C10.4619 29.7339 7.58376 35.5559 5.93535 41.707H0C1.76859 34.2607 5.13068 27.2025 9.93729 21.085C14.0137 15.8967 19.1274 11.3864 25.1825 7.89051C50.6482 -6.81213 83.2111 1.91307 97.9137 27.3788C100.566 31.9727 102.456 36.7976 103.623 41.707H97.6843C96.6314 37.7871 95.0547 33.9388 92.9289 30.2568Z"
        fill="url(#paint0_linear_130_16696)"
      />
      <path
        d="M88.6689 41.707C87.8543 39.2726 86.785 36.8832 85.4517 34.5738C74.7227 15.9907 50.9606 9.62365 32.3775 20.3526C23.8136 25.297 17.8442 33.0092 14.9467 41.707H21.0733C23.732 35.0479 28.564 29.2008 35.2555 25.3374C51.0856 16.198 71.3273 21.6217 80.4668 37.4518C81.2682 38.8397 81.9576 40.2616 82.5378 41.707H88.6689Z"
        fill="url(#paint1_linear_130_16696)"
      />
      <path
        d="M81.9764 67.609H88.1969C85.1517 75.7664 79.3601 82.9543 71.2305 87.6479C52.6474 98.3769 28.8853 92.0098 18.1563 73.4267C17.0665 71.5391 16.1531 69.598 15.4109 67.6225H6.30163C7.34088 71.0832 8.79416 74.479 10.6791 77.7437C16.9817 88.6602 26.9582 96.1248 38.1509 99.4823C50.2497 103.112 63.7499 101.936 75.5475 95.1252C86.4771 88.815 93.9492 78.8161 97.2993 67.609H103.278C99.7802 80.8396 91.2136 92.7268 78.4255 100.11C65.1983 107.747 50.0514 109.062 36.4971 104.996C23.9547 101.233 12.7594 92.859 5.69424 80.6217C3.27906 76.4385 1.49617 72.0641 0.315791 67.609H0.322159V66.1835H41.1801V67.6191C46.5624 71.8148 54.1643 72.5769 60.438 68.9548C61.1445 68.5469 61.8113 68.0966 62.437 67.609H70.3828C68.5497 70.1144 66.1772 72.2877 63.316 73.9396C53.0398 79.8726 40.0912 76.9836 33.2398 67.6225H21.6377C22.0859 68.6118 22.5867 69.5884 23.1412 70.5488C32.2807 86.3788 52.5224 91.8026 68.3525 82.6631C74.5996 79.0563 79.2261 73.7204 81.9764 67.609Z"
        fill="url(#paint2_linear_130_16696)"
      />
      <path
        d="M71.2718 41.707C64.7105 31.3006 51.0396 27.8558 40.292 34.0609C36.9408 35.9957 34.26 38.6458 32.3311 41.707H39.6712C40.6963 40.6966 41.8654 39.7989 43.17 39.0457C50.056 35.0701 58.542 36.3762 63.937 41.707H71.2718Z"
        fill="url(#paint3_linear_130_16696)"
      />
      <path d="M63.9511 43.1483L103.623 43.1483V41.7093H63.9511V43.1483Z" fill="url(#paint4_linear_130_16696)" />
      <defs>
        <linearGradient
          id="paint0_linear_130_16696"
          x1="51.8113"
          y1="0.748413"
          x2="47.1208"
          y2="134.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_130_16696"
          x1="51.8113"
          y1="0.748413"
          x2="47.1208"
          y2="134.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_130_16696"
          x1="51.8113"
          y1="0.748413"
          x2="47.1208"
          y2="134.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_130_16696"
          x1="51.8113"
          y1="0.748413"
          x2="47.1208"
          y2="134.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_130_16696"
          x1="51.8113"
          y1="0.748413"
          x2="47.1208"
          y2="134.732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21364B" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default UrexLogoLight;

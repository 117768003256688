import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import logger from 'app/utils/logger';
import { useOtpService, useResendOtpService } from 'app/utils/services/authService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../hook/AuthProvider';

interface JwtPayload {
  user_email: string;
}

interface Props {
  email: string;
  forgotPass: boolean;
}

const useConfirmPage = (props: Props) => {
  const { email, forgotPass } = props;
  const [otp, setOtp] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState({ message: '', hasError: false });
  const [emailFromToken, setEmailFromToken] = useState('');
  const navigate = useNavigate();

  const { state, updateState } = useAuth();
  const token = localStorage.getItem('token') || state.token;
  const { handleSubmit } = useForm();
  const { isSuccessOtp, dataOtp, otpMutation } = useOtpService();
  const { isSuccessResendOtp, dataResendOtp, resendOtpMutation } = useResendOtpService();

  useEffect(() => {
    if (email === undefined) {
      if (!token) {
        navigate('/auth/login');
      } else {
        const userEmail = jwtDecode(token) as JwtPayload;
        setEmailFromToken(userEmail.user_email);
      }
    }
  }, []);

  useEffect(() => {
    if (dataOtp?.token !== undefined) {
      localStorage.setItem('token', dataOtp?.token);
      localStorage.setItem('refreshToken', dataOtp?.refreshToken);
      updateState({ ...state, isAuth: true });
      navigate('/');
    }

    if (dataOtp?.message !== undefined) {
      setOpenSnackbar(true);
    }

    if (typeof dataOtp === 'string') {
      localStorage.setItem('change-pass-token', dataOtp);
      navigate('/auth/reset-password?new_password=set_new_password');
    }
  }, [dataOtp]);

  useEffect(() => {
    if (dataResendOtp?.message !== undefined) {
      setOpenSnackbar(true);
    }
  }, [dataResendOtp]);

  const handleGetOtp = (newOtp: string) => {
    if (newOtp.length === 6) {
      setError({ ...error, message: '' });
    }

    if (error.hasError && newOtp.length < 6) {
      setError({ ...error, message: 'OTP is required' });
    }
    setOtp(newOtp);
  };

  const resendOtp = () => {
    if (forgotPass) {
      resendOtpMutation({ data: { email }, url: '/email/forgot' });
    } else {
      resendOtpMutation({
        data: null,
        url: '/email/confirm/send',
        headers: { Authorization: `Bearer ${token}`, device: 'test' },
      });
    }
  };

  const onSubmit = (data: any) => {
    if (otp.length < 6) {
      setError({ message: 'OTP is required', hasError: true });
    }

    if (otp.length === 6 && !forgotPass) {
      otpMutation({
        data: { code: otp },
        url: '/email/confirm',
        headers: { Authorization: `Bearer ${token}`, device: 'test' },
      });
    }

    if (otp.length === 6 && forgotPass) {
      otpMutation({
        data: { email, token: otp },
        url: '/email/passwordtoken',
      });
    }
  };

  const onFormSubmit = handleSubmit(onSubmit);

  return {
    otp,
    onFormSubmit,
    error,
    openSnackbar,
    setOpenSnackbar,
    handleGetOtp,
    resendOtp,
    dataOtp,
    dataResendOtp,
    emailFromToken,
  };
};

export default useConfirmPage;

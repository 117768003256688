import { lazy, number, object, string } from 'yup';

const currentYear = new Date();

export const residentialSchema = (isResidental: boolean) => {
  return object({
    propertyName: string().required('This field is required').min(5),
    fullAddress: string(),
    street: string(),
    city: string(),
    state: string(),
    zipCode: string(),
    country: string(),
    county: string(),
    propertyType: object().required('This field is required'),
    usableSqFT: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
    occupancy: object(),
    totalSqFt: lazy((value) =>
      (value === '' ? string() : number().typeError('Must be a number')).required('This field is required')
    ),
    units: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
    bathroom: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
    bedroom: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
    yearBuilt: lazy((value) =>
      value === ''
        ? string().required('This field is required')
        : number()
            .typeError('Must be a number')
            .max(currentYear.getFullYear(), 'Year Built must be less than or equal to 2023')
            .required('This field is required')
    ),
    lotSize: lazy((value) =>
      value === ''
        ? string().required('This field is required')
        : number().typeError('Must be a number').required('This field is required')
    ),
    buildingClass: isResidental ? object() : object().required('This field is required'),
    parking: object(),
    stories: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
    style: object(),
    parkingSpaces: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
    heating: object(),
    zoning: string(),
    roofing: object(),
    yearRenovated: lazy((value) =>
      value === ''
        ? string()
        : number()
            .typeError('Must be a number')
            .max(currentYear.getFullYear(), 'Year Built must be less than or equal to 2023')
    ),
    cooling: object(),
    construction: object(),
    foundation: object(),
    basement: object(),
  });
};

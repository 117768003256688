import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPostCardCamping = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 19" {...props}>
    <path
      fill="currentColor"
      d="M16.418 3.883H1.582C.71 3.883 0 4.593 0 5.465v8.05c0 .872.71 1.582 1.582 1.582h14.836c.872 0 1.582-.71 1.582-1.582v-8.05c0-.872-.71-1.582-1.582-1.582m.527 9.632c0 .29-.236.527-.527.527H1.582a.53.53 0 0 1-.527-.527v-8.05c0-.29.236-.527.527-.527h14.836c.29 0 .527.237.527.527z"
    />
    <path
      fill="currentColor"
      d="M9 5.286a.527.527 0 0 0-.527.527v7.354a.527.527 0 1 0 1.054 0V5.813A.527.527 0 0 0 9 5.286M15.404 5.976h-3.722a.527.527 0 0 0-.528.528v3.722c0 .292.236.528.528.528h3.722a.527.527 0 0 0 .528-.528V6.504a.527.527 0 0 0-.528-.528M14.877 9.7h-2.668V7.031h2.668zM6.599 10.088H2.697a.527.527 0 0 0 0 1.055H6.6a.527.527 0 0 0 0-1.055M5.644 11.95H2.697a.527.527 0 1 0 0 1.054h2.947a.527.527 0 0 0 0-1.054"
    />
  </svg>
);
export default SvgPostCardCamping;

/* eslint-disable react/react-in-jsx-scope */
import { FC, useState, createContext, useContext } from 'react';
import { WithChildren } from 'app/constants/models';
import { QueryState, QueryRequestContextProps, initialQueryRequest } from './_models';

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest);

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState;
    setState(updatedState);
  };

  return <QueryRequestContext.Provider value={{ state, updateState }}>{children}</QueryRequestContext.Provider>;
};

const useQueryRequest = () => useContext(QueryRequestContext);
export { QueryRequestProvider, useQueryRequest };

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

interface Fill {
  fill: string;
}

const PreviewIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  const sxStyle = sx as Fill;

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: '20px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M9.99985 12.7905C11.5411 12.7905 12.7905 11.5411 12.7905 9.99985C12.7905 8.45863 11.5411 7.20923 9.99985 7.20923C8.45863 7.20923 7.20923 8.45863 7.20923 9.99985C7.20923 11.5411 8.45863 12.7905 9.99985 12.7905Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
      <path
        d="M19.0843 9.75305L16.5186 7.14055C14.8114 5.40548 12.4857 4.41827 10.0516 4.39543C7.61757 4.37258 5.27378 5.31595 3.53426 7.01868L0.937381 9.5843C0.849599 9.67219 0.800293 9.79133 0.800293 9.91555C0.800293 10.0398 0.849599 10.1589 0.937381 10.2468L3.50301 12.8593C5.20985 14.5951 7.53566 15.5829 9.96999 15.6057C12.4043 15.6286 14.7483 14.6847 16.4874 12.9812L19.0999 10.4156C19.1856 10.3256 19.2321 10.2053 19.2292 10.0811C19.2263 9.95684 19.1741 9.83886 19.0843 9.75305ZM9.96551 13.7281C9.22764 13.7237 8.50767 13.5005 7.89675 13.0867C7.28583 12.6728 6.81145 12.0871 6.53367 11.4035C6.2559 10.7199 6.18722 9.96922 6.33634 9.24657C6.48546 8.52391 6.84567 7.86177 7.37135 7.34397C7.89704 6.82617 8.56455 6.47601 9.28938 6.33782C10.0142 6.19964 10.7637 6.27966 11.443 6.56773C12.1224 6.85581 12.7009 7.33899 13.1054 7.95609C13.51 8.57319 13.7223 9.29646 13.7155 10.0343C13.7056 11.0206 13.3056 11.9629 12.6028 12.6551C11.9001 13.3473 10.9519 13.7331 9.96551 13.7281Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
      />
    </SvgIcon>
  );
};

export default PreviewIcon;

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  title?: string;
  className?: string;
};

const MyPropertyCustomCell: FC<Props> = ({ title, className }) => (
  <div className={clsx(className && className)}>
    <span>{title}</span>
  </div>
);

export { MyPropertyCustomCell };

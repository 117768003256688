/* eslint-disable padding-line-between-statements */
/* eslint-disable curly */
import * as Yup from 'yup';

export const contactSchema = Yup.object({
  groupId: Yup.object().required('This field is required!'),
  name: Yup.string().required('This field is required'),
  lastName: Yup.string(),
  email: Yup.string().email(),
  phoneNumber: Yup.string(),
  address: Yup.string(),
  companyName: Yup.string(),
  title: Yup.string(),
  note: Yup.string(),
  tags: Yup.array(),
});

export const smsSchema = Yup.object({
  contactsId: Yup.array().required('This field is required'),
  message: Yup.string().required('This field is required'),
  propertyId: Yup.object().required('This field is required'),
});

export const emailSchema = Yup.object({
  contactsId: Yup.array().required('This field is required'),
  message: Yup.string().required('This field is required'),
  propertyId: Yup.object().required('This field is required'),
  title: Yup.string().required('This filed is required'),
  file: Yup.mixed(),
  isSendFile: Yup.boolean(),
  senderEmail: Yup.string(),
});

export const moveToSchema = Yup.object({
  listId: Yup.object().required('This field is required'),
});

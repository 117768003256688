import React from 'react';
import { arrowIcon, authCard, contentBox, logoBox, pageDesc, pageHeader } from './AuthCard.sx';
import { Alert, Box, Snackbar, Typography } from '@mui/material';
import BackArrow from 'app/components/Icons/BackArrow';
import UrexLogo from 'app/components/Icons/UrexLogo';
import AuthPropertyCard from 'app/components/AuthPropertyCard';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: any;
  open: boolean;
  setOpen: any;
  data: any;
  header: string;
  desc: any;
}

const AuthCard = (props: Props) => {
  const { children, open, setOpen, data, header, desc } = props;
  const navigate = useNavigate();

  return (
    <Box sx={authCard}>
      <Box
        sx={{
          width: '422px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'auto',
          padding: '20px 0',
          '@media screen and (max-width: 886px)': {
            overflow: 'unset',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BackArrow sx={arrowIcon} onClick={() => navigate(-1)} />
          <Box sx={logoBox} onClick={() => navigate('/')}>
            <UrexLogo />
          </Box>
        </Box>
        <Box sx={contentBox}>
          <Typography sx={pageHeader}>{header}</Typography>
          <Typography sx={pageDesc}>{desc}</Typography>
          {children}
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={open}
          autoHideDuration={6000}
          onClose={() => setOpen(false)}
        >
          <Alert onClose={() => setOpen(false)} severity="error" sx={{ width: '100%' }}>
            {data?.message}
          </Alert>
        </Snackbar>
      </Box>
      <AuthPropertyCard />
    </Box>
  );
};

export default AuthCard;

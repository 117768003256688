import React from 'react';
import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { black, blue, gray10, white } from 'app/components/ThemeProvider/colors';
import UrexLogoLight from 'app/components/Icons/UrexLogoLight';
import LocationIcon from 'app/components/Icons/LocationIcon';
import PhoneIcon from 'app/components/Icons/PhoneIcon';
import { Link } from 'react-router-dom';
import MailIcon from 'app/components/Icons/MailIcon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LinkedinIcon from 'app/components/Icons/LinkedinIcon';
import FbIcon from 'app/components/Icons/FbIcon';
import InstagramIcon from 'app/components/Icons/InstagramIcon';

const Footer = () => {
  return (
    <>
      <Box sx={{ background: blue }}>
        <Box
          sx={{
            width: '90%',
            margin: '0 auto',
            pt: '60px',
            pb: '100px',
            display: 'flex',
            justifyContent: 'space-between',

            '@media screen and (max-width: 992px)': { flexDirection: 'column' },
          }}
        >
          <Box>
            <UrexLogoLight sx={{ width: '60px', height: '62px', mb: '60px' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <LocationIcon />
                <Box>
                  <Typography sx={{ color: white, fontWeight: 500, lineHeight: 1 }}>
                    2728 Hickory StreetSalt Lake City,
                  </Typography>
                  <Typography sx={{ color: white, fontWeight: 500 }}>UT 84104</Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <PhoneIcon />
                <Link to={'tel: +1 206-214-2298'} style={{ color: white, textDecoration: 'none', fontWeight: 500 }}>
                  +1 206-214-2298
                </Link>
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <MailIcon />
                <Link
                  to={'mailto: support@support.com'}
                  style={{ color: white, textDecoration: 'none', fontWeight: 500 }}
                >
                  support@support.com
                </Link>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column', mt: '62px' }}>
            <Typography sx={{ color: white, fontWeight: 500, fontSize: '20px' }}>Quick Links</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography sx={{ color: white, fontSize: '18px' }}>Features</Typography>
              <Typography sx={{ color: white, fontSize: '18px' }}>Who we serve</Typography>
              <Typography sx={{ color: white, fontSize: '18px' }}>Resources</Typography>
              <Typography sx={{ color: white, fontSize: '18px' }}>Add listing</Typography>
              <Typography sx={{ color: white, fontSize: '18px' }}>Dashboard</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: '62px' }}>
            <Box sx={{ mb: '24px' }}>
              <Typography sx={{ color: white, fontWeight: 500, fontSize: '20px' }}>Subscribe to our </Typography>
              <Typography sx={{ color: white, fontWeight: 500, fontSize: '20px' }}>Newsletter! </Typography>
            </Box>
            <OutlinedInput
              id={'email'}
              placeholder={'Email Address'}
              type={'text'}
              sx={{
                background: white,
                pr: '1px',
                border: 'none',
                '& fieldset': { border: 'none' },
                '& .MuiInputBase-input': {
                  '&::placeholder': {
                    color: '#888990',
                  },
                },
              }}
              endAdornment={
                <Button
                  sx={{ height: '46px', width: '46px !important', minWidth: '46px !important', borderRadius: '10px' }}
                >
                  <ArrowForwardIcon />
                </Button>
              }
            />
            <Typography sx={{ color: white, mt: '30px', mb: '30px' }}>Follow Us on</Typography>
            <Box sx={{ display: 'flex', gap: '30px' }}>
              <LinkedinIcon />
              <FbIcon />
              <InstagramIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: black }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            margin: '0 auto',
            pt: '13px',
            pb: '13px',

            '@media screen and (max-width: 768px)': { flexDirection: 'column', gap: '12px' },
            '@media screen and (min-width: 1300px)': { fontSize: '1274px' },
          }}
        >
          <Box>
            <Typography sx={{ color: gray10, '@media screen and (max-width: 768px)': { fontSize: '10px' } }}>
              © Urexc – All rights reserved
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '40px', '@media screen and (max-width: 768px)': { gap: '20px' } }}>
            <Typography sx={{ color: gray10, '@media screen and (max-width: 768px)': { fontSize: '10px' } }}>
              Terms and Conditions
            </Typography>
            <Typography sx={{ color: gray10, '@media screen and (max-width: 768px)': { fontSize: '10px' } }}>
              Privacy Policy
            </Typography>
            <Typography sx={{ color: gray10, '@media screen and (max-width: 768px)': { fontSize: '10px' } }}>
              Disclaimer
            </Typography>
            <Typography sx={{ color: gray10, '@media screen and (max-width: 768px)': { fontSize: '10px' } }}>
              v2.5
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;

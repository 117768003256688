import { CSSProperties } from 'react';
import { blue, blue6, gray6, white } from 'app/components/ThemeProvider/colors';
import { SxProps } from '@mui/material';

export const container = (flag: boolean) => {
  return {
    background: blue,
    width: '241px',
    height: '100vh',
    position: flag ? 'static' : 'fixed',
    display: 'flex',
    flexDirection: 'column',
    pl: '11px',
    pr: '35px',
    pt: '40px',

    '@media screen and (max-width: 992px)': {
      width: '80vw',
    },
  };
};

export const addListType: SxProps = {
  background: white,
  display: 'flex',
  gap: '10px',
  p: '10px 20px',
  borderRadius: '20px',
  mt: '40px',
  mb: '30px',
  alignItems: 'center',
};

const linkStyle: CSSProperties = {
  fontSize: '14px',
  textDecoration: 'none',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '10px 0 10px 11px',
};

export const style: Record<string, CSSProperties> = {
  link: {
    ...linkStyle,
    color: gray6,
    opacity: '0.65',
  },

  activeLink: {
    ...linkStyle,
    color: white,
    backgroundColor: blue6,
    borderRadius: '10px',
  },
};

/* eslint-disable react/react-in-jsx-scope */

import { Button, IconButton, InputAdornment, Typography } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { blue, blue2, blue3 } from 'app/components/ThemeProvider/colors';
import useLogin from './useLogin';
import CustomInput from 'app/components/CustomInput';
import AuthCard from 'app/components/AuthCard';

const Login = () => {
  const {
    register,
    onFormSubmit,
    errors,
    openSnackbar,
    setOpenSnackbar,
    dataLogin,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useLogin();

  return (
    <AuthCard
      open={openSnackbar}
      setOpen={setOpenSnackbar}
      data={dataLogin}
      header={'Login'}
      desc={'Please enter your email and your password'}
    >
      <form style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} onSubmit={onFormSubmit}>
        <CustomInput
          id={'login-email'}
          label={'Email'}
          type={'email'}
          error={errors.email}
          rest={{ ...register('email') }}
        />
        <CustomInput
          id={'login-pass'}
          label={'Password'}
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              </IconButton>
            </InputAdornment>
          }
          rest={{ ...register('password') }}
        />

        <Link
          to={'/auth/reset-password'}
          style={{ fontFamily: 'Poppins', color: blue, textDecoration: 'none', textAlign: 'right' }}
        >
          Forgot Password?
        </Link>
        <Button type={'submit'} sx={{ mt: '10px', mb: '30px' }}>
          Sign in
        </Button>
      </form>
      <Typography sx={{ textAlign: 'center', fontSize: '18px', color: blue2 }}>
        Don&apos;t you have an account?&nbsp;
        <Link to={'/auth/registration'} style={{ textDecoration: 'none', color: blue3 }}>
          Sign up
        </Link>
      </Typography>
    </AuthCard>
  );
};

export default Login;

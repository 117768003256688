/* eslint-disable react/react-in-jsx-scope */
import { Column } from 'react-table';
import { MyPropertySelectionCell } from './MyPropertySelectionCell';
import { MyPropertySelectionHeader } from './MyPropertySelectionHeader';
import { MyPropertyCustomHeader } from './MyPropertyCustomHeader';
import { MyPropertyCustomCell } from './MyPropertyCustomCell';
import { MyPropertyActionCell } from './MyPropertyActionCell';
import { MyProperty } from '../../../core/_models';

const contactsColumns: ReadonlyArray<Column<MyProperty>> = [
  {
    Header: (props) => <MyPropertySelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <MyPropertySelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <MyPropertyCustomHeader tableProps={props} title="Address" className="min-w-150px" />,
    id: 'Address',
    Cell: ({ ...props }) => <MyPropertyCustomCell title={props.data[props.row.index].address.fullAddress} />,
    width: 300,
  },
  {
    Header: (props) => <MyPropertyCustomHeader tableProps={props} title="City" className="min-w-150px text-center" />,
    id: 'City',
    Cell: ({ ...props }) => (
      <MyPropertyCustomCell title={props.data[props.row.index].address.city} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="ZIP Code" className="min-w-150px text-center" />
    ),
    id: 'PostalCode',
    Cell: ({ ...props }) => (
      <MyPropertyCustomCell title={props.data[props.row.index].address.postalCode} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="Owner Name" className="min-w-150px text-center" />
    ),
    id: 'OwnerName',
    Cell: ({ ...props }) => (
      <MyPropertyCustomCell title={props.data[props.row.index]?.ownerName} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="Property type" className="min-w-150px text-center" />
    ),
    id: 'TypeName',
    Cell: ({ ...props }) => (
      <MyPropertyCustomCell title={props.data[props.row.index]?.typeName} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="Contact" className="min-w-150px text-center" />
    ),
    id: 'MyContactName',
    Cell: ({ ...props }) => (
      <MyPropertyCustomCell title={props.data[props.row.index]?.myContactName} className="text-center" />
    ),
  },
  {
    Header: (props) => <MyPropertyCustomHeader tableProps={props} title="Title" className="min-w-150px text-center" />,
    id: 'Title',
    Cell: ({ ...props }) => <MyPropertyCustomCell title={props.data[props.row.index]?.title} className="text-center" />,
  },
  {
    Header: (props) => (
      <MyPropertyCustomHeader tableProps={props} title="Actions" className="min-w-150px text-center" />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <MyPropertyActionCell id={props.data[props.row.index].id} />,
  },
];

export { contactsColumns };

import React, { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuItem, SwipeableDrawer, Typography } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from 'app/components/Sidebar';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { black, blue, gray7, white } from 'app/components/ThemeProvider/colors';
import { jwtDecode } from 'jwt-decode';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { rightSide, header, mobileHeader } from './AddListingLayout.sx';
import UrexLogoDark from 'app/components/Icons/UrexLogoDark';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from 'app/pages/Authorization/hook/AuthProvider';

interface JwtPayload {
  user_name: string;
}

const AddListingLayout = () => {
  const { updateState, state } = useAuth();
  const token = localStorage.getItem('token');
  const userEmail = jwtDecode(token) as JwtPayload;
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 992px)');

  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleRedirect = (to: string) => {
    navigate(`/${to}`);
  };

  const signOut = () => {
    localStorage.removeItem('token');
    updateState({ ...state, isAuth: false });
    navigate('/auth/login');
  };

  return (
    <Box>
      {!isMobile && <Sidebar />}
      <Box sx={rightSide}>
        <Box sx={header}>
          <IconButton
            sx={{ p: '8px', border: `1px solid ${gray7}`, borderRadius: '8px', width: '30px', height: '30px' }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon sx={{ width: '14px', height: '14px', fill: black }} />
          </IconButton>
          <Box>
            <Button
              variant={'text'}
              sx={{ display: 'flex', gap: '10px', alignItems: 'center', padding: 0 }}
              onClick={(event: React.MouseEvent<any>) => {
                setAnchorEl(event.currentTarget);
                setOpenMenu(true);
              }}
              id="basic-button"
              aria-controls={openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
            >
              <AccountCircleOutlinedIcon sx={{ width: '40px !important', height: '40px !important' }} />
              <Typography sx={{ color: blue, fontWeight: 700 }}>{userEmail.user_name}</Typography>
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              sx={{ '& .MuiPaper-root': { width: '196px', mt: '10px' } }}
              id="basic-menu"
              open={openMenu}
              anchorEl={anchorEl}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              onClose={() => setOpenMenu(false)}
            >
              <MenuItem onClick={() => handleRedirect('dashboard/contacts')}>Go to Dashboard</MenuItem>
              <MenuItem onClick={() => handleRedirect('account')}>Account Settings</MenuItem>
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box sx={mobileHeader}>
          <Button
            sx={{ position: 'fixed', left: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon sx={{ fill: white }} />
          </Button>
          <UrexLogoDark />
        </Box>
        <Outlet />
      </Box>

      <SwipeableDrawer anchor="left" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <Sidebar view={'presentation'} />
      </SwipeableDrawer>
    </Box>
  );
};

export default AddListingLayout;

import { SxProps } from '@mui/material';
import { black3, black4, blue, gray9, white } from 'app/components/ThemeProvider/colors';

export const mainSection: SxProps = {
  pt: '96px',
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  margin: '0 auto 130px auto',

  '@media screen and (min-width: 1300px)': {width: "1274px"}
};

export const mainSectionBox: SxProps = {
  display: 'flex',
  gap: '42px',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& img': {
    width: '600px',
  },

  '@media screen and (max-width: 768px)': {
    '& img': {
      width: '90%',
    },
  },

  '@media screen and (max-width: 1380px)': {
    flexDirection: 'column',
    '&:nth-of-type(2n)': { flexDirection: 'column-reverse' },
  },
};

export const contactSection: SxProps = {
  position: 'relative',
  display: 'flex',
  mb: '75px',
  width: '100%',
  margin: '0 auto 75px auto',
  '@media screen and (max-width: 992px)': { flexDirection: 'column', '& img': { width: '70% !important' } },

  // '@media screen and (min-width: 1300px)': {width: "1274px"}
};

export const headerText: SxProps = {
  fontSize: '38px',
  color: black3,
  fontWeight: 600,
  mb: '16px',
  '@media screen and (max-width: 992px)': { fontSize: '20px' },
};

export const descText: SxProps = {
  color: black4,
  mb: '40px',
  '@media screen and (max-width: 992px)': { fontSize: '12px' },
};

export const aboutBtn: SxProps = {
  background: white,
  color: blue,
  border: `1px solid ${gray9}`,
  display: 'flex',
  gap: '10px',
  '&:hover': { background: white },
};

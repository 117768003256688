/* eslint-disable curly */
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { smsSchema } from './_schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendSMS } from './_requests';

const useSMS = (tools: { [key: string]: any }) => {
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    register,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(smsSchema) });

  const sendSMSMutation = useMutation((data: any) => sendSMS(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_LIST}-SEND-SMS`]);
      setValue('message', '');
      setValue('contactsId', undefined);
      setValue('propertyId', undefined);
      reset();
      tools.onOpenSnackbar(true);
      tools.onCloseSMSDrawer(false);
    },
    onError: () => {
      tools.onOpenSnackbar(true);
    },
  });

  const onSubmit = handleSubmit((data: any) => {
    const formData = new FormData();
    formData.append('Message', data?.message);
    formData.append('PropertyId', data?.propertyId?.id);
    for (const [key, value] of Object.entries(data.contactsId)) formData.append(`ContactsId[${key}]`, String(value));
    sendSMSMutation.mutate(formData);
  });

  return {
    control,
    register,
    getValues,
    errors,
    reset,
    onSubmit,
    sendSMSMutation,
  };
};

export { useSMS };

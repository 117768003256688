/* eslint-disable curly */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { reminderSchema } from './_schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { Reminder } from './_models';
import { useQueryResponse } from './QueryResponseProvider';
import { createReminder, getReminderByID, updateReminder } from './_requests';
import { getContacts, getContactById } from '../../Contacts/core/_requests';
import logger from 'app/utils/logger';
import format from 'date-fns/format';
import { isNotEmpty } from 'app/constants/helpers';
import { reminderTypes, reminderPriority } from './_helper';

const useReminder = (tools: { [key: string]: any }) => {
  const { refetch } = useQueryResponse();
  const queryClient = useQueryClient();

  const [oldContacts, setOldContacts] = useState([]);
  const [defaultContactOptions, setDefaultContactOptions] = useState([]);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(reminderSchema),
    defaultValues: { time: format(new Date(new Date().setHours(new Date().getHours() + 4)), "yyyy-MM-dd'T'HH:mm:ss") },
  });

  useQuery(`${QUERIES.CONTACT_LIST}-ITEM-${tools.contactId}`, () => getContactById(tools.contactId), {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isNotEmpty(tools.contactId),
    onSuccess: (data) => setValue('contactsId', [data] as unknown as any),
    onError: () => setValue('contactsId', []),
  });

  const enabledQuery: boolean = isNotEmpty(tools.itemIdForUpdate);
  const getReminderByIdQuery = useQuery(
    `${QUERIES.REMINDER_LIST}-contact-${tools.itemIdForUpdate}`,
    () => {
      return getReminderByID(tools.itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery && tools.itemIsUpdate,
      onSuccess: (data) => {
        if (tools.itemIsUpdate) {
          setValue('subject', data.subject || '');
          setValue('type', reminderTypes[data.type] || '');
          setValue('priority', reminderPriority[data.priority] || '');
          setValue('note', data.note || '');
          setValue('contactsId', data.contacts);
          setValue('time', data.time || '');
          const contactsId = data.contacts.map((item: { id: number }) => item.id);
          setOldContacts(contactsId);
        }
      },
      onError: (err) => {
        logger.error(err);
      },
    }
  );

  const createReminderMutation = useMutation((data: Reminder) => createReminder(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.REMINDER_LIST}-CREATE`]);
      setValue('note', '');
      setValue('contactsId', []);
      setValue('priority', undefined);
      setValue('type', undefined);
      setValue('time', '');
      reset();
      refetch();
      tools.params.set('ContactId', '');
      tools.setSearchParams(tools.params);
      tools.onCloseCreate();
    },
  });

  const updateReminderMutation = useMutation((data: Reminder) => updateReminder(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_LIST}-UPDATE`]);
      setValue('note', '');
      setValue('contactsId', []);
      setValue('priority', undefined);
      setValue('type', undefined);
      setValue('time', '');
      reset();
      tools.onCloseCreate();
      refetch();
      getReminderByIdQuery.refetch();
    },
    onError: () => {
      tools.onSetIsSnackbarOpen(true);
    },
  });

  const onSubmit = handleSubmit((data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isEdit = element.dataset.isedit;

    const contactsId = data.contactsId?.map((contact: { id: number }) => contact.id);
    const body = {
      ...data,
      type: data?.type?.id,
      priority: data?.priority?.id,
      propertyId: 0,
      contactsId,
    };

    const removeContactsId = [];
    for (const contact of oldContacts) {
      if (!contactsId?.includes(contact)) removeContactsId.push(contact);
    }

    if (tools.itemIsUpdate) delete body.contactsId;
    if (isEdit === 'true')
      updateReminderMutation.mutate({
        ...body,
        id: tools.itemIdForUpdate,
        removeContactsId,
        newContactsId: contactsId,
      });
    else createReminderMutation.mutate(body);
  });

  const loadContactOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = await getContacts(`PageNumber=${page * 10}&PageSize=10`);

    return {
      options: response.values,
      hasMore: page !== response.totalPages,
      additional: {
        page: page + 1,
      },
    };
  };

  return {
    control,
    register,
    getValues,
    setValue,
    errors,
    reset,
    onSubmit,
    createReminderMutation,
    updateReminderMutation,
    getReminderByIdQuery,
    loadContactOptions,
    defaultContactOptions,
  };
};

export { useReminder };

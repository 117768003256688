import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface Fill {
  fill: string;
}

const BackArrow = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  const sxStyle = sx as Fill;

  return (
    <SvgIcon
      viewBox="0 0 20 21"
      sx={{
        height: '21px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M7.97495 16.1834C7.81662 16.1834 7.65828 16.1251 7.53328 16.0001L2.47495 10.9418C2.23328 10.7001 2.23328 10.3001 2.47495 10.0584L7.53328 5.0001C7.77495 4.75843 8.17495 4.75843 8.41662 5.0001C8.65828 5.24176 8.65828 5.64176 8.41662 5.88343L3.79995 10.5001L8.41662 15.1168C8.65828 15.3584 8.65828 15.7584 8.41662 16.0001C8.29995 16.1251 8.13328 16.1834 7.97495 16.1834Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#888A8D'}
      />
      <path
        d="M17.0833 11.125H3.05835C2.71668 11.125 2.43335 10.8417 2.43335 10.5C2.43335 10.1583 2.71668 9.875 3.05835 9.875H17.0833C17.425 9.875 17.7083 10.1583 17.7083 10.5C17.7083 10.8417 17.425 11.125 17.0833 11.125Z"
        fill={sxStyle !== undefined ? sxStyle.fill : '#888A8D'}
      />
    </SvgIcon>
  );
};

export default BackArrow;

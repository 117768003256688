import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotificationSetting = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <g clipPath="url(#NotificationSetting_svg__a)">
      <mask
        id="NotificationSetting_svg__b"
        width={18}
        height={19}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="currentColor" d="M18 .988H0v18h18z" />
      </mask>
      <g fill="currentColor" mask="url(#NotificationSetting_svg__b)">
        <path d="M14.45 10.23V8.547a5.46 5.46 0 0 0-3.868-5.215V2.57c0-.872-.71-1.582-1.582-1.582s-1.582.71-1.582 1.582v.762a5.46 5.46 0 0 0-3.867 5.215v1.682a8.28 8.28 0 0 1-2.314 5.758.527.527 0 0 0 .38.892h4.8A2.64 2.64 0 0 0 9 18.989a2.64 2.64 0 0 0 2.584-2.11h4.799a.527.527 0 0 0 .38-.892 8.28 8.28 0 0 1-2.314-5.757M8.472 2.57a.528.528 0 0 1 1.054 0v.553a5.5 5.5 0 0 0-1.054 0zM9 17.934c-.687 0-1.274-.441-1.491-1.055h2.982A1.585 1.585 0 0 1 9 17.934m-6.246-2.11a9.32 9.32 0 0 0 1.852-5.594V8.547A4.4 4.4 0 0 1 9 4.152a4.4 4.4 0 0 1 4.395 4.395v1.682c0 2.037.65 3.985 1.851 5.595zM15.856 8.547a.527.527 0 0 0 1.054 0c0-2.113-.822-4.1-2.316-5.593a.527.527 0 0 0-.746.745 6.8 6.8 0 0 1 2.008 4.848M1.617 9.074a.527.527 0 0 0 .528-.527 6.8 6.8 0 0 1 2.007-4.848.527.527 0 1 0-.745-.745A7.86 7.86 0 0 0 1.09 8.547c0 .291.236.527.527.527" />
      </g>
    </g>
    <defs>
      <clipPath id="NotificationSetting_svg__a">
        <path fill="currentColor" d="M0 .488h18v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNotificationSetting;

import React from 'react';
import useCountdownTimer from './useCountdownTimer';
import { Box, Button, Typography } from '@mui/material';
import { blue, gray5 } from 'app/components/ThemeProvider/colors';

interface Props {
  resend: () => void;
}

const CountdownTimer = (props: Props) => {
  const { resend } = props;
  const { remainingSeconds, setSeconds, seconds, minutes } = useCountdownTimer();

  return (
    <Box sx={{ textAlign: 'center' }}>
      {seconds > 0 && (
        <Typography sx={{ fontSize: '14px', fontWeight: 600, lineHeight: 1, color: blue }}>
          0{minutes} : {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
        </Typography>
      )}
      {seconds === 0 && (
        <Box sx={{ display: 'flex', gap: '4px', justifyContent: 'center', alignItems: 'baseline' }}>
          <Typography sx={{ color: gray5, fontSize: '14px' }}>Didn&apos;t receive the code?</Typography>
          <Button
            variant="text"
            sx={{ color: blue, p: 0, fontSize: '14px', fontWeight: 600 }}
            onClick={() => {
              setSeconds(180);
              resend();
            }}
          >
            Send again
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CountdownTimer;

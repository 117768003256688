/* eslint-disable padding-line-between-statements */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { initialQueryResponse, initialQueryState, PaginationState, WithChildren } from 'app/constants/models';
import { stringifyRequestQuery, createResponseContext } from 'app/constants/helpers';
import { QUERIES } from 'app/constants/consts';
import { getContacts } from './_requests';
import { Contact } from './_models';
import { useQueryRequest } from './QueryRequestProvider';

const QueryResponseContext = createResponseContext<Contact>(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();

  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.CONTACT_LIST}-${query}`,
    () => {
      return getContacts(query);
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.values || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = initialQueryState;

  const { response } = useQueryResponse();
  if (!response || !response.totalCount || !response.totalPages) {
    return defaultPaginationState;
  }

  return {
    pageIndex: response.pageIndex,
    totalPages: response.totalPages,
    totalCount: response.totalCount,
    hasPreviousPage: response.hasPreviousPage,
    hasNextPage: response.hasNextPage,
  };
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};

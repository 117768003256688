import { black, black5, blue, gray1, gray9, gray13, red, white } from 'app/components/ThemeProvider/colors';

export const styles = {
  container: (base?: any) => ({
    ...base,
    width: '100%',
  }),
  control: (base?: any) => ({
    ...base,
    backgroundColor: 'white',
    height: '49px',
    boxShadow: 'none',
    borderColor: gray1,
    borderRadius: '10px',

    ':hover': {
      borderColor: gray9,
    },
  }),
  valueContainer: (provided?: any, state?: any) => ({
    ...provided,
    overflow: 'visible',
    textOverflow: 'ellipsis',
    padding: '0 8px',
  }),
  multiValueContainer: (provided?: any, state?: any) => ({
    ...provided,
    width: '90%',
  }),
  multiValue: (provided?: any, state?: any) => ({
    ...provided,
    backgroundColor: blue,
    borderRadius: '12px',
    margin: 0,
  }),
  multiValueLabel: (provided?: any, state?: any) => ({
    ...provided,
    color: white,
    padding: '4px 10px !important',
    boxSizing: 'box-sizing',
  }),
  multiValueRemove: (provided?: any, state?: any) => ({
    ...provided,
    color: white,

    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  placeholder: (provided?: any, state?: any) => ({
    ...provided,
    position: 'absolute',
    top: (state.hasValue || state.selectProps.inputValue) && -20,
    backgroundColor: 'white',
    width: 'fit-content',
    padding: state.hasValue || state.selectProps.inputValue ? '0 5px' : '0 0 0 2px',
    textAlign: state.hasValue || state.selectProps.inputValue ? 'center' : 'left',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 16,
    // left: '-3px',
    color: 'rgb(158 158 158)',
    fontWeight: '400',
    marginLeft: 0,
    marginRight: 0,
    // lineHeight: 1,
  }),
  dropdownIndicator: (base?: any, state?: any) => ({
    ...base,
    color: gray13,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? null : 'rotate(-90deg)',
  }),
  menu: (base?: any) => ({
    ...base,
    zIndex: '9999999',
  }),
  option: (provided?: any, state?: any) => ({
    backgroundColor: state.isSelected ? blue : white,
    color: state.isSelected ? white : state.isDisabled ? gray9 :  blue,
    padding: '16px 10px',

    ':hover': {
      cursor: 'pointer',
    },
  }),
};

export const errorStyles = {
  container: (base?: any) => ({
    ...base,
    width: '100%',
  }),
  control: (base?: any) => ({
    ...base,
    backgroundColor: 'white',
    height: '49px',
    boxShadow: 'none',
    borderColor: red,
    borderRadius: '10px',

    ':hover': {
      borderColor: red,
    },
  }),
  valueContainer: (provided?: any, state?: any) => ({
    ...provided,
    overflow: 'visible',
    textOverflow: 'ellipsis',
  }),
  multiValueContainer: (provided?: any, state?: any) => ({
    ...provided,
    width: '90%',
  }),
  multiValue: (provided?: any, state?: any) => ({
    ...provided,
    backgroundColor: blue,
    borderRadius: '12px',
  }),
  multiValueLabel: (provided?: any, state?: any) => ({
    ...provided,
    color: white,
    padding: '4px 10px !important',
    boxSizing: 'box-sizing',
  }),
  multiValueRemove: (provided?: any, state?: any) => ({
    ...provided,
    color: white,

    ':hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  }),
  placeholder: (provided?: any, state?: any) => ({
    ...provided,
    position: 'absolute',
    top: (state.hasValue || state.selectProps.inputValue) && -18,
    backgroundColor: 'white',
    width: 'fit-content',
    padding: state.hasValue || state.selectProps.inputValue ? '0 5px' : '0 0 0 2px',
    textAlign: state.hasValue || state.selectProps.inputValue ? 'center' : 'left',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: state.hasValue || state.selectProps.inputValue ? 12 : 16,
    // left: '-3px',
    color: 'rgb(158 158 158)',
    fontWeight: '400',
    marginLeft: 0,
    marginRight: 0,
    // lineHeight: 1,
  }),
  dropdownIndicator: (base?: any, state?: any) => ({
    ...base,
    color: black,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? null : 'rotate(-90deg)',
  }),
  menu: (base?: any) => ({
    ...base,
    zIndex: '9999999',
  }),
  option: (provided?: any, state?: any) => ({
    backgroundColor: state.isSelected ? blue : white,
    color: state.isSelected ? white : blue,
    padding: '16px 10px',

    ':hover': {
      cursor: 'pointer',
    },
  }),
};

import { Box, Button, CircularProgress, Collapse, TextField, Typography } from '@mui/material';
import React from 'react';
import { black2 } from 'app/components/ThemeProvider/colors';
import CustomInput from 'app/components/CustomInput';
import useInvestmentDetails from './useInvestmentDetails';
import CustomSelect from 'app/components/CustomSelect';
import {
  btnSectionBox,
  container,
  draftBtn,
  loaderBox,
  rowContainer,
  saveBtn,
  sectionBox,
} from './InvestmentDetails.sx';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const InvestmentDetails = () => {
  const {
    isLoadingContinue,
    isLoadingDraft,
    isLoadingAllProperties,
    dataAllProperties,
    control,
    register,
    role,
    openDesc,
    setOpenCondition,
    openCondition,
    setOpenDesc,
    handleClick,
    isMobile,
    onSubmit,
    getValues,
    errors,
  } = useInvestmentDetails();

  if (isLoadingAllProperties) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit} data-isdraft="false">
      <Box sx={container}>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDesc, openDesc)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Investment Description</Typography>
            {isMobile && (openDesc ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse
            in={openDesc}
            sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }}
          >
            <TextField
              multiline={true}
              type={'text'}
              error={errors.investmentDesc !== undefined}
              {...register('investmentDesc')}
              sx={{
                width: '100%',
                mb: '21px',
                '& .MuiInputBase-root ': {
                  height: '243px !important',
                  '& textarea': {
                    height: '243px !important',
                    padding: '12px 0',
                  },
                },
              }}
            />
            {role === 'residential' ? (
              <>
                <Box sx={rowContainer}>
                  <CustomSelect
                    label={'Investment Type'}
                    options={dataAllProperties?.investmentType}
                    control={control}
                    error={errors.investmentType}
                    rest={{
                      ...register('investmentType'),
                    }}
                    isMulti={true}
                  />
                  <CustomInput
                    id={'after-repair'}
                    label={'After Repair Value (ARV)'}
                    type={'text'}
                    error={errors.afterRepairValue}
                    rest={{
                      ...register('afterRepairValue', {
                        value: getValues('afterRepairValue') === undefined ? '' : getValues('afterRepairValue'),
                      }),
                    }}
                  />
                </Box>
                <Box sx={rowContainer}>
                  <CustomInput
                    id={'estimated-repairs'}
                    label={'Estimated Repairs Cost'}
                    type={'text'}
                    error={errors.estimatedRepairsCost}
                    rest={{
                      ...register('estimatedRepairsCost', {
                        value: getValues('estimatedRepairsCost') === undefined ? '' : getValues('estimatedRepairsCost'),
                      }),
                    }}
                  />
                  <CustomInput
                    id={'estimated-profit'}
                    label={'Estimated Profit'}
                    type={'text'}
                    error={errors.estimatedProfit}
                    rest={{
                      ...register('estimatedProfit', {
                        value: getValues('estimatedProfit') === undefined ? '' : getValues('estimatedProfit'),
                      }),
                    }}
                  />
                </Box>
                <Box sx={rowContainer}>
                  <CustomInput
                    id={'estimated-time'}
                    label={'Estimated Time for Repairs (Days)'}
                    type={'text'}
                    error={errors.estimatedTimeForRepairs}
                    rest={{
                      ...register('estimatedTimeForRepairs', {
                        value:
                          getValues('estimatedTimeForRepairs') === undefined
                            ? ''
                            : getValues('estimatedTimeForRepairs'),
                      }),
                    }}
                  />
                  {role === 'residential' && (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '25px',
                        width: '100%',
                        '@media screen and (max-width: 500px)': {
                          flexDirection: 'column',
                          gap: 0,
                        },
                      }}
                    >
                      <CustomInput
                        id={'rental-min'}
                        label={'Rental Income Potential Min '}
                        type={'text'}
                        error={errors.rentalIncomePotentialMin}
                        rest={{
                          ...register('rentalIncomePotentialMin', {
                            value:
                              getValues('rentalIncomePotentialMin') === undefined
                                ? ''
                                : getValues('rentalIncomePotentialMin'),
                          }),
                        }}
                      />
                      <CustomInput
                        id={'rental-max'}
                        label={'Rental Income Potential Max'}
                        type={'text'}
                        error={errors.rentalIncomePotentialMax}
                        rest={{
                          ...register('rentalIncomePotentialMax', {
                            value:
                              getValues('rentalIncomePotentialMax') === undefined
                                ? ''
                                : getValues('rentalIncomePotentialMax'),
                          }),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  gap: '30px',
                  '@media screen and (max-width: 500px)': {
                    flexDirection: 'column',
                    gap: 0,
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                  <Typography sx={{ width: '100%', fontSize: '20px', fontWeight: 600, color: black2 }}>
                    Actual
                  </Typography>
                  <Box>
                    <CustomInput
                      id={'cap-rate-actual'}
                      label={'Cap Rate'}
                      type={'text'}
                      error={errors.capRate}
                      rest={{
                        ...register('capRate', {
                          value: getValues('capRate') === undefined ? '' : getValues('capRate'),
                        }),
                      }}
                    />
                    <CustomInput
                      id={'income-actual'}
                      label={'Income'}
                      type={'text'}
                      error={errors.income}
                      rest={{
                        ...register('income', {
                          value: getValues('income') === undefined ? '' : getValues('income'),
                        }),
                      }}
                    />
                    <CustomInput
                      id={'expenses-actual'}
                      label={'Expenses'}
                      type={'text'}
                      error={errors.expenses}
                      rest={{
                        ...register('expenses', {
                          value: getValues('expenses') === undefined ? '' : getValues('expenses'),
                        }),
                      }}
                    />
                    <CustomInput
                      id={'noi-actual'}
                      label={'NOI'}
                      type={'text'}
                      error={errors.noi}
                      rest={{
                        ...register('noi', {
                          value: getValues('noi') === undefined ? '' : getValues('noi'),
                        }),
                      }}
                    />
                    <CustomSelect
                      label={'Investment Type'}
                      options={dataAllProperties?.investmentType}
                      control={control}
                      error={errors.investmentType}
                      rest={{
                        ...register('investmentType'),
                      }}
                      isMulti={true}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                  <Typography sx={{ width: '100%', fontSize: '20px', fontWeight: 600, color: black2 }}>
                    Pro Forma
                  </Typography>
                  <Box>
                    <CustomInput
                      id={'cap-rate-pro-forma'}
                      label={'Cap Rate'}
                      type={'text'}
                      error={errors.capRateProForma}
                      rest={{
                        ...register('capRateProForma', {
                          value: getValues('capRateProForma') === undefined ? '' : getValues('capRateProForma'),
                        }),
                      }}
                    />
                    <CustomInput
                      id={'income-pro-forma'}
                      label={'Income'}
                      type={'text'}
                      error={errors.incomeProForma}
                      rest={{
                        ...register('incomeProForma', {
                          value: getValues('incomeProForma') === undefined ? '' : getValues('incomeProForma'),
                        }),
                      }}
                    />
                    <CustomInput
                      id={'expenses-pro-forma'}
                      label={'Expenses'}
                      type={'text'}
                      error={errors.expensesProForma}
                      rest={{
                        ...register('expensesProForma', {
                          value: getValues('expensesProForma') === undefined ? '' : getValues('expensesProForma'),
                        }),
                      }}
                    />
                    <CustomInput
                      id={'noi-actual'}
                      label={'NOI'}
                      type={'text'}
                      error={errors.noiProForma}
                      rest={{
                        ...register('noiProForma', {
                          value: getValues('noiProForma') === undefined ? '' : getValues('noiProForma'),
                        }),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenCondition, openCondition)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Property Condition</Typography>
            {isMobile && (openCondition ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse
            in={openCondition}
            sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', flexDirection: 'column', gap: '10px' } }}
          >
            <Box sx={rowContainer}>
              <CustomInput
                id={'roof'}
                label={'Roof condition'}
                type={'text'}
                error={errors.roofCondition}
                rest={{
                  ...register('roofCondition', {
                    value: getValues('roofCondition') === undefined ? '' : getValues('roofCondition'),
                  }),
                }}
              />
              <CustomInput
                id={'heating'}
                label={'Heating Condition'}
                type={'text'}
                error={errors.heatingCondition}
                rest={{
                  ...register('heatingCondition', {
                    value: getValues('heatingCondition') === undefined ? '' : getValues('heatingCondition'),
                  }),
                }}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'cooling'}
                label={'Cooling Condition'}
                type={'text'}
                error={errors.coolingCondition}
                rest={{
                  ...register('coolingCondition', {
                    value: getValues('coolingCondition') === undefined ? '' : getValues('coolingCondition'),
                  }),
                }}
              />
              <CustomInput
                id={'construction'}
                label={'Construction Condition'}
                type={'text'}
                error={errors.constructionCondition}
                rest={{
                  ...register('constructionCondition', {
                    value: getValues('constructionCondition') === undefined ? '' : getValues('constructionCondition'),
                  }),
                }}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'foundation'}
                label={'Foundation Condition'}
                type={'text'}
                error={errors.foundationCondition}
                rest={{
                  ...register('foundationCondition', {
                    value: getValues('foundationCondition') === undefined ? '' : getValues('foundationCondition'),
                  }),
                }}
              />
              <CustomInput
                id={'basement'}
                label={'Basement Condition'}
                type={'text'}
                error={errors.basementCondition}
                rest={{
                  ...register('basementCondition', {
                    value: getValues('basementCondition') === undefined ? '' : getValues('basementCondition'),
                  }),
                }}
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={btnSectionBox}>
          <Button
            sx={saveBtn}
            onClick={onSubmit}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingDraft && <CircularProgress size={20} />}
            data-isdraft="true"
          >
            Save to draft
          </Button>
          <Button
            sx={draftBtn}
            type={'submit'}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingContinue && <CircularProgress size={20} />}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default InvestmentDetails;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const LinkedinIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      sx={{
        height: '24px !important',
        width: '24px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <rect width="24" height="24" fill="#260A0A" fillOpacity="0.01" />
      <path
        d="M5.92503 23.0008H1.3638V8.31212H5.92503V23.0008ZM3.64167 6.30848C2.18333 6.30848 1 5.1 1 3.64167C1 2.18333 2.18254 1 3.64167 1C5.10079 1 6.28333 2.18254 6.28333 3.64167C6.28333 5.1 5.1 6.30848 3.64167 6.30848ZM22.9961 23.0008H18.4451V15.8505C18.4451 14.1463 18.4105 11.9611 16.0737 11.9611C13.7023 11.9611 13.3385 13.8123 13.3385 15.728V23.0008H8.78199V8.31212H13.1562V10.3158H13.2199C13.8288 9.1615 15.3162 7.9436 17.5352 7.9436C22.1514 7.9436 23 10.9836 23 14.932V23.0008H22.9961Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default LinkedinIcon;

/* eslint-disable react/react-in-jsx-scope */
import { ReminderHeader } from './components/header/Header';
import { ReminderList } from './components/reminderList/ReminderList';
import { QueryResponseProvider } from './core/QueryResponseProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { ListViewProvider } from './core/ListViewProvider';

const ReminderPage = () => {
  return (
    <>
      <ReminderHeader />
      <ReminderList />
    </>
  );
};

const ReminderWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <ReminderPage />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export { ReminderWrapper };

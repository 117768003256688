import { httpClient } from 'app/api/http/http-client';
import { useMutation } from 'react-query';
import {
  ForgotPassRequestType,
  LoginRequestType,
  OtpRequestType,
  RegistrationRequestType,
  ResetPassRequestType,
} from 'app/utils/services/types/auth.types';

const authService = {
  loginService: async (data: { data: LoginRequestType; headers: { device: string; ipAddress: string } }) => {
    try {
      const result = await httpClient.post('/Accaount/Login', data.data, {
        headers: data.headers,
      });

      return result;
    } catch (error: any) {
      return error;
    }
  },

  registrationService: async (data: {
    data: RegistrationRequestType;
    headers: { device: string; ipAddress: string };
  }) => {
    try {
      const result = await httpClient.post('/Accaount/Register', data.data, {
        headers: data.headers,
      });

      return result;
    } catch (error: any) {
      return error;
    }
  },

  forgotPassService: async (data: ForgotPassRequestType) => {
    try {
      const result = await httpClient.post('/Accaount/email/forgot', data);

      return result;
    } catch (error: any) {
      return error;
    }
  },

  otpService: async (data: {
    data: OtpRequestType;
    url: string;
    headers?: { Authorization: string; device: string };
  }) => {
    try {
      const result = await httpClient.post(`/Accaount${data.url}`, data.data, {
        headers: data.headers,
      });

      return result;
    } catch (error: any) {
      return error;
    }
  },

  resendService: async (data: {
    data: OtpRequestType | null;
    url: string;
    headers?: { Authorization: string; device: string };
  }) => {
    try {
      const result = await httpClient.post(`/Accaount${data.url}`, data.data, {
        headers: data.headers,
      });

      return result;
    } catch (error: any) {
      return error;
    }
  },

  resetPassService: async (data: { data: ResetPassRequestType; headers?: { ipAddress: string; device: string } }) => {
    try {
      const result = await httpClient.post(`/Accaount/email/changePassword`, data.data, {
        headers: data.headers,
      });

      return result;
    } catch (error: any) {
      return error;
    }
  },
};

export const useLoginService = () => {
  const {
    isLoading: isLoadingLogin,
    isSuccess: isSuccessLogin,
    data: dataLogin,
    mutate: loginMutation,
  } = useMutation(['login'], (data: { data: LoginRequestType; headers: { device: string; ipAddress: string } }) =>
    authService.loginService(data)
  );

  return {
    isLoadingLogin,
    isSuccessLogin,
    dataLogin,
    loginMutation,
  };
};

export const useRegistrationService = () => {
  const {
    isSuccess: isSuccessRegistration,
    data: dataRegistration,
    mutate: registrationMutation,
  } = useMutation(
    ['registration'],
    (data: { data: RegistrationRequestType; headers: { device: string; ipAddress: string } }) =>
      authService.registrationService(data)
  );

  return {
    isSuccessRegistration,
    dataRegistration,
    registrationMutation,
  };
};

export const useForgotPassService = () => {
  const {
    isSuccess: isSuccessForgotPass,
    data: dataForgotPass,
    mutate: forgotPassMutation,
  } = useMutation(['forgotPass'], (data: ForgotPassRequestType) => authService.forgotPassService(data));

  return {
    isSuccessForgotPass,
    dataForgotPass,
    forgotPassMutation,
  };
};
export const useOtpService = () => {
  const {
    isSuccess: isSuccessOtp,
    data: dataOtp,
    mutate: otpMutation,
  } = useMutation(
    ['otp'],
    (data: { data: OtpRequestType; url: string; headers?: { Authorization: string; device: string } }) =>
      authService.otpService(data)
  );

  return {
    isSuccessOtp,
    dataOtp,
    otpMutation,
  };
};

export const useResendOtpService = () => {
  const {
    isSuccess: isSuccessResendOtp,
    data: dataResendOtp,
    mutate: resendOtpMutation,
  } = useMutation(
    ['resendOtp'],
    (data: { data: OtpRequestType | null; url: string; headers?: { Authorization: string; device: string } }) =>
      authService.resendService(data)
  );

  return {
    isSuccessResendOtp,
    dataResendOtp,
    resendOtpMutation,
  };
};

export const useResetPassService = () => {
  const {
    isSuccess: isSuccessResetPass,
    data: dataResetPass,
    mutate: resetPassMutation,
  } = useMutation(
    ['resetPass'],
    (data: { data: ResetPassRequestType; headers: { device: string; ipAddress: string } }) =>
      authService.resetPassService(data)
  );

  return {
    isSuccessResetPass,
    dataResetPass,
    resetPassMutation,
  };
};

import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFi7276364 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 19" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m5.366 6.978 3.5 3.833a.564.564 0 0 0 .813.018l6.937-6.937a.562.562 0 1 0-.795-.795L9.3 9.618 6.197 6.219a.563.563 0 0 0-.831.759"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.281 1.994h-5.25A2.813 2.813 0 0 0 1.22 4.807v9.375a2.813 2.813 0 0 0 2.812 2.812h9.375a2.813 2.813 0 0 0 2.813-2.812v-5.25a.563.563 0 0 0-1.125 0v5.25c0 .932-.755 1.687-1.688 1.687H4.031a1.687 1.687 0 0 1-1.687-1.687V4.807c0-.933.755-1.688 1.687-1.688h5.25a.563.563 0 0 0 0-1.125"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFi7276364;

import { serverUrl } from 'app/constants/environments';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosStatic, InternalAxiosRequestConfig } from 'axios';
import { BaseSuccessResponse } from './types';

class HttpClient {
  private readonly api: AxiosInstance;

  private readonly config: AxiosRequestConfig = {
    baseURL: serverUrl,
    responseType: 'json',
    headers: {
      Accept: 'application/json',
      Authorization: '',
      'Content-Type': 'application/json',
    },
  };

  constructor(axiosStatic: AxiosStatic) {
    this.api = axiosStatic.create(this.config);
    this.initInterceptors();
  }

  public get<T, R = BaseSuccessResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.get(url, config);
  }

  public delete<T, R = BaseSuccessResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config);
  }

  public post<T, R = BaseSuccessResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return this.api.post(url, data, config);
  }

  public put<T, D, R = BaseSuccessResponse<T>>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> {
    return this.api.put(url, data, config);
  }

  public patch<T, D, R = BaseSuccessResponse<T>>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> {
    return this.api.patch(url, data, config);
  }

  private initInterceptors(): void {
    this.api.interceptors.request.use(
      (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
        const token = this.getAuth();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (err: any) => Promise.reject(err)
    );
    this.api.interceptors.response.use(
      (response) => response.data,
      (error: AxiosError) => {

        if(error?.code === "ERR_NETWORK") {
          this.removeAuth();
          window.location.reload();
        }

        return  Promise.reject(error.response.data)
      }
    );
  }

  private getAuth(): string | null {
    if (!localStorage) {
      return;
    }

    const token: string | null = localStorage.getItem('token');
    if (!token) {
      return;
    }

    return token;
  }

  private removeAuth(): void {
    localStorage.removeItem('token');
  }
}

export const httpClient = new HttpClient(axios);

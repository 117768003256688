import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEmailCamping = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="M16.418 3.664H1.582C.708 3.664 0 4.376 0 5.246v9.492c0 .875.713 1.582 1.582 1.582h14.836c.867 0 1.582-.704 1.582-1.582V5.246c0-.868-.705-1.582-1.582-1.582m-.222 1.055-6.077 6.046A1.57 1.57 0 0 1 9 11.228c-.423 0-.82-.165-1.12-.464L1.804 4.719zM1.055 14.524V5.46l4.557 4.534zm.75.742 4.555-4.527.776.771A2.62 2.62 0 0 0 9 12.283c.704 0 1.366-.275 1.864-.772l.776-.772 4.556 4.527zm15.14-.742-4.557-4.529 4.557-4.534z"
    />
  </svg>
);
export default SvgEmailCamping;

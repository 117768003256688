/* eslint-disable padding-line-between-statements */
import { httpClient } from 'app/api/http/http-client';
import { Reminder, RemindersQueryResponse } from './_models';

const getReminders = (status: number): Promise<RemindersQueryResponse> => {
  return httpClient.get(`/Reminder/status/${status}`);
};

const getReminderByID = (id: number): Promise<Reminder> => {
  return httpClient.get(`/Reminder/${id}`);
};

const deleteReminder = (id: number): Promise<boolean> => {
  return httpClient.delete(`/Reminder/${id}`);
};

const complatedReminder = (id: number): Promise<boolean> => {
  return httpClient.put(`/Reminder/${id}/status/complated`);
};

const createReminder = (body: Reminder): Promise<boolean> => {
  return httpClient.post('/Reminder', body);
};

const updateReminder = (body: Reminder): Promise<boolean> => {
  return httpClient.put('/Reminder', body);
};

export { getReminders, getReminderByID, updateReminder, deleteReminder, complatedReminder, createReminder };

/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { ContactList } from '../contactList/ContactList';
import FilterMenu from 'app/components/Filter/Filter';
import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const Subheader = () => {
  const [age, setAge] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  return (
    <div className="row">
      <div className="col-12 col-md-8">
        <ContactList />
      </div>
      <div className="col-12 col-md-4 d-flex justify-content-end">
        <FilterMenu>
          <div className="w-100 p-4">
            <Typography variant="h3" className="fs-5 bolder">
              Filter options
            </Typography>
          </div>
          <Divider sx={{ my: 0.5 }} />
          <div className="w-350px p-4">
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={age} onChange={handleChange}>
                <MenuItem value="tag">Tag</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="w-100 p-4">
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={age} onChange={handleChange}>
                <MenuItem value="list">List</MenuItem>
              </Select>
            </FormControl>
          </div>
        </FilterMenu>
      </div>
    </div>
  );
};

export { Subheader };

import React from 'react';
import { Box } from '@mui/material';
import Header from 'app/components/Layout/Header';
import HomePageInfo from 'app/components/HomePageInfo';
import HomePageMain from 'app/components/HomePageMain';
import Footer from 'app/components/Layout/Footer';

const HomePage = () => {
  return (
    <Box>
      <Box sx={{ background: 'url(/images/mainSectionImages/img_2.png)', backgroundSize: 'cover' }}>
        <Header />
        <HomePageInfo />
      </Box>
      <HomePageMain />
      <Footer />
    </Box>
  );
};

export default HomePage;

/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC, useState } from 'react';
import { Button, Drawer, Box, TextField, CircularProgress, Snackbar, Alert } from '@mui/material';
import { drawerBody, drawerTextArea } from './SMSStyles.sx';
import ClearIcon from '@mui/icons-material/Clear';
import CustomSelect from 'app/components/CustomSelect';
import { useSMS } from '../../core/useSMS';
import { Controller } from 'react-hook-form';
import FieldError from 'app/components/FieldError';
import { useQueryResponseData } from '../../core/QueryResponseProvider';
import { useQuery } from 'react-query';
import { getProperties } from 'app/pages/Dashboard/Property/core/_requests';
import { QUERIES } from 'app/constants/consts';

type Props = {
  isOpenDrawer: boolean;
  setIsOpenDrawer: (isOpenDrawer: boolean) => void;
};

const SendSMS: FC<Props> = ({ isOpenDrawer, setIsOpenDrawer }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const contacts = useQueryResponseData();
  const getAllProperties = useQuery(
    `${QUERIES.CONTACT_LIST}-properties`,
    () => {
      return getProperties();
    },
    {
      cacheTime: 20 * 60 * 1000,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  const { control, errors, register, onSubmit, sendSMSMutation } = useSMS({
    onCloseSMSDrawer: setIsOpenDrawer,
    onOpenSnackbar: setIsSnackbarOpen,
  });

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={sendSMSMutation.isSuccess ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {sendSMSMutation.isError ? (sendSMSMutation.error as any)?.message : `Your sms sent successlufy!`}
        </Alert>
      </Snackbar>
      <Drawer anchor="right" variant="temporary" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
        <Box sx={drawerBody}>
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="fs-5 fw-bold text-black">Send SMS</h2>
            <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={() => setIsOpenDrawer(false)}>
              <ClearIcon />
            </Button>
          </div>
          <form className="row mt-5" onSubmit={onSubmit}>
            <div className="col-12 mb-2">
              <CustomSelect
                label="Send To"
                control={control}
                rest={{
                  ...register('contactsId'),
                }}
                options={contacts}
                error={errors.contactsId}
                isMulti
              />
            </div>
            <div className="col-12 mb-4">
              <Controller
                control={control}
                name="message"
                render={({ field: { onBlur, onChange, value } }) => (
                  <>
                    <TextField
                      multiline={true}
                      sx={drawerTextArea}
                      placeholder="Message"
                      label="Message"
                      id="message"
                      value={value}
                      error={errors.message !== undefined}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                    <FieldError error={errors.message} />
                  </>
                )}
              />
            </div>
            <div className="col-12 mb-2">
              <CustomSelect
                label="Related Property"
                control={control}
                rest={{
                  ...register('propertyId'),
                }}
                options={getAllProperties.data || []}
                error={errors.propertyId}
              />
            </div>

            <div className="col-12">
              <Button
                type="submit"
                className="w-100"
                size="large"
                disabled={sendSMSMutation.isLoading}
                startIcon={sendSMSMutation.isLoading && <CircularProgress size={20} />}
              >
                Send Message
              </Button>
            </div>
          </form>
        </Box>
      </Drawer>
    </>
  );
};

export { SendSMS };

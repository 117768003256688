import * as React from 'react';
import type { SVGProps } from 'react';
const SvgProperties = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="m16.563 6.308-1.675-1.005v-2.08a.886.886 0 0 0-.884-.886h-1.032a.885.885 0 0 0-.885.885v.4L9.9 2.31a1.76 1.76 0 0 0-1.803 0L1.436 6.308a.65.65 0 0 0-.22.889l.378.63a.65.65 0 0 0 .89.223l.628-.377v6.792a.281.281 0 1 0 .563 0v-7.13l5.283-3.168a.08.08 0 0 1 .086.001l3.65 2.192c.065.037.14.047.212.029 1.349.748.249.004.287-.059a.28.28 0 0 0-.093-.384L9.335 3.687a.64.64 0 0 0-.668-.001L2.193 7.569a.08.08 0 0 1-.063.008.1.1 0 0 1-.053-.039l-.377-.63a.087.087 0 0 1 .027-.118l6.66-3.997a1.2 1.2 0 0 1 1.225 0l6.66 3.996a.087.087 0 0 1 .028.12l-.377.63a.083.083 0 0 1-.117.029l-3.018-1.809a.281.281 0 0 0-.29.483l1.827 1.095v7.93h-2.917v-4.413a.845.845 0 0 0-.843-.844h-3.13a.845.845 0 0 0-.843.844v4.413H3.675v-1.111a.281.281 0 0 0-.563 0v1.11a.857.857 0 0 0-.95.845v1.04a.28.28 0 0 0 .28.282H11.5a.28.28 0 0 0 0-.563H2.724v-.76a.28.28 0 0 1 .281-.28h11.99a.28.28 0 0 1 .28.28v.76h-4.258a.281.281 0 0 0 0 .563h4.54a.28.28 0 0 0 .281-.282v-1.04a.857.857 0 0 0-.95-.844V7.674l.627.376a.65.65 0 0 0 .891-.223l.377-.63a.65.65 0 0 0-.22-.89m-3.914-3.086a.323.323 0 0 1 .323-.322h1.032a.32.32 0 0 1 .322.322v1.743L12.649 3.96zm-5.495 7.632a.28.28 0 0 1 .281-.282h3.13a.28.28 0 0 1 .28.282v4.411h-3.69z"
    />
    <path
      fill="currentColor"
      d="M10.356 7.644c-.057-1.792-2.655-1.792-2.711 0 .057 1.791 2.654 1.79 2.71 0m-2.149 0a.793.793 0 0 1 1.586 0 .793.793 0 0 1-1.586 0M7.986 12.662a.28.28 0 0 0 0 .562.282.282 0 0 0 0-.562"
    />
  </svg>
);
export default SvgProperties;

/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CreateForm } from './CreateForm';
import { Setting4, Trash } from 'app/components/SvgIcons';

type Props = {
  id?: number;
  title?: string;
  isActive?: boolean;
  isCreateMode?: boolean;
  isEditableMode?: boolean;
  updatedState?: () => void;
  onDeleteShow?: (id: number) => void;
};

const ContactListButton: React.FC<Props> = ({
  id,
  title,
  isActive,
  isCreateMode,
  isEditableMode,
  updatedState,
  onDeleteShow,
}) => {
  const [isCreate, setIsCreate] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <>
      {isCreate && <CreateForm setIsCreate={setIsCreate} itemIdforEdit={id} title={title} />}

      {!isCreateMode && !isCreate && (
        <div className="d-flex align-items-center">
          <Button
            aria-describedby={popoverId}
            variant="text"
            className={`p-0 min-w-auto ${isActive ? 'text-black fw-bold' : ''}`}
            onClick={updatedState}
          >
            {title}
          </Button>

          {isEditableMode && (
            <Button variant="text" className="p-0 ps-2 m-0 min-w-auto" onClick={handleClick}>
              <MoreVertIcon />
            </Button>
          )}
          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{ zIndex: 10 }}
          >
            <div className="d-flex flex-column bg-white gap-2 shadow">
              <Button
                variant="text"
                size="medium"
                className="d-flex justify-content-start"
                onClick={() => {
                  setIsCreate(true);
                  handleClose();
                }}
                startIcon={<Setting4 className="fs-6" />}
              >
                Edit
              </Button>
              <Button
                variant="text"
                size="medium"
                className="d-flex justify-content-start"
                onClick={() => onDeleteShow(id)}
                startIcon={<Trash className="fs-6" />}
              >
                Delete
              </Button>
            </div>
          </Popover>
        </div>
      )}

      {isCreateMode && !isCreate && (
        <Button size="small" variant="text" className="p-0" onClick={() => setIsCreate(true)}>
          Create new list +
        </Button>
      )}
    </>
  );
};

export { ContactListButton };

import React, { useEffect, useState } from 'react';
import { useRegistrationService } from 'app/utils/services/authService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import logger from 'app/utils/logger';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../hook/AuthProvider';

const useRegistration = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const [phoneNumberHiddenValue, setPhoneNumberHiddenValue] = useState<string>('');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const confirm = searchParams.get('confirm');
  const { updateState, state } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    getValues,
  } = useForm({ resolver: yupResolver(schema) });
  const { isSuccessRegistration, dataRegistration, registrationMutation } = useRegistrationService();

  useEffect(() => {
    if (dataRegistration?.message !== undefined) {
      setOpenSnackbar(true);
    }

    if (dataRegistration?.token) {
      updateState({ ...state, token: dataRegistration?.token, refreshToken: dataRegistration?.refreshToken });
      navigate('/auth/registration?confirm=email');
      // localStorage.setItem('token', dataRegistration?.token);
      // localStorage.setItem('refreshToken', dataRegistration?.refreshToken);
    }
  }, [dataRegistration]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangePhone = (e: any) => {
    const inputValue = e.target.value;
    const cleanedValue = inputValue.replace(/[^\d]/g, ''); // Remove non-digit characters

    let formattedString = '';

    if (cleanedValue.length <= 3) {
      formattedString = cleanedValue;
    } else if (cleanedValue.length <= 6) {
      formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3)}`;
    } else {
      formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
    }

    const hiddenInputValue = inputValue.replace(/[()\s-]/g, '');

    setPhoneNumberValue(formattedString);
    setPhoneNumberHiddenValue(`+1${hiddenInputValue}`);
  };

  const onSubmit = (data: any) => {
    registrationMutation({
      data: {
        email: data.email,
        pasword: data.password,
        lastName: data.lastname,
        phoneNumber: phoneNumberHiddenValue,
        name: data.name,
      },
      headers: { device: 'test', ipAddress: 'test' },
    });
  };

  const onFormSubmit = handleSubmit(onSubmit);

  return {
    openSnackbar,
    setOpenSnackbar,
    register,
    showPassword,
    errors,
    onFormSubmit,
    handleClickShowPassword,
    handleMouseDownPassword,
    dataRegistration,
    handleChangePhone,
    phoneNumberValue,
    phoneNumberHiddenValue,
    control,
    confirm,
    getValues,
  };
};

export default useRegistration;

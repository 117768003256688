import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CloudUpload = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 49 48"
      sx={{
        height: '48px !important',
        width: '49px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M32.5 32L24.5 24L16.5 32"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 24V42"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2799 36.78C43.2306 35.7165 44.7716 34.0338 45.6597 31.9973C46.5477 29.9607 46.7323 27.6865 46.1843 25.5334C45.6363 23.3803 44.3869 21.471 42.6333 20.1069C40.8796 18.7428 38.7216 18.0015 36.4999 18H33.9799C33.3745 15.6585 32.2462 13.4847 30.6798 11.642C29.1134 9.7993 27.1496 8.3357 24.9361 7.36121C22.7226 6.38673 20.317 5.92672 17.9002 6.01576C15.4833 6.10481 13.1181 6.7406 10.9823 7.87533C8.84649 9.01006 6.99574 10.6142 5.56916 12.5672C4.14259 14.5201 3.1773 16.7711 2.74588 19.1508C2.31446 21.5305 2.42813 23.9771 3.07835 26.3065C3.72856 28.636 4.8984 30.7877 6.49992 32.6"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 32L24.5 24L16.5 32"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CloudUpload;

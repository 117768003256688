import React from 'react';
import { Box, Typography } from '@mui/material';
import PropertyInfoIcon from 'app/components/Icons/PropertyInfoIcon';
import { white } from 'app/components/ThemeProvider/colors';
import { NavLink, useLocation } from 'react-router-dom';
import { style, container, addListType } from './Sidebar.sx';
import MarketingDescriptionIcon from 'app/components/Icons/MarketingDescriptionIcon';
import InvestmentDetailsIcon from 'app/components/Icons/InvestmentDetailsIcon';
import GalleryIcon from 'app/components/Icons/GalleryIcon';
import ConfidentialityIcon from 'app/components/Icons/ConfidentialityIcon';
import PersonDetailsIcon from 'app/components/Icons/PersonDetailsIcon';
import PreviewIcon from 'app/components/Icons/PreviewIcon';
import UrexLogoSecond from 'app/components/Icons/UrexLogoSecond';
import CommercialIcon from 'app/components/Icons/CommercialIcon';
import ResidentialIcon from 'app/components/Icons/ResidentialIcon';

interface Props {
  view?: string;
}

const Sidebar = (props: Props) => {
  const { view } = props;
  const location = useLocation();
  const role = location.pathname.split('/')[2];

  const menuItems = [
    {
      id: 1,
      label: 'Property Information',
      icon: <PropertyInfoIcon />,
      active_icon: <PropertyInfoIcon sx={{ fill: white }} />,
      link: `/add-listing/${role}/property-information`,
    },
    {
      id: 2,
      label: 'Marketing Description',
      icon: <MarketingDescriptionIcon />,
      active_icon: <MarketingDescriptionIcon sx={{ fill: white }} />,
      link: `/add-listing/${role}/marketing-description`,
    },
    {
      id: 3,
      label: 'Investment Details',
      icon: <InvestmentDetailsIcon />,
      active_icon: <InvestmentDetailsIcon sx={{ fill: white }} />,
      link: `/add-listing/${role}/investment-details`,
    },
    {
      id: 4,
      label: 'Photos and Videos',
      icon: <GalleryIcon />,
      active_icon: <GalleryIcon sx={{ fill: white }} />,
      link: `/add-listing/${role}/gallery`,
    },
    {
      id: 5,
      label: 'Confidentiality',
      icon: <ConfidentialityIcon />,
      active_icon: <ConfidentialityIcon sx={{ fill: white }} />,
      link: `/add-listing/${role}/confidentiality`,
    },
    {
      id: 6,
      label: 'Listing Person Details',
      icon: <PersonDetailsIcon />,
      active_icon: <PersonDetailsIcon sx={{ fill: white }} />,
      link: `/add-listing/${role}/person-details`,
    },
    {
      id: 7,
      label: 'Preview',
      icon: <PreviewIcon />,
      active_icon: <PreviewIcon sx={{ fill: white }} />,
      link: `/add-listing/${role}/preview`,
    },
  ];

  return (
    <Box sx={container(view !== undefined)} role={view}>
      <UrexLogoSecond sx={{ m: '0 auto' }} />
      <Box sx={addListType}>
        {role === 'commercial' ? (
          <>
            <CommercialIcon />
            <Typography sx={{ fontSize: '14px', fontWeight: 600, lineHeight: 1 }}>Commercial</Typography>
          </>
        ) : (
          role === 'residential' && (
            <>
              <ResidentialIcon />
              <Typography sx={{ fontSize: '14px', fontWeight: 600, lineHeight: 1 }}>Residential</Typography>
            </>
          )
        )}
      </Box>
      {menuItems.map((item) => (
        <NavLink key={item.id} to={item.link} style={({ isActive }) => (isActive ? style.activeLink : style.link)}>
          {({ isActive }) => (
            <>
              {isActive ? (
                <>
                  {item.active_icon} {item.label}
                </>
              ) : (
                <>
                  {item.icon} {item.label}
                </>
              )}
            </>
          )}
        </NavLink>
      ))}
    </Box>
  );
};

export default Sidebar;

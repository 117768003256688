import React, { useState } from 'react';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { white } from 'app/components/ThemeProvider/colors';
import { rightSide, mobileHeader, content } from './DashboardLayout.sx';
import UrexLogoDark from 'app/components/Icons/UrexLogoDark';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';

const AddListingLayout = () => {
  const isMobile = useMediaQuery('(max-width: 992px)');

  const [open, setOpen] = useState(false);

  return (
    <Box>
      {!isMobile && <Sidebar />}
      <Box sx={rightSide}>
        <Box sx={mobileHeader}>
          <Button
            sx={{ position: 'fixed', left: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => setOpen(true)}
          >
            <MenuIcon sx={{ fill: white }} />
          </Button>
          <UrexLogoDark />
        </Box>
        <Box sx={content}>
          <Outlet />
        </Box>
      </Box>

      <SwipeableDrawer anchor="left" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
        <Sidebar view={'presentation'} />
      </SwipeableDrawer>
    </Box>
  );
};

export default AddListingLayout;

import { SxProps } from '@mui/material';

export const drawerBody: SxProps = {
  minWidth: '320px',
  maxWidth: '100%',
  padding: '20px',

  '@media only screen and (min-width: 768px)': {
    maxWidth: '550px',
    width: '550px',
  },
};

export const drawerTextArea: SxProps = {
  width: '100%',
  '& .MuiInputBase-root ': {
    height: '95px !important',
    '& textarea': {
      height: '95px !important',
      padding: '12px 0',
    },
  },
};

/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { Dispatch, SetStateAction, ReactNode } from 'react';

export type ID = undefined | null | number;

export type WithChildren = {
  children?: ReactNode;
};

export type PaginationState = {
  PageNumber?: number;
  PageSize?: number;
  GroupId?: number;
  OrderByParameter?: string;
  OrderByType?: 0 | 1;
};

export type SearchState = {
  Title?: string;
  SearchFilter?: string;
  NameSurname?: string;
};

export type QueryState = PaginationState & SearchState;

export type Response<T> = {
  map: any;
  find: any;
  id: number;
  data?: T;
  pageIndex?: number;
  totalPages?: number;
  totalCount?: number;
  values?: T;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
};

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
  PageNumber: 0,
  PageSize: 10,
  OrderByParameter: 'Id',
  OrderByType: 0,
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined;
  refetch: () => void;
  isLoading: boolean;
  query?: string;
  error?: Error;
};

export const initialQueryResponse = { refetch: () => {}, isLoading: false, query: '' };

export type ListViewContextProps = {
  selected: Array<ID>;
  onSelect: (selectedId: ID) => void;
  onSelectAll: () => void;
  clearSelected: () => void;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID;
  itemIsUpdate?: boolean;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  setItemIsUpdate: Dispatch<SetStateAction<boolean>>;
  isAllSelected: boolean;
  disabled: boolean;
  isOpenCreateDrawer?: boolean;
  setIsOpenCreateDrawer: Dispatch<SetStateAction<boolean>>;
  isOpenDetails?: boolean;
  setIsOpenDetails: Dispatch<SetStateAction<boolean>>;
};

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  setItemIsUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  itemIsUpdate: false,
  isOpenCreateDrawer: false,
  setIsOpenCreateDrawer: () => {},
  isOpenDetails: false,
  setIsOpenDetails: () => {},
};

export type Error = {
  errors?: { status: string[] };
  status?: number;
  title?: string;
};

import { array, lazy, number, object, string } from 'yup';

export const schema = object().shape({
  investmentDesc: string().required('This field is required'),
  investmentType: array().required('This field is required'),
  afterRepairValue: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  estimatedRepairsCost: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  estimatedProfit: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  estimatedTimeForRepairs: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  rentalIncomePotentialMin: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  rentalIncomePotentialMax: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  capRate: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  income: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  expenses: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  noi: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  capRateProForma: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  incomeProForma: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  expensesProForma: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  noiProForma: lazy((value) => (value === '' ? string() : number().typeError('Must be a number'))),
  roofCondition: string(),
  heatingCondition: string(),
  coolingCondition: string(),
  constructionCondition: string(),
  foundationCondition: string(),
  basementCondition: string(),
});

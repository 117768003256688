import { Typography } from '@mui/material';
import logger from 'app/utils/logger';
import React, { ErrorInfo } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<{ children?: any }, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('ERROR:', error);
    logger.error('ERROR INFO:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Typography variant={'h3'}>errors</Typography>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react';
import { Button } from '@mui/material';
import { gray4 } from 'app/components/ThemeProvider/colors';
import { Link } from 'react-router-dom';
import useForgotPass from './useForgotPass';
import BackArrow from 'app/components/Icons/BackArrow';
import ConfirmPage from 'app/pages/Authorization/Confirm';
import SetNewPass from 'app/pages/Authorization/SetNewPass';
import CustomInput from 'app/components/CustomInput';
import AuthCard from 'app/components/AuthCard';

const ForgotPass = () => {
  const { openSnackbar, setOpenSnackbar, onFormSubmit, register, errors, dataForgotPass, confirm, getValues, newPass } =
    useForgotPass();

  if (confirm === 'password_reset') {
    return <ConfirmPage email={getValues('email')} forgotPass={true} />;
  }
  if (newPass === 'set_new_password') {
    return <SetNewPass email={getValues('email')} />;
  } else {
    return (
      <AuthCard
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        data={dataForgotPass}
        header={' Forgot Password?'}
        desc={'Enter the email address you used to register with UREXC'}
      >
        <form style={{ display: 'flex', flexDirection: 'column', gap: '3px' }} onSubmit={onFormSubmit}>
          <CustomInput
            id={'forgot-email'}
            label={'Email'}
            type={'email'}
            error={errors.email}
            rest={{ ...register('email') }}
          />
          <Button type={'submit'} sx={{ mt: '21px', mb: '48px' }}>
            Resent Password
          </Button>
        </form>
        <Link
          to={'/auth/login'}
          style={{
            textDecoration: 'none',
            color: gray4,
            display: 'flex',
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          <BackArrow />
          Back to log in
        </Link>
      </AuthCard>
    );
  }
};

export default ForgotPass;

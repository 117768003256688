import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBuyers = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 19" {...props}>
    <path
      fill="currentColor"
      d="M13.195 8.717a3.68 3.68 0 0 0-2.859 1.36l-1.128-.376-.039-.01c3.424-1.608 2.354-6.735-1.48-6.792-3.95.146-4.808 5.126-1.518 6.802l-2.982.991a3.03 3.03 0 0 0-2.074 2.88v1.423a1.07 1.07 0 0 0 1.067 1.07h10.55q.23.03.463.032c4.892-.202 4.895-7.177 0-7.38m-8.502-2.26A3 3 0 0 1 7.69 3.463c3.974.165 3.973 5.828 0 5.992a3 3 0 0 1-2.996-2.996m-2.51 9.045a.506.506 0 0 1-.505-.507v-1.422a2.47 2.47 0 0 1 1.689-2.346l2.982-.991a4.22 4.22 0 0 1 2.68 0l.976.325c-1.01 1.61-.403 3.988 1.186 4.94zm11.012.032a3.13 3.13 0 0 1-3.127-3.127c.172-4.148 6.082-4.146 6.253 0a3.13 3.13 0 0 1-3.127 3.127"
    />
    <path
      fill="currentColor"
      d="M15.822 11.909a.28.28 0 0 0-.233-.123H12.29l-.342-1.15a.28.28 0 0 0-.27-.202h-.598a.28.28 0 0 0-.26.174.3.3 0 0 0-.021.108c.014.366.426.27.669.281.008.017.338 1.142.344 1.157l.525 1.76a.28.28 0 0 0 .27.2h2.292a.28.28 0 0 0 .263-.179l.69-1.765a.28.28 0 0 0-.03-.261m-1.115 1.642h-1.89l-.359-1.202h2.719zM12.62 14.186a.282.282 0 0 0 0 .563.282.282 0 0 0 0-.563M14.799 14.186a.282.282 0 0 0 0 .563.282.282 0 0 0 0-.563"
    />
  </svg>
);
export default SvgBuyers;

/* eslint-disable no-console */
import { production } from 'app/constants/environments';

const logger = {
  log(...msg: unknown[]): void {
    if (!production) {
      console.log(...msg);
    }
  },

  error(...msg: unknown[]): void {
    if (!production) {
      console.error(...msg);
    }
  },

  warn(...msg: unknown[]): void {
    if (!production) {
      console.warn(...msg);
    }
  },
};

export default logger;

/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { Button, Drawer, Box, Divider, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { drawerBody } from './ActionsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { useListView } from '../../core/ListViewProvider';
import { useMutation, useQueryClient } from 'react-query';
import { deleteSelectedContacts } from '../../core/_requests';
import { QUERIES } from 'app/constants/consts';
import { useQueryResponse } from '../../core/QueryResponseProvider';
import { MoveToList } from '../moveToList/MoveToList';
const Actions = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenMoveDrawer, setIsOpenMoveDrawer] = useState(false);
  const { selected, clearSelected } = useListView();

  const { query } = useQueryResponse();
  const queryClient = useQueryClient();
  const deleteSelectedItems = useMutation(() => deleteSelectedContacts(selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_LIST}-${query}`]);
      clearSelected();
    },
  });

  return (
    <>
      <Button size="large" startIcon={<MenuIcon />} onClick={() => setIsOpenDrawer(true)}>
        Actions
      </Button>
      <MoveToList isOpenDrawer={isOpenMoveDrawer} setIsOpenDrawer={setIsOpenMoveDrawer} />
      <Drawer anchor="right" variant="temporary" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
        <Box sx={drawerBody}>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h2 className="fs-3 fw-bold text-black">Actions</h2>
            <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={() => setIsOpenDrawer(false)}>
              <ClearIcon />
            </Button>
          </div>
          <div className="row">
            <div className="col-12 mb-5 mt-5">
              <Button
                variant="text"
                size="large"
                className="text-black fw-bold text-start p-0 w-100 d-block mb-5 fs-5"
                onClick={() => setIsOpenMoveDrawer(true)}
              >
                Move to list
              </Button>
              <Divider component="div" />
            </div>
            <div className="col-12 mb-5">
              <Button variant="text" size="large" className="text-black fw-bold text-start p-0 w-100 d-block mb-5 fs-5">
                Export
              </Button>
              <Divider component="div" />
            </div>
            <div className="col-12 mb-5">
              <Button variant="text" size="large" className="text-black fw-bold text-start p-0 w-100 d-block mb-5 fs-5">
                Add Contacts with CSV
              </Button>
              <Divider component="div" />
            </div>
            <div className="col-12 mb-5">
              <Button
                variant="text"
                size="large"
                className="text-black fw-bold text-start p-0 w-100 d-flex justify-content-start mb-5 fs-5"
                onClick={async () => {
                  await deleteSelectedItems.mutateAsync();
                  setIsOpenDrawer(false);
                }}
                disabled={deleteSelectedItems.isLoading}
                startIcon={deleteSelectedItems.isLoading && <CircularProgress size={20} />}
              >
                Delete Contact
              </Button>
              <Divider component="div" />
            </div>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export { Actions };

/* eslint-disable curly */
/* eslint-disable padding-line-between-statements */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, createContext } from 'react';
import { useQuery } from 'react-query';
import { WithChildren } from 'app/constants/models';
import { initialQueryResponse, Reminder, QueryResponseContextProps } from './_models';
import { QUERIES } from 'app/constants/consts';
import { getReminders } from './_requests';
import { useQueryRequest } from './QueryRequestProvider';
import logger from 'app/utils/logger';

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState);
}

const QueryResponseContext = createResponseContext<Reminder>(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();

  const {
    isFetching,
    refetch,
    data: response,
    error,
  } = useQuery(
    `${QUERIES.REMINDER_LIST}-${state.Status}`,
    () => {
      return getReminders(state.Status);
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, error }}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponseError = () => {
  const { error } = useQueryResponse();
  if (!error) return;
  return error;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseError,
  useQueryResponseLoading,
};

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const PhoneIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: '20px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M19.4282 2.07826L19.3951 2.05762L15.2186 0L10.7107 6.0105L12.7847 8.77597C12.7226 9.8189 12.2803 10.8029 11.5416 11.5416C10.8028 12.2804 9.8188 12.7227 8.77587 12.7847L6.01045 10.7107L0 15.2185L2.04049 19.3602L2.05767 19.3951L2.07836 19.4281C2.18725 19.6036 2.33923 19.7482 2.51984 19.8483C2.70046 19.9484 2.90368 20.0006 3.11018 20H4.18787C6.26435 20 8.3205 19.591 10.2389 18.7964C12.1573 18.0017 13.9005 16.837 15.3687 15.3687C16.837 13.9004 18.0017 12.1573 18.7964 10.2389C19.591 8.32046 20 6.2643 20 4.18782V3.11008C20.0007 2.90359 19.9485 2.70036 19.8484 2.51975C19.7483 2.33914 19.6036 2.18715 19.4282 2.07826ZM18.4566 4.18782C18.4566 12.0556 12.0556 18.4566 4.18787 18.4566H3.31584L1.95059 15.685L6.01074 12.6399L8.27122 14.3352H8.52845C10.0681 14.3335 11.5441 13.7211 12.6328 12.6325C13.7215 11.5438 14.3338 10.0677 14.3356 8.52811V8.27089L12.6403 6.01046L15.685 1.95054L18.4566 3.31603V4.18782Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default PhoneIcon;

import { object, string } from 'yup';

export const schema = object({
  email: string()
    .email('Email must be a valid email')
    .required('Email is required')
    .matches(
      /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
      'Email must be a valid email'
    ),
});

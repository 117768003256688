/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { sendMessage } from './core/_requests';
import { validationScheme } from './core/_schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Contact } from './core/_models';
import { Box, Button, TextField, CircularProgress, Alert, Snackbar } from '@mui/material';
import CustomInput from 'app/components/CustomInput';
import FieldError from 'app/components/FieldError';

const ContactUs = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { firstName: '', lastName: '', emailAddress: '', subject: '', message: '' },
    resolver: yupResolver(validationScheme),
  });

  const { isLoading, isSuccess, isError, error, mutate } = useMutation(
    ['contactUs'],
    (body: Contact) => sendMessage(body),
    {
      onSuccess: () => {
        reset();
        setIsSnackbarOpen(true);
      },
      onError: () => {
        setIsSnackbarOpen(true);
      },
    }
  );

  const onSubmit = handleSubmit((body: Contact) => mutate(body));

  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ display: 'flex', gap: '18px', mb: '10px' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setIsSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setIsSnackbarOpen(false)}
            severity={isSuccess ? 'success' : 'error'}
            sx={{ width: '100%' }}
          >
            {isError ? (error as any)?.message : 'Your message send successlufy!'}
          </Alert>
        </Snackbar>
        <CustomInput
          id={'firstName'}
          label={'Name'}
          type={'text'}
          rest={{
            ...register('firstName', {
              value: getValues('firstName'),
            }),
          }}
          error={errors.firstName}
          disabled={isLoading}
        />
        <CustomInput
          id={'lastName'}
          label={'Last Name'}
          type={'text'}
          rest={{
            ...register('lastName', {
              value: getValues('lastName'),
            }),
          }}
          error={errors.lastName}
          disabled={isLoading}
        />
      </Box>
      <Box sx={{ mb: '10px' }}>
        <CustomInput
          id={'subject'}
          label={'Subject'}
          type={'text'}
          rest={{
            ...register('subject', {
              value: getValues('subject'),
            }),
          }}
          error={errors.subject}
          disabled={isLoading}
        />
      </Box>
      <Box sx={{ mb: '10px' }}>
        <CustomInput
          id={'emailAddress'}
          label={'Email Address'}
          type={'text'}
          rest={{
            ...register('emailAddress', {
              value: getValues('emailAddress'),
            }),
          }}
          error={errors.emailAddress}
          disabled={isLoading}
        />
      </Box>
      <Controller
        control={control}
        name="message"
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <TextField
              multiline={true}
              id="message"
              type={'text'}
              label={'Message'}
              value={value}
              error={errors.message !== undefined}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isLoading}
              sx={{
                width: '100%',
                height: '100px',

                '& label': { lineHeight: '1.5' },
                '& .MuiInputBase-root ': {
                  height: '94px !important',
                  '& textarea': {
                    height: '94px !important',
                    padding: '12px 0',
                  },
                },
              }}
            />
            <FieldError error={errors.message} />
          </>
        )}
      />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          size="large"
          sx={{
            width: '360px',
            fontSize: '18px',
            lineHeight: '26px',
            '@media screen and (max-width: 992px)': { width: '100%' },
          }}
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={20} />}
        >
          Send Message
        </Button>
      </Box>
    </form>
  );
};

export { ContactUs };

import { CSSProperties } from 'react';
import { blue, white } from 'app/components/ThemeProvider/colors';
import { SxProps } from '@mui/material';

const linkStyle: CSSProperties = {
  fontSize: '16px',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  padding: '13px 16px',
  color: white,
  fontWeight: 500,
  height: 'fit-content',
  justifyContent: 'center',
};

export const style: Record<string, CSSProperties> = {
  link: {
    ...linkStyle,
  },

  activeLink: {
    ...linkStyle,
    backgroundColor: 'rgba(255, 255, 255, .3)',
    borderRadius: '30px',
  },
};

export const headerBox: SxProps = {
  display: 'flex',
  width: '90%',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '108px',
  pt: '46px',
  margin: '0 auto',

  '@media screen and (max-width: 1100px)': {
    display: 'none',
  },

  '@media screen and (min-width: 1300px)': { width: '1274px' },
};

export const registerBtn: SxProps = {
  background: 'transparent',
  border: `1px solid ${white}`,
  fontSize: '16px',
  fontWeight: 500,
  p: '12px 30px',

  '&:hover': {
    background: 'transparent',
  },

  '@media screen and (max-width: 576px)': {
    width: '100%',
  },
};

export const loginBtn: SxProps = {
  color: blue,
  background: white,
  display: 'flex',
  gap: '10px',
  fontSize: '16px',
  fontWeight: 500,
  p: '12px 30px',

  '&:hover': {
    background: white,
  },

  '@media screen and (max-width: 576px)': {
    width: '100%',
  },
};

export const mobileHeader: SxProps = {
  display: 'none',
  width: '90%',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '120px',
  pt: '60px',
  margin: '0 auto',

  '@media screen and (max-width: 1100px)': {
    display: 'flex',
    alignItems: 'center',
  },
};

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const UrexLogoDark = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 84 20"
      sx={{
        width: '84px !important',
        height: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M15.8621 0.265252C15.5791 1.29089 15.4377 2.5641 15.4377 4.08488V15.8621C15.4377 17.2414 15.5791 18.5323 15.8621 19.7347H12.4668C12.5729 19.3457 12.6437 18.9744 12.679 18.6207C12.7144 18.2317 12.7675 17.8426 12.8382 17.4536C11.2467 19.1512 9.4076 20 7.32095 20C5.26967 20 3.58974 19.2573 2.28117 17.7719C0.972591 16.2511 0.318302 14.1291 0.318302 11.4058V4.08488C0.318302 2.84704 0.212202 1.57383 0 0.265252H3.34218C3.05924 1.53846 2.91777 2.81167 2.91777 4.08488V11.4058C2.91777 13.3864 3.23607 14.8541 3.87268 15.809C4.79222 17.2944 6.1008 18.0371 7.79841 18.0371C9.49602 18.0371 10.84 17.3652 11.8302 16.0212C12.5376 14.9602 12.8912 13.4218 12.8912 11.4058V4.08488C12.8912 2.81167 12.7498 1.53846 12.4668 0.265252H15.8621Z"
        fill="#21364B"
      />
      <path
        d="M23.2817 1.85676C23.1756 3.02387 23.1225 3.76658 23.1225 4.08488V10.2387H24.661C25.9342 10.2387 26.9421 9.86737 27.6848 9.12467C28.4629 8.3466 28.852 7.33864 28.852 6.1008C28.852 4.86295 28.4983 3.855 27.7909 3.07692C26.9068 2.26348 25.8635 1.85676 24.661 1.85676H23.2817ZM25.6159 11.8302L24.661 11.8833H23.1225V15.8621C23.1225 17.2414 23.264 18.5323 23.5469 19.7347H20.2047C20.4169 18.4615 20.523 17.1706 20.523 15.8621V4.08488C20.523 2.84704 20.4169 1.57383 20.2047 0.265252H24.661C26.783 0.265252 28.4629 0.813439 29.7008 1.90981C31.0093 3.00619 31.6636 4.40318 31.6636 6.1008C31.6636 7.76304 31.0093 9.14235 29.7008 10.2387C29.4532 10.4156 29.2056 10.5924 28.9581 10.7692C28.7105 10.9461 28.4452 11.0875 28.1623 11.1936L31.8228 16.7109C32.5655 17.8072 33.3789 18.8152 34.2631 19.7347H30.3904C29.7184 18.1786 29.0995 17.0292 28.5337 16.2865L25.6159 11.8302Z"
        fill="#21364B"
      />
      <path
        d="M39.4029 15.8621C39.4029 16.1096 39.4559 16.87 39.562 18.1432H43.0103C44.425 18.1432 45.7159 18.0195 46.883 17.7719V19.7347H36.4851C36.6973 18.4615 36.8034 17.1706 36.8034 15.8621V4.08488C36.8034 2.84704 36.6973 1.57383 36.4851 0.265252H45.3445V2.22812C43.8591 1.98055 42.5682 1.85676 41.4718 1.85676H39.562C39.5267 2.2458 39.4913 2.63484 39.4559 3.02387C39.4206 3.41291 39.4029 3.76658 39.4029 4.08488V9.17772H41.4718C42.356 9.17772 43.6469 9.03625 45.3445 8.75332V11.1406C44.2481 10.893 42.9573 10.7692 41.4718 10.7692H39.4029V15.8621Z"
        fill="#21364B"
      />
      <path
        d="M48.6977 19.7347C49.4757 19.0274 50.3246 18.0371 51.2441 16.7639L55.8595 10.2918L51.138 3.28912C50.466 2.33422 49.6526 1.32626 48.6977 0.265252H52.6234C52.9771 1.36163 53.5606 2.51105 54.3741 3.71353L57.3979 8.11671L60.581 3.66048C61.2883 2.6702 61.9249 1.53846 62.4908 0.265252H65.5147C64.5597 1.18479 63.7109 2.15738 62.9682 3.18302L58.3528 9.65517L63.0743 16.7109C63.5695 17.4889 64.3829 18.4969 65.5147 19.7347H61.5889C61.1999 18.7445 60.6163 17.595 59.8383 16.2865L56.8144 11.8302L53.6314 16.3395C52.8533 17.3652 52.199 18.4969 51.6685 19.7347H48.6977Z"
        fill="#21364B"
      />
      <path
        d="M82.7657 5.41114C81.2449 2.86472 79.3881 1.59151 77.1954 1.59151C75.038 1.59151 73.3227 2.458 72.0495 4.19098C70.7763 5.92396 70.1397 7.86914 70.1397 10.0265C70.1397 12.1839 70.7409 14.076 71.9434 15.7029C73.3227 17.4713 75.0203 18.3554 77.0362 18.3554C79.6534 18.3554 81.8815 17.0292 83.7206 14.3767H83.8797V17.0822C81.9345 19.0274 79.6357 20 76.9832 20C74.3307 20 72.0495 19.0451 70.1397 17.1353C68.2652 15.1901 67.328 12.8205 67.328 10.0265C67.328 7.23254 68.2652 4.86295 70.1397 2.91777C72.0141 0.972591 74.2069 0 76.7179 0C79.229 0 81.2803 0.671972 82.8718 2.01591V5.41114H82.7657Z"
        fill="#21364B"
      />
    </SvgIcon>
  );
};

export default UrexLogoDark;

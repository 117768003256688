import { httpClient } from 'app/api/http/http-client';
import { useQuery } from 'react-query';
import { IAllProperties } from './types';

const propertyService = {
  getAllProperties: (id: number): Promise<any> => {
    return  httpClient.get<IAllProperties>(`/PropertyDetails/all/${id}`);
  },
};

export const useGetAllProperties = (id: number) => {
  const {
    isLoading: isLoadingAllProperties,
    data: dataAllProperties,
    isFetching: isFetchedAllProperties,
    isError: isErrorAllProperties,
  } = useQuery(['getAllProperties'], () => propertyService.getAllProperties(id));

  return { isLoadingAllProperties, isFetchedAllProperties, dataAllProperties,isErrorAllProperties };
};

import { httpClient } from 'app/api/http/http-client';
import { useMutation, useQuery } from 'react-query';

const addListingService = {
  draft: (data: any) => {
    return httpClient.post(`/${data.path}/Draft`, data.data, { headers: data.headers });
  },

  continue: (data: any) => {
    return httpClient.post(`/${data.path}/Continue`, data.data, { headers: data.headers });
  },

  getData: (path: string, id: string, headers: any) => {
    return httpClient.get(`/${path}/${id}`, { headers });
  },

  deleteFileFromBack:  (data: any) => {
    return httpClient.delete(`/Property/${data.id}/image/${data.path}`, { headers: data.headers });
  },

  publish: (data: any) => {
    return  httpClient.post(data.url, data.data, { headers: data.headers });
  },
};

export const useAddListeningContinue = () => {
  const {
    isLoading: isLoadingContinue,
    isSuccess: isSuccessContinue,
    data: dataContinue,
    mutate: continueMutation,
    error: contiuneError,
  } = useMutation(['addListeningContinue'], (data: any) => addListingService.continue(data));

  return {
    isLoadingContinue,
    isSuccessContinue,
    dataContinue,
    contiuneError,
    continueMutation,
  };
};

export const useAddListingDraft = () => {
  const {
    isLoading: isLoadingDraft,
    isSuccess: isSuccessDraft,
    data: dataDraft,
    mutate: draftMutation,
    error: draftError,
  } = useMutation(['addListingDraft'], (data: any) => addListingService.draft(data));

  return {
    isLoadingDraft,
    isSuccessDraft,
    dataDraft,
    draftError,
    draftMutation,
  };
};

export const useGetAddListingData = (path: string, id: string, headers: any) => {
  const {
    isLoading: isLoadingGetAddListingData,
    data: dataGetAddListingData,
    refetch: refetchGetAllListingData,
    error: dataGetAddListingError,
  } = useQuery(['getAddListingData'], () => addListingService.getData(path, id, headers), {
    enabled: id !== null && typeof id === 'string',
  });

  return { isLoadingGetAddListingData, dataGetAddListingData, dataGetAddListingError, refetchGetAllListingData };
};

export const useDeleteFileFromBack = () => {
  const {
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    data: dataDelete,
    mutate: deleteMutation,
    error: fileFromBackError,
  } = useMutation(['addListingDraft'], (data: any) => addListingService.deleteFileFromBack(data));

  return {
    isLoadingDelete,
    isSuccessDelete,
    dataDelete,
    fileFromBackError,
    deleteMutation,
  };
};

export const usePublish = () => {
  const {
    isSuccess: isSuccessPublish,
    data: publishData,
    mutate: publishMutate,
    error: publishError,
  } = useMutation(['publish'], (data: any) => addListingService.publish(data));

  return {
    isSuccessPublish,
    publishData,
    publishMutate,
    publishError,
  };
};

import React, { memo, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { wrapper } from 'app/components/FieldError/FieldError.sx';

interface FormError {
  message?: string;
}

interface FieldErrorsProps {
  error: FormError | undefined;
}

const FieldError = memo(({ error }: FieldErrorsProps): ReactElement => {
  return <>{error !== undefined && <Typography sx={wrapper}>{error.message}</Typography>}</>;
});

export default FieldError;

import { SxProps } from '@mui/material';
import { blue, gray8, white } from 'app/components/ThemeProvider/colors';

export const loaderBox: SxProps = {
  width: 'calc(100vw - 299px)',
  height: 'calc(100vh - 98px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const container: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',

  '@media screen and (max-width: 992px)': {
    mt: '60px',
    pr: '20px',
    pl: '20px',
  },
};

export const sectionBox: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '100%',
};

export const rowContainer: SxProps = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',

  '@media screen and (max-width: 500px)': {
    flexDirection: 'column',
    gap: 0,
  },
};

export const halfRowContainer: SxProps = {
  width: 'calc(50% - 15px)',

  '@media screen and (max-width: 500px)': {
    width: '100%',
  },
};

export const btnSectionBox: SxProps = {
  display: 'flex',
  gap: '20px',
  justifyContent: 'flex-end',
  mb: '15px',

  '@media screen and (max-width: 500px)': {
    flexDirection: 'column',
  },
};

export const saveBtn: SxProps = {
  width: '213px',
  height: '48px',
  color: white,
  background: gray8,

  '@media screen and (max-width: 500px)': {
    width: '100%',
  },
};

export const draftBtn: SxProps = {
  width: '213px',
  height: '48px',
  color: white,

  '@media screen and (max-width: 500px)': {
    width: '100%',
  },
};

export const radioBtn: SxProps = {
  border: '1px solid #878787',
  padding: '16px',
  borderRadius: '10px',
  ml: 0,
  mr: 0,
  width: 'fit-content',
  gap: '10px',

  '& span': {
    padding: 0,
    color: '#878787 !important',
  },
};

export const checkedRadioBtn: SxProps = {
  background: blue,
  border: `1px solid ${blue} `,
  padding: '16px',
  borderRadius: '10px',
  ml: 0,
  mr: 0,
  width: 'fit-content',
  gap: '10px',

  '& span': {
    padding: 0,
    color: white,
  },

  '& .Mui-disabled': { color: `${white} !important` },
};

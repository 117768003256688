/* eslint-disable curly */
import { useGetAllProperties } from 'app/utils/services/propertyService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useAddListingDraft,
  useGetAddListingData,
  useAddListeningContinue,
} from 'app/utils/services/addListingService';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'app/pages/AddListing/InvestmentDetails/schema';

const useInvestmentDetails = () => {
  const location = useLocation();
  const role = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const sessionId = sessionStorage.getItem(role === 'commercial' ? 'commercialId' : 'residentialId');

  const [openDesc, setOpenDesc] = useState(true);
  const [openCondition, setOpenCondition] = useState(true);
  const isMobile = useMediaQuery('(max-width: 992px)');
  const isMobilePhone = useMediaQuery('(max-width: 500px)');

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { isLoadingGetAddListingData, dataGetAddListingData, dataGetAddListingError } = useGetAddListingData(
    role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
    sessionId,
    {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  );
  const { isLoadingAllProperties, dataAllProperties } = useGetAllProperties(
    role === 'residential' ? 2 : role === 'commercial' && 1
  );

  const { isLoadingDraft, isSuccessDraft, dataDraft, draftMutation } = useAddListingDraft();
  const { isLoadingContinue, isSuccessContinue, dataContinue, continueMutation } = useAddListeningContinue();

  useEffect(() => {
    if (!isMobile && !openDesc) {
      setOpenDesc(true);
    }
    if (!isMobile && !openCondition) {
      setOpenCondition(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (
      sessionId !== null &&
      dataGetAddListingData?.investment !== null &&
      !isLoadingAllProperties &&
      !isLoadingGetAddListingData
    ) {
      setValue('investmentDesc', dataGetAddListingData?.investment.description || '');
      setValue('afterRepairValue', dataGetAddListingData?.investment.afterRepairValue || '');
      setValue('basementCondition', dataGetAddListingData?.investment.basementCondition || '');
      setValue('constructionCondition', dataGetAddListingData?.investment.constructionCondition || '');
      setValue('coolingCondition', dataGetAddListingData?.investment.coolingCondition || '');
      setValue('estimatedProfit', dataGetAddListingData?.investment.estimatedProfit || '');
      setValue('estimatedRepairsCost', dataGetAddListingData?.investment.estimatedRepairsCost || '');
      setValue('estimatedTimeForRepairs', dataGetAddListingData?.investment.estimatedTimeForRepairs || '');
      setValue('foundationCondition', dataGetAddListingData?.investment.foundationCondition || '');
      setValue('heatingCondition', dataGetAddListingData?.investment.heatingCondition || '');
      setValue('rentalIncomePotentialMax', dataGetAddListingData?.investment.rentalIncomePotentialMax || '');
      setValue('rentalIncomePotentialMin', dataGetAddListingData?.investment.rentalIncomePotentialMin || '');
      setValue('roofCondition', dataGetAddListingData?.investment.roofCondition || '');
      setValue('investmentType', dataGetAddListingData?.investment.types || []);
      setValue('noi', dataGetAddListingData?.investment.noi || '');
      setValue('capRate', dataGetAddListingData?.investment.capRate || '');
      setValue('income', dataGetAddListingData?.investment.income || '');
      setValue('expenses', dataGetAddListingData?.investment.expenses || '');
      setValue('noiProForma', dataGetAddListingData?.investment.proFormaNOI || '');
      setValue('capRateProForma', dataGetAddListingData?.investment.proFormaCapRate || '');
      setValue('incomeProForma', dataGetAddListingData?.investment.proFormaIncome || '');
      setValue('expensesProForma', dataGetAddListingData?.investment.proFormaExpenses || '');
    }

    if ((dataGetAddListingError as any)?.status === 404) {
      sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
      if (window !== undefined) window.location.reload();
    }
  }, [dataGetAddListingData, isLoadingAllProperties, isLoadingGetAddListingData]);

  useEffect(() => {
    if (
      (isSuccessDraft && dataDraft !== undefined && !dataDraft.message) ||
      (isSuccessContinue && dataContinue !== undefined && !dataContinue.message)
    ) {
      sessionStorage.setItem(
        role === 'commercial' ? 'commercialId' : 'residentialId',
        dataDraft ? String(dataDraft) : String(dataContinue)
      );
      navigate(`/add-listing/${role}/gallery`);
    }
  }, [dataDraft, dataContinue]);

  const handleClick = (set: any, value: boolean) => {
    if (isMobile) {
      set(!value);
    }
  };

  const submitForm = (data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isDraft = element?.dataset?.isdraft === 'true';
    const residentialFormData = new FormData();
    residentialFormData.append('Investment.Description', data.investmentDesc);
    residentialFormData.append('Investment.AfterRepairValue', data.afterRepairValue);
    residentialFormData.append('Investment.EstimatedRepairsCost', data.estimatedRepairsCost);
    residentialFormData.append('Investment.EstimatedTimeForRepairs', data.estimatedTimeForRepairs);
    residentialFormData.append('Investment.EstimatedProfit', data.estimatedProfit);
    residentialFormData.append('Investment.RentalIncomePotentialMin', data.rentalIncomePotentialMin);
    residentialFormData.append('Investment.RentalIncomePotentialMax', data.rentalIncomePotentialMax);
    residentialFormData.append('Investment.RoofCondition', data.roofCondition);
    residentialFormData.append('Investment.CoolingCondition', data.coolingCondition);
    residentialFormData.append('Investment.FoundationCondition', data.foundationCondition);
    residentialFormData.append('Investment.HeatingCondition', data.heatingCondition);
    residentialFormData.append('Investment.ConstructionCondition', data.constructionCondition);
    residentialFormData.append('Investment.BasementCondition', data.basementCondition);

    if (getValues('investmentType') !== undefined) {
      for (const row of getValues('investmentType')) {
        if (row.id !== undefined) {
          residentialFormData.append('Investment.Types', row.id);
        } else {
          residentialFormData.append('Investment.Types', row);
        }
      }
    }
    residentialFormData.append('Id', sessionId === null ? '' : sessionId);

    const commercialFormData = new FormData();
    commercialFormData.append('Investment.Description', data.investmentDesc);
    commercialFormData.append('Investment.NOI', data.noi);
    commercialFormData.append('Investment.CapRate', data.capRate);
    commercialFormData.append('Investment.Income', data.income);
    commercialFormData.append('Investment.Expenses', data.expenses);
    commercialFormData.append('Investment.ProFormaNOI', data.noiProForma);
    commercialFormData.append('Investment.ProFormaCapRate', data.capRateProForma);
    commercialFormData.append('Investment.ProFormaIncome', data.incomeProForma);
    commercialFormData.append('Investment.ProFormaExpenses', data.expensesProForma);
    commercialFormData.append('Investment.CoolingCondition', data.coolingCondition);
    commercialFormData.append('Investment.FoundationCondition', data.foundationCondition);
    commercialFormData.append('Investment.HeatingCondition', data.heatingCondition);
    commercialFormData.append('Investment.ConstructionCondition', data.constructionCondition);
    commercialFormData.append('Investment.BasementCondition', data.basementCondition);
    commercialFormData.append('Investment.RoofCondition', data.roofCondition);
    if (getValues('investmentType') !== undefined) {
      for (const row of getValues('investmentType')) {
        if (row.id !== undefined) {
          commercialFormData.append('Investment.Types', row.id);
        } else {
          commercialFormData.append('Investment.Types', row);
        }
      }
    }
    commercialFormData.append('Id', sessionId === null ? '' : sessionId);

    if (isDraft) {
      draftMutation({
        data: role === 'commercial' ? commercialFormData : role === 'residential' && residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      continueMutation({
        data: role === 'commercial' ? commercialFormData : role === 'residential' && residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  const onSubmit = handleSubmit(submitForm);

  return {
    isLoadingContinue,
    isLoadingDraft,
    isLoadingAllProperties,
    dataAllProperties,
    control,
    register,
    role,
    handleClick,
    openDesc,
    setOpenDesc,
    openCondition,
    setOpenCondition,
    isMobile,
    isMobilePhone,
    onSubmit,
    getValues,
    errors,
  };
};

export default useInvestmentDetails;

import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { black, blue, gray3, white } from 'app/components/ThemeProvider/colors';

interface Props {
  value: string;
  changeValue?: (newValue: string) => void;
  disabled?: boolean;
}

const CustomSwitcherCheckbox = (props: Props) => {
  const { value, changeValue, disabled } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        width: '104px',
        height: '38px',
      }}
    >
      <Box
        sx={
          value === 'SF'
            ? {
                backgroundColor: blue,
                color: white,
                width: '100%',
                borderRadius: '10px 0 0 10px',
                border: `1px solid ${blue}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : {
                width: '100%',
                borderRadius: '10px 0 0 10px',
                border: `1px solid ${gray3}`,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
        }
        onClick={() => (disabled ? {} : changeValue('SF'))}
      >
        <Typography sx={value === 'SF' ? { color: white } : { color: black }}>SF</Typography>
      </Box>
      <Box
        sx={
          value === 'AC'
            ? {
                backgroundColor: blue,
                color: white,
                width: '100%',
                borderRadius: '0 10px 10px 0',
                border: `1px solid ${blue}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
            : {
                width: '100%',
                borderRadius: '0 10px 10px 0',
                border: `1px solid ${gray3}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
        }
        onClick={() => (disabled ? {} : changeValue('AC'))}
      >
        <Typography sx={value === 'AC' ? { color: white } : { color: black }}>AC</Typography>
      </Box>
    </Box>
  );
};

export default CustomSwitcherCheckbox;

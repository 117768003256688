import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <g clipPath="url(#analytics_svg__a)">
      <mask
        id="analytics_svg__b"
        width={18}
        height={19}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="currentColor" d="M18 .751H0v18h18z" />
      </mask>
      <g fill="currentColor" mask="url(#analytics_svg__b)">
        <path d="M2.7 13.652H.9a.9.9 0 0 0-.9.9v3.9a.3.3 0 0 0 .3.3h3a.3.3 0 0 0 .3-.3v-3.9a.9.9 0 0 0-.9-.9m.3 4.5H.6v-3.6a.3.3 0 0 1 .3-.3h1.8a.3.3 0 0 1 .3.3zM7.498 9.452h-1.8a.9.9 0 0 0-.9.9v8.099a.3.3 0 0 0 .3.3h3a.3.3 0 0 0 .3-.3v-8.099a.9.9 0 0 0-.9-.9m.3 8.699h-2.4v-7.799a.3.3 0 0 1 .3-.3h1.8a.3.3 0 0 1 .3.3zM12.3 11.252h-1.8a.9.9 0 0 0-.9.9v6.3a.3.3 0 0 0 .3.299h3a.3.3 0 0 0 .3-.3v-6.3a.9.9 0 0 0-.9-.899m.3 6.9h-2.4v-6a.3.3 0 0 1 .3-.3h1.8a.3.3 0 0 1 .3.3zM17.098 8.853h-1.8a.9.9 0 0 0-.9.9v8.698a.3.3 0 0 0 .3.3h3a.3.3 0 0 0 .3-.3V9.752a.9.9 0 0 0-.9-.9m.3 9.298h-2.4V9.752a.3.3 0 0 1 .3-.3h1.8a.3.3 0 0 1 .3.3zM14.854 4.347a1.799 1.799 0 1 0-.32-.508l-1.792 1.12a1.79 1.79 0 0 0-2.581-.11L8.197 3.377a1.8 1.8 0 1 0-2.97.338L2.712 6.704a1.8 1.8 0 1 0 .46.386l2.514-2.988c.691.41 1.57.31 2.15-.246L9.8 5.33a1.8 1.8 0 1 0 3.262.138zm1.344-2.394a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4M1.8 9.453a1.2 1.2 0 1 1 0-2.4 1.2 1.2 0 0 1 0 2.4m4.8-5.7a1.2 1.2 0 1 1 0-2.4 1.2 1.2 0 0 1 0 2.4m4.798 3.6a1.2 1.2 0 1 1 0-2.4 1.2 1.2 0 0 1 0 2.4" />
      </g>
    </g>
    <defs>
      <clipPath id="analytics_svg__a">
        <path fill="currentColor" d="M0 .251h18v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAnalytics;

/* eslint-disable curly */
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { QUERIES } from 'app/constants/consts';
import { contactSchema } from './_schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { Contact, ContactList } from './_models';
import { createContact, getContactById, updateContact, getContactList, getTags } from './_requests';
import { useQueryResponse } from './QueryResponseProvider';
import { useContactListQueryResponse } from './ContactListQueryResponse';
import { isNotEmpty } from 'app/constants/helpers';
import logger from 'app/utils/logger';

const useContact = (tools: { [key: string]: any }) => {
  const { refetch } = useQueryResponse();
  const contactListData = useContactListQueryResponse();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactSchema) });

  const grId = (getValues('groupId') as { id: number })?.id;
  useQuery(`${QUERIES.CONTACT_GROUP_LIST}-tags-${grId}`, () => getTags(isNotEmpty(grId) ? [grId] : []), {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: isNotEmpty(grId),
    onSuccess: (data) => {
      const tags = data?.map((item: string) => ({ value: item, label: item }));
      setValue('tags', tags);
    },
  });

  const enabledQuery: boolean = isNotEmpty(tools.itemIdForUpdate);
  const getContactByIdQuery = useQuery(
    `${QUERIES.CONTACT_LIST}-contact-${tools.itemIdForUpdate}`,
    () => {
      return getContactById(tools.itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery && tools.itemIsUpdate,
      onSuccess: (data) => {
        if (tools.itemIsUpdate) {
          setValue('name', data.name || '');
          setValue('lastName', data.lastName || '');
          setValue('address', data.address || '');
          setValue('companyName', data.companyName || '');
          setValue('email', data.email || '');
          setValue('title', data.title || '');
          setValue('note', data.note || '');
          setValue('phoneNumber', data.phoneNumber || '');

          const tags = data.tags?.map((tag) => ({ label: tag.name, value: tag.name }));
          setValue('tags', tags);

          const findGroupItem = contactListData.response?.find((contact: ContactList) => contact.id === data.groupId);
          if (findGroupItem) setValue('groupId', findGroupItem);
          else setValue('groupId', undefined);
        }
      },
      onError: (err) => {
        logger.error(err);
      },
    }
  );

  const createContactMutation = useMutation((data: Contact) => createContact(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_LIST}-CREATE`]);
      setValue('note', '');
      reset();
      tools.onCloseCreate();
      tools.onOpenScankbar(true);
      refetch();
    },
  });

  const updateContactMutation = useMutation((data: Contact) => updateContact(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_LIST}-UPDATE`]);
      setValue('note', '');
      reset();
      tools.onCloseCreate();
      tools.onOpenScankbar(true);
      refetch();
      getContactByIdQuery.refetch();
    },
  });

  const onSubmit = handleSubmit((data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isEdit = element.dataset.isedit;
    const groupId = data.groupId.id;
    const tags = data?.tags?.map((tag: { label: string }) => tag.label);
    if (isEdit === 'true') updateContactMutation.mutate({ ...data, groupId, id: tools.itemIdForUpdate, newTags: tags });
    else createContactMutation.mutate({ ...data, groupId, tags });
  });

  const loadContactOptions = async (searchQuery: any, loadedOptions: any, { page }: { page: number }) => {
    const response = (await getContactList()) as unknown as any[];

    return {
      options: response,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  return {
    control,
    register,
    getValues,
    errors,
    reset,
    onSubmit,
    createContactMutation,
    getContactByIdQuery,
    loadContactOptions,
  };
};

export { useContact };

import { object, string } from 'yup';

export const schema = object({
  email: string()
    .email('Email must be a valid email')
    .required('Email is required')
    .matches(
      /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
      'Email must be a valid email'
    ),
  lastName: string().required('Lastname is required'),
  name: string().required('Name is required'),
  phoneNumber: string().required('Phone number is required').min(14, 'Phone number must be a valid phone number'),
});

import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTextCamping = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="M15.11 2.454H2.875c-1.28 0-2.32 1.04-2.32 2.32v7.488a2.323 2.323 0 0 0 2.311 2.32v3.398l4.883-3.398h7.36c1.28 0 2.32-1.041 2.32-2.32V4.774c0-1.28-1.04-2.32-2.32-2.32m1.33 9.808c0 .734-.596 1.33-1.33 1.33H7.44l-3.585 2.495v-2.494h-.98a1.333 1.333 0 0 1-1.332-1.331V4.774c0-.734.598-1.331 1.332-1.331H15.11c.734 0 1.33.597 1.33 1.331z"
    />
    <path
      fill="currentColor"
      d="M5.07 5.948h7.842v.989H5.07zM5.07 8.057h7.842v.99H5.07zM5.07 10.167h7.842v.989H5.07z"
    />
  </svg>
);
export default SvgTextCamping;

import { SxProps } from '@mui/material';
import { white, blue } from 'app/components/ThemeProvider/colors';

export const rightSide: SxProps = {
  width: 'calc(100vw - 241px)',
  ml: '241px',
  padding: '30px',
  pl: 0,
  height: '100vh',
  backgroundColor: blue,

  '@media screen and (max-width: 992px)': {
    width: '100vw',
    ml: 0,
    pl: 0,
    pr: 0,
  },
};

export const mobileHeader: SxProps = {
  display: 'none',

  '@media screen and (max-width: 992px)': {
    width: '100vw',
    height: 'fit-content',
    display: 'flex',
    position: 'fixed',
    top: '0',
    pt: '40px',
    pb: '20px',
    backgroundColor: white,
    zIndex: '10',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const content: SxProps = {
  width: '100%',
  height: 'calc(100% - 80px)',
  backgroundColor: white,
  borderRadius: '10px',
  overflowY: 'auto',
  padding: '15px',
  marginTop: '80px',
  '@media screen and (min-width: 992px)': {
    height: '100%',
    marginTop: 0,
    padding: '30px',
  },
};

import React from 'react';
import { styled } from '@mui/material/styles';
import { gray3, white } from 'app/components/ThemeProvider/colors';

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '6px',
  width: 30,
  height: 30,
  border: `1px solid ${gray3}`,
  boxShadow: 'none',
  backgroundColor: white,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#21364B',
  border: '1px solid #137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 30,
    height: 30,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

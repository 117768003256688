import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const HomePageMainArrowIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 68 68"
      sx={{
        height: '68px !important',
        width: '68px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M53.8333 9.91663L14.1666 49.5833"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.8333 39.015V9.91663H24.735"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default HomePageMainArrowIcon;

/* eslint-disable curly */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { ReactNode } from 'react';
import { Button, Drawer, Box, TextField, CircularProgress } from '@mui/material';
import { drawerBody, drawerTextArea } from './DetailsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { useListView } from '../../core/ListViewProvider';
import { useQuery } from 'react-query';
import { getMyPropertyById } from '../../core/_requests';
import { isNotEmpty } from 'app/constants/helpers';
import { QUERIES } from 'app/constants/consts';
import logger from 'app/utils/logger';
import { Setting4, User, Location, Building, TagRight, Map, SmsTracking } from 'app/components/SvgIcons';

const Details = () => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    setIsOpenDetails,
    isOpenDetails,
    setIsOpenCreateDrawer,
  } = useListView();

  const onClose = () => {
    setItemIdForUpdate(undefined);
    setIsOpenDetails(false);
  };

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const {
    isLoading,
    data: property,
    error,
  } = useQuery(
    `${QUERIES.MYPROPERTY_LIST}-property-${itemIdForUpdate}`,
    () => {
      return getMyPropertyById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        onClose();
        logger.error(err);
      },
    }
  );

  let renderDetails: ReactNode;
  if (!isLoading && !error && property)
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-end mb-4">
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onClose}>
            <ClearIcon />
          </Button>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <h3 className="fw-bold fs-5 text-black p-0 m-0 ms-2">{property?.typeName}</h3>
          </div>
          <div className="d-flex align-items-center">
            <Button
              variant="text"
              className="p-0 m-0 ms-1 text-end"
              startIcon={<Setting4 className="fs-5" />}
              onClick={() => {
                setItemIsUpdate(true);
                setIsOpenCreateDrawer(true);
              }}
            >
              Edit
            </Button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <TagRight className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Tag</span>
          </div>
          <span className="fs-6 text-black ps-1">Buyers</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <User className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Owner Name</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.ownerName}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <User className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Related Contact</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.myContactName}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Building className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Property Type</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.typeName}</span>
        </div>

        <div className="d-flex justify-content-between py-3">
          <div className="d-flex align-items-center col-6">
            <Location className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Address</span>
          </div>
          <span className="fs-6 text-black ps-2 col-6 text-end">{property?.address?.fullAddress}</span>
        </div>

        <div className="d-flex align-items-start justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Map className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">City</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.address?.city}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <SmsTracking className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">ZIP Code</span>
          </div>
          <span className="fs-6 text-black ps-1">{property?.address?.postalCode}</span>
        </div>

        <div className="w-100 my-3">
          <TextField
            multiline={true}
            sx={drawerTextArea}
            placeholder="Add a note"
            label="Note"
            disabled
            value={property?.note}
          />
        </div>
      </Box>
    );
  else
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress />
        </div>
      </Box>
    );

  return (
    <>
      <Drawer anchor="right" variant="temporary" open={isOpenDetails} onClose={onClose}>
        {renderDetails}
      </Drawer>
    </>
  );
};

export { Details };

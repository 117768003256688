import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ScrollToTop = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    if(window) {window.scrollTo(0, 0);}
  },[pathname]);

  return null;
};

export default ScrollToTop;
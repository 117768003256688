/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable curly */
import { useRef, useEffect, useState } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import CustomInput from '../CustomInput';


interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  id?: string;
  label?: string;
  endAdornment?: React.ReactElement;
  error?: any;
  rest?: {[key: string]: any};
}

const Autocomplete = ({id,label, endAdornment,error,rest,onPlaceSelect }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address', 'address_components'],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="autocomplete-container w-100">
      <CustomInput
        id={id}
        label={label ? label : "Search"}
        endAdornment={endAdornment}
        height="auto"
        inputProps={{ ref: inputRef }}
        error={error}
        rest={{...rest}} />
    </div>
  );
};

export { Autocomplete };

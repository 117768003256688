import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FbIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      sx={{
        height: '24px !important',
        width: '24px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <rect width="24" height="24" fill="#260A0A" fillOpacity="0.01" />
      <path
        d="M17.0103 13.3743L17.6215 9.393H13.8011V6.80937C13.8011 5.72038 14.3346 4.65819 16.0457 4.65819H17.7824V1.26881C17.7824 1.26881 16.2066 1 14.6996 1C11.5536 1 9.49731 2.90713 9.49731 6.35906V9.39369H6V13.375H9.49731V23H13.8011V13.375L17.0103 13.3743Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default FbIcon;

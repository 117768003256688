import { SxProps } from '@mui/material';

export const drawerBody: SxProps = {
  width: '100%',
  maxWidth: '100%',
  padding: '20px',

  '@media only screen and (min-width: 576px)': {
    padding: '60px',
  },

  '@media only screen and (min-width: 768px)': {
    maxWidth: '700px',
  },
};

export const drawerTextArea: SxProps = {
  width: '100%',
  '& .MuiInputBase-root ': {
    height: '95px !important',
    '& textarea': {
      height: '95px !important',
      padding: '12px 0',
    },
  },
};

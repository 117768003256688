import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const LoginProfileIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: '20px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M10.1334 9.05829C10.05 9.04996 9.95005 9.04996 9.85838 9.05829C7.87505 8.99163 6.30005 7.36663 6.30005 5.36663C6.30005 3.32496 7.95005 1.66663 10 1.66663C12.0417 1.66663 13.7 3.32496 13.7 5.36663C13.6917 7.36663 12.1167 8.99163 10.1334 9.05829Z"
        stroke="#21364B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9666 12.1334C3.94993 13.4834 3.94993 15.6834 5.9666 17.025C8.25827 18.5584 12.0166 18.5584 14.3083 17.025C16.3249 15.675 16.3249 13.475 14.3083 12.1334C12.0249 10.6084 8.2666 10.6084 5.9666 12.1334Z"
        stroke="#21364B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default LoginProfileIcon;

import React, { ChangeEvent } from 'react';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import FieldError from 'app/components/FieldError';
import { gray11, gray12, gray13, red } from 'app/components/ThemeProvider/colors';

interface Props {
  id?: string;
  label?: string;
  type?: string;
  error?: any;
  rest?: any;
  endAdornment?: any;
  value?: string;
  inputProps?: any;
  disabled?: boolean;
  pr?: any;
  onFocus?: () => void;
  styles?: { [key: string]: any };
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  height?: string;
}

const CustomInput = (props: Props) => {
  const {
    id,
    label,
    type,
    error,
    rest,
    endAdornment,
    value,
    inputProps,
    disabled,
    pr,
    onFocus,
    styles = {},
    onChange,
    height,
  } = props;

  return (
    <FormControl sx={{ height: height || '69px', width: '100%' }}>
      <InputLabel
        htmlFor={id}
        sx={
          error !== undefined
            ? { height: '22px', '&.MuiInputLabel-shrink': { color: red } }
            : { height: '22px', color: disabled ? gray11 : gray12 }
        }
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        label={label}
        placeholder={''}
        type={type}
        sx={{
          width: '100%',
          pr,
          ...styles,
          svg: {
            fill: !error ? gray13 : red,
          },
        }}
        onChange={onChange}
        {...rest}
        value={value}
        inputProps={inputProps}
        endAdornment={endAdornment}
        error={error !== undefined}
        disabled={disabled}
        onFocus={onFocus}
      />
      <FieldError error={error} />
    </FormControl>
  );
};

export default CustomInput;

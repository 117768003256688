import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

interface Fill {
  fill: string;
}

const GalleryIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  const sxStyle = sx as Fill;

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      sx={{
        height: '20px !important',
        width: '20px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <g clipPath="url(#clip0_1253_4762)">
        <path
          d="M10.3615 15.8144C9.77995 15.8054 9.21926 15.5963 8.77384 15.2224L5.84076 12.6391C5.37922 12.2079 4.67345 12.1737 4.1724 12.5584L0.00154866 15.626V17.6172C-0.0284275 18.1514 0.380371 18.6087 0.914533 18.6387C0.942037 18.6402 0.969644 18.6406 0.997199 18.6397H15.7702C16.3622 18.6397 16.9542 18.2092 16.9542 17.6172V12.3969L11.6531 15.4645C11.2634 15.6988 10.8162 15.8199 10.3615 15.8144Z"
          fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
        />
        <path
          d="M10.1462 10.5938C10.8001 10.5938 11.3302 10.0637 11.3302 9.40984C11.3302 8.75593 10.8001 8.22583 10.1462 8.22583C9.49226 8.22583 8.96216 8.75593 8.96216 9.40984C8.96216 10.0637 9.49226 10.5938 10.1462 10.5938Z"
          fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
        />
        <path
          d="M19.7527 3.46308C19.5809 3.22904 19.3156 3.08163 19.0262 3.05943L4.36078 1.36419C4.07488 1.34024 3.78954 1.41631 3.55354 1.57948C3.34453 1.76098 3.20243 2.00733 3.14989 2.27913L2.90771 4.37802H15.7702C16.9965 4.40584 17.9881 5.38557 18.0306 6.61145V16.1103C18.0306 16.0565 18.2727 16.0026 18.3804 15.895C18.6032 15.72 18.7324 15.4517 18.7302 15.1685L19.9949 4.24344C20.0228 3.96124 19.9355 3.67977 19.7527 3.46308Z"
          fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
        />
        <path
          d="M15.7701 5.45435H0.997115C0.405112 5.45435 0.00146484 6.01941 0.00146484 6.61141V14.2805L3.55343 11.6972C4.45508 11.0284 5.70244 11.0846 6.54033 11.8317L9.50029 14.415C9.94664 14.7939 10.5842 14.8479 11.0879 14.5495L16.954 11.1321V6.61141C16.9134 5.9797 16.4026 5.48056 15.7701 5.45435ZM10.1461 11.6703C8.89778 11.6703 7.88575 10.6583 7.88575 9.40991C7.88575 8.16157 8.89773 7.14954 10.1461 7.14954C11.3945 7.14954 12.4065 8.16152 12.4065 9.40991C12.4065 10.6583 11.3945 11.6703 10.1461 11.6703Z"
          fill={sxStyle !== undefined ? sxStyle.fill : '#B2B9C0'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1253_4762">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default GalleryIcon;

/* eslint-disable react/react-in-jsx-scope */
import { Route, Routes, Outlet } from 'react-router-dom';
import Registration from 'app/pages/Authorization/Registration';
import Login from 'app/pages/Authorization/Login';
import ForgotPass from 'app/pages/Authorization/ForgotPass';
import { ROUTES } from 'app/constants/routes';

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path={ROUTES.login} element={<Login />} />
        <Route path={ROUTES.registration} element={<Registration />} />
        <Route path={ROUTES.forgotPass} element={<ForgotPass />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
};

export { AuthPage };

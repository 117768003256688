/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { MyPropertyListButton } from './MyPropertyListButton';
import { useMyPropertyListQueryResponse } from '../../core/MyPropertyListQueryResponse';
import { ContactList as CL } from '../../core/_models';
import { useQueryRequest } from '../../core/QueryRequestProvider';
import { DeleteModal } from './DeleteModal';

const MyPropertyList = () => {
  const [itemId, setItemId] = useState<number | null>(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setItemId(null);
    setShow(false);
  };
  const handleShow = (id: number) => {
    setItemId(id);
    setShow(true);
  };

  const {
    state: { GroupId },
    updateState,
  } = useQueryRequest();
  const { response } = useMyPropertyListQueryResponse();
  const data = response as any;

  const updatedState = (groupId?: number) => updateState({ GroupId: groupId });

  return (
    <div className="d-flex flex-wrap gap-3 mt-4 mt-md-0">
      <MyPropertyListButton title="My Properties" updatedState={() => updatedState()} />
      {data?.map((item: CL) => (
        <MyPropertyListButton
          isEditableMode
          key={item.id}
          id={item.id}
          title={item.name}
          isActive={GroupId === item.id}
          updatedState={() => updatedState(item.id)}
          onDeleteShow={handleShow}
        />
      ))}
      <MyPropertyListButton isCreateMode />
      <DeleteModal show={show} handleClose={handleClose} id={itemId} />
    </div>
  );
};

export { MyPropertyList };

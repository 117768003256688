/* eslint-disable curly */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { FC, useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useContactListQueryResponse } from '../../core/ContactListQueryResponse';
import { QUERIES } from 'app/constants/consts';
import { updateContactList, createContactList } from '../../core/_requests';

type Props = {
  title?: string;
  itemIdforEdit?: number;
  setIsCreate?: (isCreate: boolean) => void;
};

const CreateForm: FC<Props> = ({ setIsCreate, itemIdforEdit, title }) => {
  const { refetch } = useContactListQueryResponse();
  const queryClient = useQueryClient();
  const [name, setName] = useState<string>(title || '');
  const [order] = useState<number>(0);

  const createMutation = useMutation(() => createContactList({ name, order }), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_GROUP_LIST}-CREATE`]);
      refetch();
      setIsCreate(false);
    },
  });

  const updateMutation = useMutation(() => updateContactList(itemIdforEdit, name), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.CONTACT_GROUP_LIST}-UPDATE`]);
      refetch();
      setIsCreate(false);
    },
  });

  return (
    <div className="d-flex align-items-center gap-2">
      <TextField label="Title" className="h-40px" value={name} onChange={(event) => setName(event.target.value)} />
      <Button
        size="small"
        onClick={async () => {
          if (itemIdforEdit) await updateMutation.mutateAsync();
          else await createMutation.mutateAsync();
        }}
        disabled={createMutation.isLoading || updateMutation.isLoading}
        startIcon={(createMutation.isLoading || updateMutation.isLoading) && <CircularProgress size={20} />}
      >
        Save
      </Button>
      <Button size="small" className="bg-warning text-black" onClick={() => setIsCreate(false)}>
        Cancel
      </Button>
    </div>
  );
};

export { CreateForm };

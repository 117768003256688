import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  TextField,
  Typography,
} from '@mui/material';
import { black2, blue, gray6, gray8, white } from 'app/components/ThemeProvider/colors';
import CustomInput from 'app/components/CustomInput';
import CustomSelect from 'app/components/CustomSelect';
import CustomSwitcherCheckbox from 'app/components/CustomSwitcherCheckbox';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { styled } from '@mui/material/styles';
import {
  halfRowContainer,
  rowContainer,
  sectionBox,
} from 'app/pages/AddListing/MarketingDescription/MarketingDescription.sx';
import { container } from 'app/pages/AddListing/PropertyInformation/PropertyInformation.sx';
import { checkedRadioBtn, radioBtn } from 'app/pages/AddListing/Confidentiality/Confidentiality.sx';
import usePreview from 'app/pages/AddListing/Preview/usePreview';
import FileIcon from 'app/components/Icons/FileIcon';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  backgroundColor: white,
  border: `2px solid ${gray6}`,
  padding: '0 !important',

  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19, 124, 189, .6)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: blue,
  border: `2px solid ${white}`,

  '&:before': {
    display: 'block',
    width: '16px',
    height: '16px',
    backgroundImage: 'radial-gradient(#fff, #fff, 28%, transparent 32%)',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  'input:hover ~ &': {
    backgroundColor: blue,
  },
});

const BpRadio = (props: RadioProps) => {
  return <Radio disableRipple checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />;
};

const Preview = () => {
  const {
    handleChangeSwitch,
    isMobile,
    register,
    isLoadingGetAddListingData,
    dataGetAddListingData,
    handleClick,
    openAddress,
    setOpenAddress,
    openInfo,
    openDetail,
    setOpenDetail,
    setOpenInfo,
    control,
    role,
    switchValue,
    getValues,
    dataAllProperties,
    openBrochure,
    setOpenBrochure,
    openDesc,
    setOpenDesc,
    openPrice,
    setOpenPrice,
    checked,
    closingResDate,
    isMobilePhone,
    fileFromBack,
    openDescInvest,
    setOpenDescInvest,
    setOpenCondition,
    openCondition,
    openVideo,
    setOpenVideo,
    openPhoto,
    setOpenPhoto,
    openAgreement,
    setOpenAgreement,
    setOpenConfigDocs,
    openConfigDocs,
    radioButtonValue,
    fileFromBackDocs,
    openDetails,
    setOpenDetails,
    phoneNumberValue,
    showTextField,
    fileFromBackAgreement,
    onSubmit,
  } = usePreview();

  if (isLoadingGetAddListingData) {
    return (
      <Box
        sx={{
          width: 'calc(100vw - 299px)',
          height: 'calc(100vh - 98px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <Box sx={container}>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenInfo, openInfo)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Property Information</Typography>
            {isMobile && (openInfo ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openInfo}>
            <CustomInput
              id={'property-name'}
              label={'Property Name'}
              type={'text'}
              rest={{ ...register('propertyName') }}
              disabled={true}
            />
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenAddress, openAddress)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Address Details</Typography>
            {isMobile && (openAddress ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openAddress}>
            <CustomInput
              id={'full-address'}
              label={'Property Full Address'}
              type={'text'}
              rest={{ ...register('fullAddress') }}
              disabled={true}
            />
            <Box sx={rowContainer}>
              <CustomInput
                id={'street'}
                label={'Street'}
                type={'text'}
                disabled={true}
                rest={{ ...register('street') }}
              />
              <CustomInput id={'city'} label={'City'} type={'text'} disabled={true} rest={{ ...register('city') }} />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput id={'state'} label={'State'} type={'text'} disabled={true} rest={{ ...register('state') }} />
              <CustomInput
                id={'zipCode'}
                label={'ZIP Code'}
                type={'text'}
                disabled={true}
                rest={{ ...register('zipCode') }}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'country'}
                label={'Country'}
                type={'text'}
                disabled={true}
                rest={{ ...register('country') }}
              />
              <CustomInput
                id={'county'}
                label={'County'}
                type={'text'}
                disabled={true}
                rest={{ ...register('county') }}
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDetail, openDetail)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Property Detail</Typography>
            {isMobile && (openDetail ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openDetail}>
            <Box sx={rowContainer}>
              <CustomSelect
                control={control}
                rest={{
                  ...register('propertyType'),
                }}
                label={'Property Type'}
                options={dataAllProperties?.type}
                disabled={true}
              />
              {role === 'commercial' ? (
                <CustomInput
                  id={'usable-sqft'}
                  label={'Usable Sq FT'}
                  type={'text'}
                  rest={{ ...register('usableSqFT') }}
                  disabled={true}
                />
              ) : (
                <CustomSelect
                  rest={{
                    ...register('occupancy'),
                  }}
                  label={'Occupancy'}
                  options={dataAllProperties?.ocuppancy}
                  control={control}
                  disabled={true}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'total-sqft'}
                label={'Total Sq FT'}
                type={'text'}
                rest={{ ...register('totalSqFt') }}
                disabled={true}
              />
              {role === 'commercial' ? (
                <CustomInput
                  id={'units'}
                  label={'Units'}
                  type={'text'}
                  rest={{ ...register('units') }}
                  disabled={true}
                />
              ) : (
                <CustomInput
                  id={'bathroom'}
                  label={'Bathroom'}
                  type={'text'}
                  rest={{ ...register('bathroom') }}
                  disabled={true}
                />
              )}
            </Box>
            {role === 'residential' && (
              <Box sx={rowContainer}>
                <CustomInput
                  id={'bedroom'}
                  label={'Bedroom'}
                  type={'text'}
                  disabled={true}
                  rest={{ ...register('bedroom') }}
                />
                <CustomInput
                  id={'yearBuilt'}
                  label={'Year Built'}
                  type={'text'}
                  disabled={true}
                  rest={{ ...register('yearBuilt') }}
                />
              </Box>
            )}
            <Box sx={rowContainer}>
              <CustomInput
                id={'lot-size'}
                label={'Lot Size'}
                type={'text'}
                disabled={true}
                rest={{ ...register('lotSize') }}
                endAdornment={<CustomSwitcherCheckbox value={switchValue} changeValue={handleChangeSwitch} />}
                pr="5px"
              />
              {role === 'commercial' ? (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('buildingClass'),
                  }}
                  label={'Building Class'}
                  options={dataAllProperties?.buildingClass}
                  disabled={true}
                />
              ) : (
                <CustomSelect
                  label={'Parking'}
                  control={control}
                  rest={{
                    ...register('parking'),
                  }}
                  options={dataAllProperties?.parking}
                  disabled={true}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              {role === 'commercial' ? (
                <CustomInput
                  id={'stories'}
                  label={'Stories'}
                  type={'text'}
                  rest={{ ...register('stories') }}
                  disabled={true}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('style'),
                  }}
                  label={'Style'}
                  options={dataAllProperties?.style}
                  disabled={true}
                />
              )}
              {role === 'commercial' ? (
                <CustomInput
                  id={'parking-spaces'}
                  label={'Parking Spaces'}
                  type={'text'}
                  rest={{ ...register('parkingSpaces') }}
                  disabled={true}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('heating'),
                  }}
                  label={'Heating'}
                  options={dataAllProperties?.heating}
                  disabled={true}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              {role === 'commercial' ? (
                <CustomInput
                  id={'zoning'}
                  label={'Zoning'}
                  type={'text'}
                  rest={{ ...register('zoning') }}
                  disabled={true}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('cooling'),
                  }}
                  label={'Cooling'}
                  options={dataAllProperties?.cooling}
                  disabled={true}
                />
              )}
              {role === 'commercial' ? (
                <CustomInput
                  id={'year-renovated'}
                  label={'Year Renovated'}
                  type={'text'}
                  rest={{ ...register('yearRenovated') }}
                  disabled={true}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('cooling'),
                  }}
                  label={'Cooling'}
                  options={dataAllProperties?.cooling}
                  disabled={true}
                />
              )}
            </Box>
            <Box sx={role === 'commercial' ? halfRowContainer : rowContainer}>
              {role === 'commercial' ? (
                <CustomInput
                  id={'year-built'}
                  label={'Year Built'}
                  type={'text'}
                  rest={{ ...register('yearBuilt') }}
                  disabled={true}
                />
              ) : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('construction'),
                  }}
                  label={'Construction'}
                  disabled={true}
                />
              )}
              {role === 'commercial' ? null : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('foundation'),
                  }}
                  label={'Foundation'}
                  options={dataAllProperties?.foundation}
                  disabled={true}
                />
              )}
            </Box>
            <Box sx={halfRowContainer}>
              {role === 'commercial' ? null : (
                <CustomSelect
                  control={control}
                  rest={{
                    ...register('basement'),
                  }}
                  label={'Basement'}
                  options={dataAllProperties?.basement}
                  disabled={true}
                />
              )}
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDesc, openDesc)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>
              Marketing/Property Description
            </Typography>
            {isMobile && (openDesc ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openDesc}>
            <TextField
              multiline={true}
              disabled={true}
              type={'text'}
              {...register('marketingDesc')}
              sx={{
                width: '100%',
                height: '243px',
                '& .MuiInputBase-root ': {
                  height: '243px !important',
                  '& textarea': {
                    height: '243px !important',
                    padding: '12px 0',
                  },
                },
              }}
            />
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenPrice, openPrice)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Price</Typography>
            {isMobile && (openPrice ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openPrice}>
            <Box
              sx={{
                mb: '11px',
                '@media screen and (max-width: 500px)': {
                  mb: 0,
                },
              }}
            >
              <Box sx={rowContainer}>
                {checked ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '25px',
                      width: '100%',
                      '@media screen and (max-width: 500px)': {
                        flexDirection: 'column',
                        gap: 0,
                      },
                    }}
                  >
                    <CustomInput
                      id={'min-price'}
                      label={'Min Price'}
                      type={'text'}
                      rest={{ ...register('minPrice') }}
                      disabled={true}
                    />
                    <CustomInput
                      id={'max-price'}
                      label={'Max Price'}
                      type={'text'}
                      rest={{ ...register('maxPrice') }}
                      disabled={true}
                    />
                  </Box>
                ) : (
                  <CustomInput
                    id={'price'}
                    label={'Price'}
                    type={'text'}
                    rest={{ ...register('price') }}
                    disabled={true}
                  />
                )}
                {isMobilePhone && (
                  <FormControlLabel
                    control={<Checkbox value={checked} checked={checked} disabled />}
                    label="Unpriced"
                    sx={{
                      m: 0,
                      display: 'flex',
                      gap: '12px',
                      width: 'fit-content',
                      mb: '11px',
                      '& .MuiButtonBase-root': { padding: 0 },
                    }}
                    {...register('unpriced')}
                  />
                )}
                <CustomInput
                  id={'due-diligence'}
                  label={'Due Diligence'}
                  type={'text'}
                  rest={{ ...register('dueDeligence') }}
                  disabled={true}
                />
              </Box>
              {!isMobilePhone && (
                <FormControlLabel
                  control={<Checkbox value={checked} checked={checked} disabled />}
                  label="Unpriced"
                  sx={{
                    m: 0,
                    display: 'flex',
                    gap: '12px',
                    width: 'fit-content',
                    '& .MuiButtonBase-root': { padding: 0 },
                  }}
                  {...register('unpriced')}
                />
              )}
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'inspection-date'}
                label={'Inspection Date'}
                type={'text'}
                rest={{ ...register('inspectionDate') }}
                disabled={true}
              />
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  bottom: '25px',
                  '@media screen and (max-width: 500px)': {
                    bottom: 0,
                  },
                }}
              >
                <CustomInput
                  id={'closing-date'}
                  label={'Closing Date'}
                  type={'text'}
                  value={closingResDate}
                  rest={{ ...register('closingDate') }}
                  disabled={true}
                  endAdornment={<CalendarMonthOutlinedIcon />}
                />
              </Box>
            </Box>
            <Box sx={halfRowContainer}>
              <CustomSelect
                control={control}
                rest={{
                  ...register('financialAccepted'),
                }}
                label={'Financial Accepted'}
                options={dataAllProperties?.financialAccepted}
                isMulti={true}
                disabled={true}
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenBrochure, openBrochure)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Add Brochure</Typography>
            {isMobile && (openBrochure ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openBrochure}>
            {fileFromBack !== undefined && fileFromBack !== '' && (
              <Box
                sx={{
                  border: '1px dashed grey',
                  height: '40px',
                  paddingLeft: '16px',
                  borderRadius: '10px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '50%',

                  '@media screen and (max-width: 500px)': {
                    width: '100%',
                  },
                }}
              >
                <FileIcon sx={{ marginRight: '16px' }} />
                {fileFromBack !== undefined ? (
                  fileFromBack?.length > 50 ? (
                    `${fileFromBack.substring(0, 50)} ...`
                  ) : (
                    fileFromBack
                  )
                ) : (
                  <Typography>No file uploaded</Typography>
                )}
              </Box>
            )}
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDescInvest, openDescInvest)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Investment Description</Typography>
            {isMobile && (openDescInvest ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openDescInvest}>
            <TextField
              multiline={true}
              disabled={true}
              type={'text'}
              {...register('investmentDesc')}
              sx={{
                width: '100%',
                mb: '21px',
                '& .MuiInputBase-root ': {
                  height: '243px !important',
                  '& textarea': {
                    height: '243px !important',
                    padding: '12px 0',
                  },
                },
              }}
            />
            {role === 'residential' ? (
              <>
                <Box sx={rowContainer}>
                  <CustomSelect
                    label={'Investment Type'}
                    options={dataAllProperties?.investmentType}
                    control={control}
                    rest={{
                      ...register('investmentType'),
                    }}
                    isMulti={true}
                    disabled={true}
                  />
                  <CustomInput
                    id={'after-repair'}
                    label={'After Repair Value (ARV)'}
                    type={'text'}
                    rest={{ ...register('afterRepairValue') }}
                    disabled={true}
                  />
                </Box>
                <Box sx={rowContainer}>
                  <CustomInput
                    id={'estimated-repairs'}
                    label={'Estimated Repairs Cost'}
                    type={'text'}
                    rest={{ ...register('estimatedRepairsCost') }}
                    disabled={true}
                  />
                  <CustomInput
                    id={'estimated-profit'}
                    label={'Estimated Profit'}
                    type={'text'}
                    rest={{ ...register('estimatedProfit') }}
                    disabled={true}
                  />
                </Box>
                <Box sx={rowContainer}>
                  <CustomInput
                    id={'estimated-time'}
                    label={'Estimated Time for Repairs (Days)'}
                    type={'text'}
                    rest={{ ...register('estimatedTimeForRepairs') }}
                    disabled={true}
                  />
                  {role === 'residential' && (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '25px',
                        width: '100%',
                        '@media screen and (max-width: 500px)': {
                          flexDirection: 'column',
                          gap: 0,
                        },
                      }}
                    >
                      <CustomInput
                        id={'rental-min'}
                        label={'Rental Income Potential Min '}
                        type={'text'}
                        rest={{ ...register('rentalIncomePotentialMin') }}
                        disabled={true}
                      />
                      <CustomInput
                        id={'rental-max'}
                        label={'Rental Income Potential Max'}
                        type={'text'}
                        rest={{ ...register('rentalIncomePotentialMax') }}
                        disabled={true}
                      />
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  gap: '30px',
                  '@media screen and (max-width: 500px)': {
                    flexDirection: 'column',
                    gap: 0,
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                  <Typography sx={{ width: '100%', fontSize: '20px', fontWeight: 600, color: black2 }}>
                    Actual
                  </Typography>
                  <Box>
                    <CustomInput
                      id={'cap-rate-actual'}
                      label={'Cap Rate'}
                      type={'text'}
                      rest={{ ...register('capRate') }}
                      disabled={true}
                    />
                    <CustomInput
                      id={'income-actual'}
                      label={'Income'}
                      type={'text'}
                      rest={{ ...register('income') }}
                      disabled={true}
                    />
                    <CustomInput
                      id={'expenses-actual'}
                      label={'Expenses'}
                      type={'text'}
                      rest={{ ...register('expenses') }}
                      disabled={true}
                    />
                    <CustomInput
                      id={'noi-actual'}
                      label={'NOI'}
                      type={'text'}
                      rest={{ ...register('noi') }}
                      disabled={true}
                    />
                    <CustomSelect
                      label={'Investment Type'}
                      options={dataAllProperties?.investmentType}
                      control={control}
                      rest={{
                        ...register('investmentType'),
                      }}
                      isMulti={true}
                      disabled={true}
                    />
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                  <Typography sx={{ width: '100%', fontSize: '20px', fontWeight: 600, color: black2 }}>
                    Pro Forma
                  </Typography>
                  <Box>
                    <CustomInput
                      id={'cap-rate-pro-forma'}
                      label={'Cap Rate'}
                      type={'text'}
                      rest={{ ...register('capRateProForma') }}
                      disabled={true}
                    />
                    <CustomInput
                      id={'income-pro-forma'}
                      label={'Income'}
                      type={'text'}
                      rest={{ ...register('incomeProForma') }}
                      disabled={true}
                    />
                    <CustomInput
                      id={'expenses-pro-forma'}
                      label={'Expenses'}
                      type={'text'}
                      rest={{ ...register('expensesProForma') }}
                      disabled={true}
                    />
                    <CustomInput
                      id={'noi-actual'}
                      label={'NOI'}
                      type={'text'}
                      rest={{ ...register('noiProForma') }}
                      disabled={true}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenCondition, openCondition)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Property Condition</Typography>
            {isMobile && (openCondition ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openCondition}>
            <Box sx={rowContainer}>
              <CustomInput
                id={'roof'}
                label={'Roof condition'}
                type={'text'}
                rest={{ ...register('roofCondition') }}
                disabled={true}
              />
              <CustomInput
                id={'heating'}
                label={'Heating Condition'}
                type={'text'}
                rest={{ ...register('heatingCondition') }}
                disabled={true}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'cooling'}
                label={'Cooling Condition'}
                type={'text'}
                rest={{ ...register('coolingCondition') }}
                disabled={true}
              />
              <CustomInput
                id={'construction'}
                label={'Construction Condition'}
                type={'text'}
                rest={{ ...register('constructionCondition') }}
                disabled={true}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'foundation'}
                label={'Foundation Condition'}
                type={'text'}
                rest={{ ...register('foundationCondition') }}
                disabled={true}
              />
              <CustomInput
                id={'basement'}
                label={'Basement Condition'}
                type={'text'}
                rest={{ ...register('basementCondition') }}
                disabled={true}
              />
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenPhoto, openPhoto)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Add Photo</Typography>
            {isMobile && (openPhoto ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openPhoto} className="d-flex flex-wrap">
            {dataGetAddListingData?.gallery?.photos.map((item: any) => (
              <Box
                sx={{
                  border: '1px dashed grey',
                  height: '100px',
                  width: '100px',
                  borderRadius: '10px',
                  marginBottom: '10px',
                  backgroundImage: `url(https://files.urexc.com/${item})`,
                }}
                key={item}
              ></Box>
            ))}
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenVideo, openVideo)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Add Video URL</Typography>
            {isMobile && (openVideo ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openVideo}>
            <CustomInput
              id={'video'}
              label={'Video'}
              type={'text'}
              rest={{ ...register('videoUrl') }}
              disabled={true}
            />
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenAgreement, openAgreement)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>
                Confidentiality Agreement
              </Typography>
            </Box>
            {isMobile && (openAgreement ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openAgreement}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {showTextField ? (
                <TextField
                  multiline={true}
                  disabled={true}
                  type={'text'}
                  {...register('desc')}
                  sx={{
                    width: '100%',
                    height: '243px',
                    '& .MuiInputBase-root ': {
                      height: '243px !important',
                      '& textarea': {
                        height: '243px !important',
                        padding: '12px 0',
                      },
                    },
                  }}
                />
              ) : (
                <>
                  {fileFromBackAgreement !== undefined && fileFromBackAgreement !== '' && (
                    <Box
                      sx={{
                        border: '1px dashed grey',
                        height: '40px',
                        paddingLeft: '16px',
                        borderRadius: '10px',
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '50%',

                        '@media screen and (max-width: 500px)': {
                          width: '100%',
                        },
                      }}
                    >
                      <FileIcon sx={{ marginRight: '16px' }} />
                      {fileFromBackAgreement !== undefined ? (
                        fileFromBackAgreement?.length > 50 ? (
                          `${fileFromBackAgreement.substring(0, 50)} ...`
                        ) : (
                          fileFromBackAgreement
                        )
                      ) : (
                        <Typography>No file uploaded</Typography>
                      )}
                    </Box>
                  )}
                </>
              )}
              <RadioGroup
                {...register('status')}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                }}
              >
                <FormControlLabel
                  control={<BpRadio checked={radioButtonValue === 0} disabled={true} />}
                  label={'Public'}
                  value={0}
                  sx={radioButtonValue === 0 ? checkedRadioBtn : radioBtn}
                />
                <FormControlLabel
                  control={<BpRadio checked={radioButtonValue === 1} disabled={true} />}
                  label={'Private'}
                  value={1}
                  sx={radioButtonValue === 1 ? checkedRadioBtn : radioBtn}
                />
                <FormControlLabel
                  control={<BpRadio checked={radioButtonValue === 2} disabled={true} />}
                  label={'User Approval'}
                  value={2}
                  sx={radioButtonValue === 2 ? checkedRadioBtn : radioBtn}
                />
              </RadioGroup>
            </Box>
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenConfigDocs, openConfigDocs)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>
              Add Confidential Documents
            </Typography>
            {isMobile && (openConfigDocs ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openConfigDocs}>
            {fileFromBackDocs !== undefined &&
              fileFromBackDocs !== '' &&
              fileFromBackDocs.map((item: any) => (
                <Box
                  key={item}
                  sx={{
                    border: '1px dashed grey',
                    height: '40px',
                    paddingLeft: '16px',
                    borderRadius: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '50%',

                    '@media screen and (max-width: 500px)': {
                      width: '100%',
                    },
                  }}
                >
                  <FileIcon sx={{ marginRight: '16px' }} />
                  {item !== undefined ? (
                    item?.name.length > 50 ? (
                      `${item.name.substring(0, 50)} ...`
                    ) : (
                      item.name
                    )
                  ) : (
                    <Typography>No file uploaded</Typography>
                  )}
                </Box>
              ))}
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenDetails, openDetails)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Listing Person Details</Typography>
            {isMobile && (openDetails ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openDetails}>
            <Box sx={rowContainer}>
              <CustomInput id={'name'} label={'Name'} type={'text'} rest={{ ...register('name') }} disabled={true} />
              <CustomInput
                id={'lastname'}
                label={'Last Name'}
                type={'text'}
                rest={{ ...register('lastName') }}
                disabled={true}
              />
            </Box>
            <Box sx={rowContainer}>
              <CustomInput
                id={'phone-number'}
                label={'Phone Number'}
                type={'text'}
                value={phoneNumberValue}
                inputProps={{ maxLength: 14 }}
                rest={{
                  ...register('phoneNumber'),
                }}
                disabled={true}
              />
              <CustomInput id={'email'} label={'Email'} type={'text'} rest={{ ...register('email') }} disabled={true} />
            </Box>
          </Collapse>
        </Box>
        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end', mb: '15px' }}>
          <Button sx={{ width: '213px', height: '48px', color: white, background: gray8 }}>Save to draft</Button>
          <Button sx={{ width: '213px', height: '48px', color: white }} type={'submit'}>
            Publish
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Preview;

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const MailIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 22 16"
      sx={{
        height: '16px !important',
        width: '22px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path d="M6 5L11 8.5L16 5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1 13V3C1 1.89543 1.89543 1 3 1H19C20.1046 1 21 1.89543 21 3V13C21 14.1046 20.1046 15 19 15H3C1.89543 15 1 14.1046 1 13Z"
        stroke="white"
        strokeWidth="1.6"
      />
    </SvgIcon>
  );
};

export default MailIcon;

/* eslint-disable react/react-in-jsx-scope */
import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthPage } from 'app/pages/Authorization/AuthPage';
import { ROUTES } from 'app/constants/routes';
import HomePage from 'app/pages/HomePage';
import { useAuth } from 'app/pages/Authorization/hook/AuthProvider';

const AppRoutes: FC = () => {
  const {
    state: { isAuth },
  } = useAuth();

  return (
    <Routes>
      <Route path={ROUTES.root} element={<HomePage />} />
      {isAuth ? (
        <>
          <Route path="/*" element={<PrivateRoutes />} />
        </>
      ) : (
        <>
          <Route path="auth/*" element={<AuthPage />} />
          <Route path="*" element={<Navigate to="/auth" />} />
        </>
      )}
    </Routes>
  );
};

export { AppRoutes };

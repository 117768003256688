import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAccountSetting = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <g clipPath="url(#AccountSetting_svg__a)">
      <mask
        id="AccountSetting_svg__b"
        width={18}
        height={19}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="currentColor" d="M18 .74H0v18h18z" />
      </mask>
      <g fill="currentColor" mask="url(#AccountSetting_svg__b)">
        <path d="M.465 16.253v2.104c0 .212.17.361.382.382h11.37c.212 0 .361-.17.382-.382v-2.104a5.62 5.62 0 0 0-4.059-5.398 4.06 4.06 0 0 0 1.7-1.785c.32.255.68.446 1.042.574v.467c0 .531.425.978.977.978h.85a.975.975 0 0 0 .978-.978v-.467a4.2 4.2 0 0 0 1.169-.66l.404.235c.467.255 1.062.106 1.317-.362l.425-.722a.98.98 0 0 0-.361-1.34l-.404-.233a3.5 3.5 0 0 0 0-1.317l.404-.234a.95.95 0 0 0 .361-1.318c-.021-.021-.085-.127-.425-.722a.973.973 0 0 0-1.317-.362l-.404.234a4 4 0 0 0-1.169-.659v-.467a.975.975 0 0 0-.977-.978h-.85a.975.975 0 0 0-.978.978v.467a4.2 4.2 0 0 0-1.169.66l-.404-.235c-.467-.255-1.062-.106-1.317.362l-.298.51a3.9 3.9 0 0 0-1.551-.298c-2.253 0-4.102 1.807-4.102 4.06 0 1.487.808 2.868 2.104 3.59a5.64 5.64 0 0 0-4.08 5.42m8.543-12.9c.064-.106.191-.149.319-.085l.637.361a.38.38 0 0 0 .447-.042 3.25 3.25 0 0 1 1.338-.765.39.39 0 0 0 .277-.361v-.723c0-.127.106-.234.233-.234h.85c.128 0 .234.107.234.234v.723c0 .17.107.318.277.36.488.15.956.405 1.338.766a.38.38 0 0 0 .447.042l.637-.36c.106-.065.255-.022.319.084l.425.723c.064.106.021.255-.085.297l-.638.362a.356.356 0 0 0-.17.403q.192.765 0 1.53a.36.36 0 0 0 .17.404l.638.361c.106.064.149.192.085.32l-.425.722c-.064.106-.191.149-.297.085l-.638-.361a.38.38 0 0 0-.446.042 3.25 3.25 0 0 1-1.34.765.364.364 0 0 0-.276.361v.723a.236.236 0 0 1-.233.234h-.85a.236.236 0 0 1-.234-.234v-.723a.364.364 0 0 0-.276-.36 3.14 3.14 0 0 1-1.254-.681 4.6 4.6 0 0 0 .127-1.041c0-1.445-.765-2.72-1.891-3.443zm-5.844 3.91c0-1.848 1.509-3.336 3.358-3.336 1.848 0 3.357 1.509 3.357 3.336 0 1.743-1.317 3.358-3.379 3.358-1.827 0-3.336-1.509-3.336-3.358m2.932 4.102h.85a4.89 4.89 0 0 1 4.888 4.888v1.721H1.21v-1.721a4.89 4.89 0 0 1 4.887-4.888" />
        <path d="M12.663 7.986a2.07 2.07 0 0 0 2.061-2.083 2.07 2.07 0 0 0-2.082-2.061 2.07 2.07 0 0 0-2.062 2.083c0 1.126.935 2.061 2.083 2.061m0-3.4c.743 0 1.317.616 1.317 1.339a1.329 1.329 0 0 1-2.656-.021c0-.744.595-1.34 1.339-1.318" />
      </g>
    </g>
    <defs>
      <clipPath id="AccountSetting_svg__a">
        <path fill="currentColor" d="M0 .24h18v19H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAccountSetting;

/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { useTable, ColumnInstance, Row } from 'react-table';
import { MyPropertiesLoading } from '../loading/MyPropertiesLoading';
import { MyPropertyHeaderColumn } from './columns/MyPropertyHeaderColumn';
import { MyPropertyRow } from './columns/MyPropertyRow';
import { contactsColumns } from './columns/_columns';
import { MyProperty } from '../../core/_models';
import { useQueryResponseData, useQueryResponseLoading } from '../../core/QueryResponseProvider';

const MyPropertyTable = () => {
  const contacts = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const data = useMemo(() => contacts, [contacts]);
  const columns = useMemo(() => contactsColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn: {
      minWidth: 100,
      maxWidth: 300,
    },
  });

  return (
    <div className="py-4 position-relative">
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 gs-0">
              {headers.map((column: ColumnInstance<MyProperty>) => (
                <MyPropertyHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-black fs-6" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<MyProperty>, i) => {
                prepareRow(row);

                return <MyPropertyRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <MyPropertiesLoading />}
    </div>
  );
};

export { MyPropertyTable };

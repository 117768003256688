/* eslint-disable react/react-in-jsx-scope */
import { QueryResponseProvider } from './core/QueryResponseProvider';
import { QueryRequestProvider } from './core/QueryRequestProvider';
import { ContactListQueryResponseProvider } from './core/ContactListQueryResponse';
import { ListViewProvider, useListView } from './core/ListViewProvider';
import { ContactHeader } from './components/header/Header';
import { ContactsTable } from './components/table/ContactTable';
import { Details } from './components/details/Details';
import { Subheader } from './components/subheader/subheader';

const ContactPage = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <ContactHeader />
      <Subheader />
      <ContactsTable />
      {itemIdForUpdate !== undefined && <Details />}
    </>
  );
};

const Contacts = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ContactListQueryResponseProvider>
          <ListViewProvider>
            <ContactPage />
          </ListViewProvider>
        </ContactListQueryResponseProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  );
};

export { Contacts };

import { PaletteMode } from '@mui/material';
import { createTheme, SimplePaletteColorOptions, ThemeOptions } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { black, blue1, red, gray, white, blue, gray1, gray11, gray9, blue7, black5, gray13 } from './colors';

export type ColorMode = PaletteMode | 'system';

export const px4 = '0.25rem';
export const px6 = '0.375rem';
export const px8 = '0.5rem';
export const px9 = '0.5625rem';
export const px10 = '0.625rem';
export const px12 = '0.75rem';
export const px14 = '0.875rem';
export const px16 = '1rem';
export const px18 = '1.125rem';
export const px20 = '1.25rem';
export const px22 = '1.375rem';
export const px24 = '1.5rem';
export const px28 = '1.75rem';
export const px32 = '2rem';
export const px34 = '2.125rem';
export const px36 = '2.25rem';
export const px38 = '2.375rem';
export const px40 = '2.5rem';
export const px42 = '2.625rem';
export const px48 = '3rem';
export const px52 = '3.25rem';
export const px54 = '3.375rem';
export const px64 = '4rem';
export const px66 = '4.125rem';
export const px92 = '5.75rem';
export const px100 = '6.25rem';
export const px109 = '6.8125rem';
export const px168 = '10.5rem';
export const px172 = '10.75rem';
export const px198 = '12.375rem';
export const px200 = '12.5rem';
export const px290 = '18.125rem';
export const px368 = '23rem';
export const px424 = '26.5rem';

declare module '@mui/material/styles' {
  interface Palette {
    normal: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    normal?: SimplePaletteColorOptions;
  }
}

export const getTheme = (_mode: PaletteMode | ColorMode) => {
  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: blue1,
        // light: blue5,
        // dark: blue5,
      },
      secondary: {
        main: black,
        light: black,
        dark: black,
      },
      background: {
        default: white,
        paper: white,
      },
      text: {
        primary: black,
        secondary: black,
      },
      // error: {
      //   main: 'rgb(255, 58, 32)',
      //   light: 'rgb(255, 235, 233)',
      //   dark: 'rgb(102, 23, 13)',
      // },
      // warning: {
      //   main: 'rgb(255, 153, 0)',
      //   light: 'rgb(255, 245, 229)',
      //   dark: 'rgb(102, 61, 0)',
      // },
      // success: {
      //   main: 'rgb(41, 216, 132)',
      //   light: 'rgb(234, 251, 243)',
      //   dark: 'rgb(16, 86, 53)',
      // },
      // normal: {
      //   main: 'rgb(255, 226, 71)',
      //   light: 'rgb(255, 252, 237)',
      //   dark: 'rgb(102, 90, 28)',
      // },
      // info: {
      //   main: 'rgb(92, 200, 255)',
      //   light: 'rgb(239, 249, 255)',
      //   dark: 'rgb(37, 80, 102)',
      // },
      // divider: dark ? "#fff" : '#333333',
      // action: {
      //     hoverOpacity: 0.3,
      // },
    },
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
      easing: {
        // This is the most common easing curve.
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        // Objects enter the screen at full velocity from off-screen and
        // slowly decelerate to a resting point.
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        // Objects leave the screen at full velocity. They do not decelerate when off-screen.
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        // The sharp curve is used by objects that may return to the screen at any time.
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
          focusRipple: false,
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          disableElevation: true,
          size: 'medium',
          color: 'primary',
        },
        styleOverrides: {
          root: {
            padding: '12px 30px',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            textTransform: 'unset',
            borderRadius: '10px',

            '& .MuiSvgIcon-root': {
              height: '18px',
              width: '18px',
            },
            '& .MuiButton-startIcon': {
              marginRight: '10px',
            },
            '& .MuiButton-endIcon': {
              marginLeft: '10px',
            },
          },
          textPrimary: {
            color: black,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          outlinedPrimary: {
            color: blue,
            backgroundColor: 'transparent',
            borderColor: gray9,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: black5,
            },
          },
          containedPrimary: {
            color: white,
            backgroundColor: blue,
            // padding: '6px 18px',
            '&:hover': {
              backgroundColor: blue7,
            },
          },
          outlinedSecondary: {
            color: white,
            backgroundColor: 'transparent',
            borderColor: white,
            '&:hover': {
              backgroundColor: 'transparent',
              color: white,
              borderColor: white,
            },
            // padding: '6px 18px',
          },
          containedSecondary: {
            color: white,
            backgroundColor: blue,
            // padding: '6px 18px',
            '&:hover': {
              backgroundColor: blue1,
            },
          },
          sizeSmall: {
            padding: '8px 20px',
            fontSize: '14px',
            lineHeight: '22px',
          },
          sizeMedium: {
            padding: '10px 30px',
            fontSize: '14px',
            lineHeight: '22px',
          },
          sizeLarge: {
            padding: '12px 30px',
            fontSize: '16px',
            lineHeight: '24px',
          },
        },
      },
      // MuiIconButton: {
      //   defaultProps: {
      //     size: 'medium',
      //     color: 'primary',
      //   },
      //   styleOverrides: {
      //     root: {
      //       padding: '15px',
      //       borderRadius: '12px',
      //       backgroundColor: white,
      //       '& .MuiSvgIcon-root': {
      //         height: '18px',
      //         width: '18px',
      //       },
      //     },
      //     colorPrimary: {
      //       border: `1px solid ${blue1}`,
      //     },
      //     colorSecondary: {
      //       border: `1px solid ${black2}`,
      //     },
      //   },
      // },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              '& fieldset': {
                borderColor: `${gray1}`,
              },

              '& .MuiInputAdornment-positionStart': {
                marginRight: '-4px',
              },
              '& .MuiInputAdornment-positionEnd': {
                marginLeft: '-4px',
              },

              '&.Mui-error': {
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                  border: `2px solid ${red}`,
                },
              },
            },
            '& .MuiInputBase-input': {
              padding: '12px 16px',
              color: black,
              backgroundColor: white,
              borderRadius: '12px',

              '&::placeholder': {
                color: gray,
                opacity: 'unset',
              },
            },
            '& .MuiInputLabel-root': {
              top: '3px',
              color: gray,

              '& .MuiInputLabel-asterisk': {
                color: red,
              },
            },
            '& .Mui-error': {
              color: red,
            },
            '& .MuiFormHelperText-root': {
              color: gray,
              '&.Mui-error': {
                color: red,
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            height: '48px',
            background: white,

            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: gray1,
            },

            '&.Mui-disabled': {
              input: {
                color: gray11,
                WebkitTextFillColor: gray11,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: gray11,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: gray11,
              },
            },

            '&.Mui-focused ': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: gray1,
              },

              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: gray1,
              },
            },

            '&.Mui-error ': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: red,
              },

              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: red,
              },
            },

            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderWidth: '1px',
              borderColor: gray9,
            },

            '& fieldset': {
              borderWidth: '1px',
              borderColor: gray1,
            },

            '& .MuiInputBase-input': {
              padding: '16px',
              color: black,
              boxSizing: 'border-box',
              fontFamily: 'Poppins',
              backgroundColor: white,

              '&::placeholder': {
                color: gray11,
                opacity: 'unset',
              },
            },

            input: {
              WebkitBoxShadow: '0 0 0 1000px white inset',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: gray,
            lineHeight: 1,
            '&.MuiInputLabel-shrink': {
              lineHeight: '1.473rem',
              width: 'fit-content',
              paddingRight: '5px',
              background: white,
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            borderColor: black,
            '&.Mui-checked': {
              color: blue,
            },
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: '-3px 3px 12px 0px #2D2C2C0F!important',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: gray,
            backgroundColor: white,
            '&.Mui-selected': {
              color: blue1,
              backgroundColor: white,
              '&:hover': {
                backgroundColor: white,
              },
            },
            '&:hover': {
              color: blue1,
              backgroundColor: white,
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          color: 'text.primary',
        },
        styleOverrides: {
          root: {
            fontFamily: 'Poppins',
          },
        },
      },
      // MuiTable: {
      //   defaultProps: {
      //     stickyHeader: true,
      //   },
      //   styleOverrides: {
      //     root: {
      //       borderCollapse: 'separate',
      //       borderSpacing: '0px 6px',
      //       minWidth: '650px',
      //     },
      //   },
      // },
      // MuiTableRow: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: white,
      //       border: `1px solid ${gray1}`,
      //       boxShadow: '-3px 3px 12px 0px #ADAFC017',
      //     },
      //   },
      // },
      // MuiTableHead: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: white,
      //       border: `1px solid ${gray1}`,
      //
      //       '& .MuiTableCell-root': {
      //         fontStyle: 'normal',
      //         fontWeight: 600,
      //         fontSize: '12px',
      //         lineHeight: '20px',
      //         color: gray7,
      //         padding: '11px',
      //         textTransform: 'uppercase',
      //         userSelect: 'none',
      //
      //         '&:first-of-type': {
      //           borderTopLeftRadius: '12px!important',
      //           borderBottomLeftRadius: '12px!important',
      //           paddingLeft: '22px',
      //         },
      //
      //         '&:last-of-type': {
      //           borderTopRightRadius: '12px!important',
      //           borderBottomRightRadius: '12px!important',
      //           paddingRight: '22px',
      //         },
      //       },
      //     },
      //   },
      // },
      // MuiTableCell: {
      //   styleOverrides: {
      //     root: {
      //       fontStyle: 'normal',
      //       fontWeight: 400,
      //       fontSize: '14px',
      //       lineHeight: '22px',
      //       color: black2,
      //       border: 'none',
      //       padding: '18px',
      //       width: 'calc(100% - 20px)',
      //       marginBottom: '40px',
      //       '&:first-of-type': {
      //         borderTopLeftRadius: '18px!important',
      //         borderBottomLeftRadius: '18px!important',
      //         paddingLeft: '24px',
      //       },
      //
      //       '&:last-of-type': {
      //         borderTopRightRadius: '18px!important',
      //         borderBottomRightRadius: '18px!important',
      //         paddingRight: '24px',
      //       },
      //     },
      //   },
      // },
      MuiPagination: {
        styleOverrides: {
          root: {
            '& ul': {
              '& li': {
                '&:first-of-type': {
                  // border: '1px solid red',
                  marginRight: '24px',
                },

                '&:last-of-type': {
                  // border: '1px solid green',
                  marginLeft: '24px',
                },
              },
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            padding: 0,
            minWidth: 'unset',
            '&.MuiPaginationItem-root': {
              backgroundColor: 'unset',
              padding: 0,
              minWidth: 'unset',

              '&.MuiPaginationItem-page': {
                color: gray,
                '&.Mui-selected': {
                  color: black,
                },
              },

              '&.MuiPaginationItem-previousNext': {
                width: '36px',
                height: '36px',
                borderRadius: '12px',
                background: white,

                '&.Mui-disabled': {
                  backgroundColor: gray,
                },
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: white,
            minHeight: 'unset',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            borderRadius: '11px',
            border: `1px solid transparent`,
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '22px',
            color: black,
            padding: '12px 24px',
            minHeight: 'unset',
            minWidth: '90px',
            textTransform: 'unset',

            '&.Mui-selected': {
              color: white,
              border: `1px solid ${blue1}`,
              backgroundColor: blue1,
              fontWeight: '600',
            },
          },
        },
      },
    },
    typography: {
      fontFamily: 'Poppins',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontStyle: 'normal',
        lineHeight: '30px',
        fontWeight: 700,
        fontSize: '28px',
        color: black,
      },
      h2: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: black,
      },
      h3: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: black,
      },
      // h4: {
      //   fontSize: px18,
      //   fontWeight: 'bold',
      //   lineHeight: px24,
      // },
      // body1: {
      //   fontSize: px16,
      //   fontWeight: 'normal',
      //   lineHeight: px22,
      // },
      // body2: {
      //   fontSize: px14,
      //   fontWeight: 'normal',
      //   lineHeight: px20,
      // },
      // subtitle1: {
      //   fontSize: px16,
      //   fontWeight: 'bold',
      //   lineHeight: px22,
      // },
      // subtitle2: {
      //   fontSize: px16,
      //   fontWeight: 'bold',
      //   lineHeight: px22,
      // },
      // caption: {
      //   fontSize: px12,
      //   fontWeight: 'normal',
      //   lineHeight: px16,
      //   letterSpacing: '0.4px',
      // },
      // overline: {
      //   fontSize: px12,
      //   fontWeight: 'normal',
      //   lineHeight: px16,
      //   letterSpacing: '1px',
      //   textTransform: 'uppercase',
      // },
    },
    shape: {
      borderRadius: 12,
    },
    shadows: Array(25).fill('none') as Shadows,
    // spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  } as ThemeOptions);
};

export { useTheme } from '@mui/material/styles';

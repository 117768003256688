import { string, object, number } from 'yup';

export const confidentialitySchema = object().shape({
  desc: string().when(['$showTextField', 'status'], ([showTextField, status], schema) =>
    showTextField && status !== 0 ? schema.required('This field is required') : schema.notRequired()
  ),
  status: number().required(),
  agreementFile: string().when('$showTextField', ([showTextField], schema) =>
    !showTextField ? schema.required('This field is required') : schema.notRequired()
  ),
});

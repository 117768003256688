import React from 'react';
import { loginBox } from './ConfirmPage.sx';
import { Alert, Box, Button, FormControl, OutlinedInput, Snackbar, Typography } from '@mui/material';
import UrexLogo from 'app/components/Icons/UrexLogo';
import { blue1, blue2, gray4 } from 'app/components/ThemeProvider/colors';
import FieldError from 'app/components/FieldError';
import { Link } from 'react-router-dom';
import BackArrow from 'app/components/Icons/BackArrow';
import AuthPropertyCard from 'app/components/AuthPropertyCard';
import OTPInput from 'react-otp-input';
import useConfirmPage from './useConfirmPage';
import styles from './otp.module.css';
import SetNewPass from 'app/pages/Authorization/SetNewPass';
import CountdownTimer from 'app/components/CountdownTimer';
import AuthCard from 'app/components/AuthCard';

interface Props {
  email: string;
  forgotPass: boolean;
}

const ConfirmPage = (props: Props) => {
  const { email, forgotPass } = props;
  const {
    otp,
    onFormSubmit,
    openSnackbar,
    setOpenSnackbar,
    dataOtp,
    resendOtp,
    handleGetOtp,
    error,
    dataResendOtp,
    emailFromToken,
  } = useConfirmPage({
    email,
    forgotPass,
  });

  return (
    <AuthCard
      open={openSnackbar}
      setOpen={setOpenSnackbar}
      data={dataOtp?.message !== undefined ? dataOtp : dataResendOtp}
      header={forgotPass ? 'Password Reset?' : 'Confirm Email'}
      desc={[
        'We sent a code to ',
        <Typography key={'confirm'} sx={{ fontWeight: 600, fontSize: '14px', color: blue2 }} component={'span'}>
          {email !== undefined ? email : emailFromToken}
        </Typography>,
      ]}
    >
      <form style={{ display: 'flex', flexDirection: 'column', gap: '3px' }} onSubmit={onFormSubmit}>
        <FormControl sx={{ height: '69px', width: '100%', mb: '15px' }}>
          <OTPInput
            value={otp}
            onChange={handleGetOtp}
            numInputs={6}
            containerStyle={{ justifyContent: 'space-between' }}
            renderSeparator={<span></span>}
            renderInput={(prop) => (
              <input {...prop} className={error.message.length > 0 ? styles.otpInputError : styles.otpInput} />
            )}
            shouldAutoFocus={true}
          />
          <FieldError error={error} />
        </FormControl>
        <CountdownTimer resend={resendOtp} />
        <Button type={'submit'} sx={{ mt: '30px', mb: '48px' }}>
          {forgotPass ? 'Continue' : 'Submit'}
        </Button>
      </form>
    </AuthCard>
  );
};

export default ConfirmPage;

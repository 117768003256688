import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DeleteIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      viewBox="0 0 18 18"
      sx={{
        width: '18px !important',
        height: '18px !important',
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <g clipPath="url(#clip0_2525_7850)">
        <path
          d="M10.6491 9.01474L17.6579 2.00568C18.114 1.54977 18.114 0.812628 17.6579 0.356718C17.202 -0.0991917 16.4649 -0.0991917 16.009 0.356718L8.9999 7.36577L1.99106 0.356718C1.53493 -0.0991917 0.798003 -0.0991917 0.342093 0.356718C-0.114031 0.812628 -0.114031 1.54977 0.342093 2.00568L7.35094 9.01474L0.342093 16.0238C-0.114031 16.4797 -0.114031 17.2168 0.342093 17.6728C0.5693 17.9002 0.868044 18.0144 1.16657 18.0144C1.4651 18.0144 1.76364 17.9002 1.99106 17.6728L8.9999 10.6637L16.009 17.6728C16.2364 17.9002 16.5349 18.0144 16.8334 18.0144C17.132 18.0144 17.4305 17.9002 17.6579 17.6728C18.114 17.2168 18.114 16.4797 17.6579 16.0238L10.6491 9.01474Z"
          fill="#919191"
        />
      </g>
      <defs>
        <clipPath id="clip0_2525_7850">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default DeleteIcon;

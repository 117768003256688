/* eslint-disable curly */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useResetPassService } from 'app/utils/services/authService';
import { useNavigate } from 'react-router-dom';

interface Props {
  email: string;
}

const useSetNewPass = (props: Props) => {
  const { email } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('change-pass-token');

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { isSuccessResetPass, dataResetPass, resetPassMutation } = useResetPassService();

  useEffect(() => {
    if (dataResetPass?.message !== undefined) {
      setOpenSnackbar(true);
    }

    if (dataResetPass?.token !== undefined) {
      localStorage.setItem('token', dataResetPass?.token);
      localStorage.setItem('refreshToken', dataResetPass?.refreshToken);
      localStorage.removeItem('change-pass-token');
      navigate('/');
    }
  }, [dataResetPass]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (data: any) => {
    resetPassMutation({
      data: { email, token, newPassword: data.newPassword },
      headers: { device: 'test', ipAddress: 'test' },
    });
  };

  const onFormSubmit = handleSubmit(onSubmit);

  return {
    showPassword,
    showConfirmPassword,
    openSnackbar,
    setOpenSnackbar,
    handleMouseDownPassword,
    handleClickShowConfirmPassword,
    handleClickShowPassword,
    handleMouseDownConfirmPassword,
    errors,
    register,
    onFormSubmit,
    dataResetPass,
  };
};

export default useSetNewPass;

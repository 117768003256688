import React from 'react';
import { Box, Button, CircularProgress, Collapse, Typography } from '@mui/material';
import { black2 } from 'app/components/ThemeProvider/colors';
import CustomFileUpload from 'app/components/CustomFileUpload';
import CustomInput from 'app/components/CustomInput';
import { btnSectionBox, container, draftBtn, saveBtn, sectionBox } from './Gallery.sx';
import useGallery from 'app/pages/AddListing/Gallery/useGallery';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { loaderBox } from 'app/pages/AddListing/MarketingDescription/MarketingDescription.sx';

const Gallery = () => {
  const {
    isLoadingContinue,
    isLoadingDraft,
    isMobile,
    openPhoto,
    setOpenPhoto,
    openVideo,
    setOpenVideo,
    onDrop,
    file,
    fileFromBack,
    handleClick,
    isLoadingGetAddListingData,
    onSubmit,
    register,
    getValues,
    handleDelete,
    errors,
    galleryError,
  } = useGallery();

  if (isLoadingGetAddListingData) {
    return (
      <Box sx={loaderBox}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={onSubmit} data-isdraft="false">
      <Box sx={container}>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenPhoto, openPhoto)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Add Photo</Typography>
            {isMobile && (openPhoto ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openPhoto}>
            <CustomFileUpload
              file={file}
              onDrop={onDrop}
              fileFromBack={fileFromBack}
              isMulti={true}
              deleteItem={handleDelete}
              extension={'img'}
              maxSize={5}
              error={galleryError}
            />
          </Collapse>
        </Box>
        <Box sx={sectionBox}>
          <Box
            onClick={() => handleClick(setOpenVideo, openVideo)}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 600, color: black2 }}>Add Video URL</Typography>
            {isMobile && (openVideo ? <ExpandMore /> : <ExpandLess sx={{ transform: 'rotate(90deg)' }} />)}
          </Box>
          <Collapse in={openVideo}>
            <CustomInput
              id={'video'}
              label={'Video'}
              type={'text'}
              error={errors.videoUrl}
              rest={{
                ...register('videoUrl', {
                  value: getValues('videoUrl') === undefined ? '' : getValues('videoUrl'),
                }),
              }}
            />
          </Collapse>
        </Box>
        <Box sx={btnSectionBox}>
          <Button
            sx={saveBtn}
            onClick={onSubmit}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingDraft && <CircularProgress size={20} />}
            data-isdraft="true"
          >
            Save to draft
          </Button>
          <Button
            sx={draftBtn}
            type={'submit'}
            disabled={isLoadingContinue || isLoadingDraft}
            startIcon={isLoadingContinue && <CircularProgress size={20} />}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Gallery;

import * as React from 'react';
import type { SVGProps } from 'react';
const SvgContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="M14.357 1.795H4.863c-1.11 0-2.009.9-2.01 2.01v.867H1.91a.402.402 0 1 0 0 .804h.94V8.11h-.94a.402.402 0 1 0 0 .804h.94v2.636h-.94a.402.402 0 1 0 0 .803h.94v2.636h-.94a.402.402 0 1 0 0 .804h.94v.867c0 1.11.9 2.009 2.009 2.01h9.494c1.11-.001 2.008-.9 2.009-2.01V3.805a2.006 2.006 0 0 0-2.005-2.009m1.205 14.867a1.21 1.21 0 0 1-1.205 1.205H4.863a1.21 1.21 0 0 1-1.206-1.206v-.867h.94a.402.402 0 0 0 0-.804h-.94v-2.636h.94a.402.402 0 1 0 0-.803h-.94V8.915h.94a.402.402 0 1 0 0-.804h-.94V5.476h.94a.402.402 0 1 0 0-.804h-.94v-.868A1.21 1.21 0 0 1 4.863 2.6h9.494c.665.002 1.203.54 1.205 1.205z"
    />
    <path
      fill="currentColor"
      d="M9.609 10.116a1.949 1.949 0 1 0 0-3.897 1.949 1.949 0 0 0 0 3.897m0-3.097a1.145 1.145 0 1 1 0 2.29 1.145 1.145 0 0 1 0-2.29M9.608 10.763c-1.671 0-2.981 1.354-2.981 3.086 0 .222.18.401.402.402h5.159c.221 0 .401-.18.402-.402 0-1.732-1.31-3.086-2.982-3.086m-2.145 2.684c.173-1.097 1.036-1.88 2.145-1.88 1.11 0 1.977.783 2.146 1.88z"
    />
  </svg>
);
export default SvgContacts;

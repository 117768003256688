import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReminders = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="M15.188 3.531H13.5V2.97a.563.563 0 0 0-1.125 0v.562h-6.75V2.97a.563.563 0 1 0-1.125 0v.562H2.813A1.687 1.687 0 0 0 1.125 5.22v10.125a1.69 1.69 0 0 0 1.688 1.687h12.375a1.687 1.687 0 0 0 1.687-1.687V5.219a1.687 1.687 0 0 0-1.687-1.688M2.813 4.656H4.5a.563.563 0 1 0 1.125 0h6.75a.563.563 0 0 0 1.125 0h1.688a.56.56 0 0 1 .562.563v1.125H2.25V5.219a.563.563 0 0 1 .563-.563m12.375 11.25H2.813a.56.56 0 0 1-.563-.562V7.469h13.5v7.875a.563.563 0 0 1-.562.562"
    />
    <path
      fill="currentColor"
      d="m10.289 9.882-1.851 1.856-.726-.731a.564.564 0 1 0-.799.799l1.125 1.125a.56.56 0 0 0 .8 0l2.25-2.25a.563.563 0 0 0-.4-.965.56.56 0 0 0-.4.166"
    />
  </svg>
);
export default SvgReminders;

import { SxProps } from '@mui/material';
import { white } from 'app/components/ThemeProvider/colors';

export const rightSide: SxProps = {
  width: 'calc(100vw - 241px)',
  ml: '241px',
  pl: '18px',
  pt: '30px',
  pr: '40px',

  '@media screen and (max-width: 992px)': {
    width: '100vw',
    ml: 0,
    pl: 0,
    pr: 0,
  },
};

export const header: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: '28px',
  '@media screen and (max-width: 992px)': {
    display: 'none',
  },
};

export const mobileHeader: SxProps = {
  display: 'none',

  '@media screen and (max-width: 992px)': {
    width: '100vw',
    height: 'fit-content',
    display: 'flex',
    position: 'fixed',
    top: '0',
    pt: '40px',
    pb: '20px',
    backgroundColor: white,
    zIndex: '10',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

import { SxProps } from '@mui/material';

export const drawerBody: SxProps = {
  width: '100%',
  maxWidth: '100%',
  padding: '20px',

  '@media only screen and (min-width: 576px)': {
    padding: '60px',
    maxWidth: '520px',
    width: '520px',
  },
};

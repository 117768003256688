import React from 'react';
import { AuthProvider } from './pages/Authorization/hook/AuthProvider';
import ErrorBoundary from './components/ErrorBoundary';
import { ThemeProvider } from 'app/components/ThemeProvider';
import { StyledEngineProvider } from '@mui/material';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'app/api/queryClient';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routing/AppRoutes';
import { APIProvider } from '@vis.gl/react-google-maps';
import { googleApiKey } from './constants/environments';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <StyledEngineProvider injectFirst>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <APIProvider apiKey={googleApiKey}>
                <BrowserRouter>
                  <ScrollToTop />
                  <AppRoutes />
                </BrowserRouter>
              </APIProvider>
            </QueryClientProvider>
          </AuthProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;

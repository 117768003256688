import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExport = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 18 20" {...props}>
    <path
      fill="currentColor"
      d="M6.525 7.012 8.25 5.287v7.2c0 .45.3.75.75.75s.75-.3.75-.75v-7.2l1.725 1.725c.3.3.75.3 1.05 0s.3-.75 0-1.05l-3-3c-.075-.075-.15-.15-.225-.15-.15-.075-.375-.075-.6 0-.075 0-.15.075-.225.15l-3 3c-.3.3-.3.75 0 1.05s.75.3 1.05 0m9.225 4.725c-.45 0-.75.3-.75.75v3c0 .45-.3.75-.75.75H3.75c-.45 0-.75-.3-.75-.75v-3c0-.45-.3-.75-.75-.75s-.75.3-.75.75v3c0 1.275.975 2.25 2.25 2.25h10.5c1.275 0 2.25-.975 2.25-2.25v-3c0-.45-.3-.75-.75-.75"
    />
  </svg>
);
export default SvgExport;

/* eslint-disable curly */
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  useAddListingDraft,
  useGetAddListingData,
  useAddListeningContinue,
} from 'app/utils/services/addListingService';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'app/pages/AddListing/PersonDetails/schema';

import useMediaQuery from '@mui/material/useMediaQuery';

const usePersonDetails = () => {
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const [openDetails, setOpenDetails] = useState(true);

  const isMobile = useMediaQuery('(max-width: 992px)');

  const location = useLocation();
  const role = location.pathname.split('/')[2];
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const sessionId = sessionStorage.getItem(role === 'commercial' ? 'commercialId' : 'residentialId');
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { isLoadingDraft, isSuccessDraft, dataDraft, draftMutation } = useAddListingDraft();
  const { isLoadingContinue, isSuccessContinue, dataContinue, continueMutation } = useAddListeningContinue();
  const { isLoadingGetAddListingData, dataGetAddListingData, dataGetAddListingError } = useGetAddListingData(
    role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
    sessionId,
    {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    }
  );

  useEffect(() => {
    if (!isMobile && !openDetails) {
      setOpenDetails(true);
    }
  }, [isMobile]);

  const handleClick = (set: any, value: boolean) => {
    if (isMobile) {
      set(!value);
    }
  };

  useEffect(() => {
    if (sessionId !== null && dataGetAddListingData !== undefined && dataGetAddListingData?.personalDetails !== null) {
      setValue('name', dataGetAddListingData?.personalDetails.name || '');
      setValue('lastName', dataGetAddListingData?.personalDetails.lastName || '');
      // setValue('phoneNumber', dataGetAddListingData?.personalDetails.phoneNumber);

      const value = dataGetAddListingData?.personalDetails.phoneNumber;

      const cleanedValue = value.substring(1).replace(/[^\d]/g, ''); // Remove non-digit characters

      let formattedString = '';
      if (cleanedValue.length <= 3) {
        formattedString = cleanedValue;
      } else if (cleanedValue.length <= 6) {
        formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3)}`;
      } else {
        formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
      }

      setPhoneNumberValue(formattedString || '');
      setValue('phoneNumber', formattedString || '');
      setValue('email', dataGetAddListingData?.personalDetails.email || '');
    }

    if ((dataGetAddListingError as any)?.status === 404) {
      sessionStorage.removeItem(role === 'commercial' ? 'commercialId' : 'residentialId');
      if (window !== undefined) window.location.reload();
    }
  }, [dataGetAddListingData]);

  useEffect(() => {
    if (
      (isSuccessDraft && dataDraft !== undefined && !dataDraft.message) ||
      (isSuccessContinue && dataContinue !== undefined && !dataContinue.message)
    ) {
      sessionStorage.setItem(
        role === 'commercial' ? 'commercialId' : 'residentialId',
        dataDraft ? String(dataDraft) : String(dataContinue)
      );
      navigate(`/add-listing/${role}/preview`);
    }
  }, [dataDraft, dataContinue]);

  const handleChangePhone = (e: any) => {
    const inputValue = e.target.value;
    const cleanedValue = inputValue.replace(/[^\d]/g, ''); // Remove non-digit characters

    let formattedString = '';

    if (cleanedValue.length <= 3) {
      formattedString = cleanedValue;
    } else if (cleanedValue.length <= 6) {
      formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3)}`;
    } else {
      formattedString = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
    }

    setPhoneNumberValue(formattedString);
    setValue('phoneNumber', formattedString || '');
  };

  const submitForm = (data: any, event: any) => {
    const element = event.target as HTMLElement;
    const isDraft = element?.dataset?.isdraft === 'true';
    const residentialFormData = new FormData();
    residentialFormData.append('PersonalDetails.Name', data.name);
    residentialFormData.append('PersonalDetails.LastName', data.lastName);
    residentialFormData.append('PersonalDetails.PhoneNumber', `1${phoneNumberValue.replace(/[()\s-]/g, '')}`);
    residentialFormData.append('PersonalDetails.Email', data.email);
    residentialFormData.append('Id', sessionId === null ? '' : sessionId);

    if (isDraft) {
      draftMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      continueMutation({
        data: residentialFormData,
        path: role === 'commercial' ? 'Commercial' : role === 'residential' && 'Resident',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  };

  const onSubmit = handleSubmit((data: any, event: any) => submitForm(data, event));

  return {
    isLoadingContinue,
    isLoadingDraft,
    isLoadingGetAddListingData,
    onSubmit,
    register,
    getValues,
    handleChangePhone,
    phoneNumberValue,
    errors,
    isMobile,
    handleClick,
    openDetails,
    setOpenDetails,
  };
};

export default usePersonDetails;

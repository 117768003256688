/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/button-has-type */
/* eslint-disable react/react-in-jsx-scope */
import { FC, ReactNode, useState } from 'react';
import { Button, Popover, Snackbar, Alert, CircularProgress } from '@mui/material';
import {
  MoreRotate,
  Flag,
  Danger,
  Calendar,
  Home,
  TickCircle,
  Setting4,
  Trash,
  Information,
} from 'app/components/SvgIcons';
import { Reminder } from '../../core/_models';
import { useMutation } from 'react-query';
import { deleteReminder, complatedReminder } from '../../core/_requests';
import { Error } from 'app/constants/models';
import { useQueryResponse } from '../../core/QueryResponseProvider';
import { useListView } from '../../core/ListViewProvider';
import { typesData, priorityData } from '../../core/_helper';

type Props = {
  reminder: Reminder;
};

const ReminderCard: FC<Props> = ({ reminder }) => {
  const { refetch } = useQueryResponse();
  const { setItemIdForUpdate, setIsOpenCreateDrawer, setItemIsUpdate, setIsOpenDetails } = useListView();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpenDetailsModal = () => {
    setItemIdForUpdate(reminder.id);
    setIsOpenDetails(true);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const deleteRm = useMutation((id: number) => deleteReminder(id), {
    onSuccess: () => {
      setIsSnackbarOpen(true);
      refetch();
    },
    onError: () => {
      setIsSnackbarOpen(true);
    },
  });

  const complatedRm = useMutation((id: number) => complatedReminder(id), {
    onSuccess: () => {
      setIsSnackbarOpen(true);
      refetch();
    },
    onError: () => {
      setIsSnackbarOpen(true);
    },
  });

  let renderStatus: ReactNode;
  if (deleteRm.isError || complatedRm.isError) {
    const errors = deleteRm.isError ? deleteRm.error : complatedRm.error;
    renderStatus = (errors as Error)?.errors?.status?.map((message, index) => (
      <span className="text-black fw-bold d-block" key={index}>
        {message}
      </span>
    ));
  } else {
    renderStatus = (
      <span className='className="text-black fw-bold d-block"'>
        Reminder {deleteRm.isSuccess ? 'removed' : 'complated'} successfuly
      </span>
    );
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={deleteRm.isError ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {renderStatus}
        </Alert>
      </Snackbar>
      <div className="w-100 border border-1 rounded p-2 p-md-5 mb-4">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex align-items-center justify-content-between w-100 w-md-auto mb-3">
            <Button className="bg-light-success text-success fw-bold order-2 order-md-1 ms-4 ms-md-0">
              {typesData[reminder.type]}
            </Button>
            <h2 className="ms-md-4 mb-0 fs-6 order-1 order-md-2" onClick={onOpenDetailsModal}>
              {reminder.subject}
            </h2>
          </div>

          <div className="d-flex align-items-center order-2 order-md-1 w-100 w-md-auto">
            <Button
              className={`text-white ${priorityData[reminder.priority].class}`}
              startIcon={priorityIcons[reminder.priority]}
            >
              {priorityData[reminder.priority].status}
            </Button>
            {reminder.status !== 3 && (
              <Button
                variant="outlined"
                size="medium"
                className="ms-4"
                startIcon={complatedRm.isLoading ? <CircularProgress size={16} /> : <Flag className="fs-5" />}
                onClick={async () => await complatedRm.mutateAsync(reminder.id)}
              >
                Complete task
              </Button>
            )}

            <div className="d-flex align-items-center ms-auto">
              <Button variant="text" className="p-0 ps-2 m-0 min-w-auto cursor-pointer fs-4 ms-3" onClick={handleClick}>
                <MoreRotate />
              </Button>
              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div className="d-flex flex-column bg-white gap-2 shadow">
                  <Button
                    variant="text"
                    size="medium"
                    className="d-flex justify-content-start"
                    startIcon={<Setting4 className="fs-6" />}
                    onClick={() => {
                      setItemIsUpdate(true);
                      setItemIdForUpdate(reminder.id);
                      setIsOpenCreateDrawer(true);
                      handleClose();
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="text"
                    size="medium"
                    className="d-flex justify-content-start"
                    startIcon={deleteRm.isLoading ? <CircularProgress size={12} /> : <Trash className="fs-6" />}
                    onClick={async () => await deleteRm.mutateAsync(reminder.id)}
                  >
                    Delete
                  </Button>
                </div>
              </Popover>
            </div>
          </div>

          <div className="d-flex flex-wrap align-items-center w-100 mt-5 order-1 order-md-2 mb-4">
            <div className="d-flex align-items-center me-5 order-1">
              <Calendar className="fs-6 text-muted me-2" />
              <span className="fs-6 text-muted">10.02.2022</span>
            </div>
            <div className="d-flex align-items-center me-5 ms-0 ms-md-5 order-4 order-md-2">
              <Home className="fs-6 text-muted me-2" />
              <span className="fs-6 text-muted">5908 STATE ROUTE 224 ALPINE NY 14805 - 9526 USA</span>
            </div>
            <div className="d-flex align-items-center me-5 ms-5 order-2 order-md-3">
              <TickCircle className="fs-6 text-muted me-2" />
              <span className="fs-6 text-muted">5908 AVE</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const priorityIcons = {
  1: <Flag className="fs-5" />,
  2: <Information className="fs-5" />,
  3: <Danger className="fs-5" />,
};

export { ReminderCard };

import { SxProps } from '@mui/material';
import { white } from 'app/components/ThemeProvider/colors';

export const propertyCard: SxProps = {
  background: `linear-gradient(180deg, #3D5C7B 0%, #071728 100%);`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '100vh',
  maxHeight: '100vh',
  width: '53%',
  maxWidth: '53%',
  p: '2px 22px 2px',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '10px',
  // display: 'flex',
  // flexWrap: 'wrap',
  overflowY: 'scroll',
  justifyItems: 'center',

  '@media screen and (max-width: 1330px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },

  '@media screen and (max-width: 886px)': {
    display: 'none',
  },
};

export const infoCard: SxProps = {
  width: '283px',
  height: '406px',
  backgroundColor: white,
  borderRadius: '20px',
  p: '10px 10px 46px 10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  mb: '60px',

  '&:nth-of-type(2n)': {
    position: 'relative',
    top: '150px',
  },

  '@media screen and (max-width: 1330px)': {
    '&:nth-of-type(2n)': {
      position: 'relative',
      top: '0',
    },
  },
};

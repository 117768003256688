/* eslint-disable curly */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Drawer, Box, TextField, CircularProgress } from '@mui/material';
import CustomInput from 'app/components/CustomInput';
import { drawerBody, drawerTextArea } from './DetailsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { SendEmail } from '../sendemail/SendEmail';
import { SendSMS } from '../sendsms/SendSMS';
import { useListView } from '../../core/ListViewProvider';
import { useQuery } from 'react-query';
import { getContactById } from '../../core/_requests';
import { isNotEmpty } from 'app/constants/helpers';
import { QUERIES } from 'app/constants/consts';
import logger from 'app/utils/logger';
import {
  Setting4,
  User,
  DirectSend,
  Sms,
  Notification,
  Tag,
  Direct,
  Call,
  Location,
  Building,
  Briefcase,
} from 'app/components/SvgIcons';

const Details = () => {
  const navigate = useNavigate();
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    setItemIsUpdate,
    setIsOpenDetails,
    isOpenDetails,
    setIsOpenCreateDrawer,
  } = useListView();
  const [isOpenSendEmail, setIsOpenSendEmail] = useState<boolean>(false);
  const [isOpenSendSMS, setIsOpenSendSMS] = useState<boolean>(false);

  const onAddReminder = () => navigate(`/dashboard/reminders?ContactId=${itemIdForUpdate}`);

  const onClose = () => {
    setItemIdForUpdate(undefined);
    setIsOpenDetails(false);
  };

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const {
    isLoading,
    data: contact,
    error,
  } = useQuery(
    `${QUERIES.CONTACT_LIST}-contact-${itemIdForUpdate}`,
    () => {
      return getContactById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        onClose();
        logger.error(err);
      },
    }
  );

  let renderDetails: ReactNode;
  if (!isLoading && !error && contact)
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-end mb-4">
          <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onClose}>
            <ClearIcon />
          </Button>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex align-items-center">
            <User className="fs-5" />
            <h3 className="fw-bold fs-5 text-black p-0 m-0 ms-2">{`${contact.name} ${contact.lastName}`}</h3>
          </div>
          <div className="d-flex align-items-center">
            <Button
              variant="text"
              className="p-0 m-0 ms-1 text-end"
              startIcon={<Setting4 className="fs-5" />}
              onClick={() => {
                setItemIsUpdate(true);
                setIsOpenCreateDrawer(true);
              }}
            >
              Edit
            </Button>
          </div>
        </div>

        <div className="row g-2 mb-4">
          <div className="col-12 col-sm-4">
            <Button
              className="bg-success text-nowrap w-100"
              size="small"
              endIcon={<DirectSend className="fs-5" />}
              onClick={() => setIsOpenSendEmail(true)}
            >
              Send Email
            </Button>
          </div>
          <div className="col-12 col-sm-4">
            <Button
              className="bg-info text-nowrap w-100"
              size="small"
              endIcon={<Sms className="fs-5" />}
              onClick={() => setIsOpenSendSMS(true)}
            >
              Send SMS
            </Button>
          </div>
          <div className="col-12 col-sm-4">
            <Button
              className="bg-warning text-nowrap w-100"
              size="small"
              endIcon={<Notification className="fs-5" />}
              onClick={onAddReminder}
            >
              Add Reminder
            </Button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Tag className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Tag</span>
          </div>
          <span className="fs-6 text-black ps-1">{contact.tags?.map(({ name }) => name).join(',')}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Direct className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Email</span>
          </div>
          <span className="fs-6 text-black ps-1">{contact.email}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Call className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Phone Number</span>
          </div>
          <span className="fs-6 text-black ps-1">{contact.phoneNumber}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Location className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Address</span>
          </div>
          <span className="fs-6 text-black ps-1">{contact.address}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Building className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Company Name</span>
          </div>
          <span className="fs-6 text-black ps-1">{contact.companyName}</span>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <div className="d-flex align-items-center">
            <Briefcase className="fs-5" />
            <span className="fs-6 text-black p-0 m-0 ms-2">Title</span>
          </div>
          <span className="fs-6 text-black ps-1">{contact.title}</span>
        </div>

        <div className="w-100 my-3">
          <TextField
            multiline={true}
            sx={drawerTextArea}
            placeholder="Add a note"
            label="Note"
            disabled
            value={contact.note}
          />
        </div>

        <div className="w-100 mt-4 mb-3">
          <CustomInput label="Related Deal" value="57th AVE" disabled />
        </div>

        <div className="w-100 d-flex flex-column">
          <h3 className="fs-5 text-black p-0 m-0 mb-2">Related Property</h3>

          <div className="p-3 border rounded d-flex">
            <div className="d-flex flex-column">
              <h4 className="fs-6 text-body-secondary">5908 STATE ROUTE 224 ALPINE NY 14805 - 9526 USA</h4>
              <small className="text-body-tertiary">Residential</small>
            </div>
          </div>
        </div>
      </Box>
    );
  else
    renderDetails = (
      <Box sx={drawerBody}>
        <div className="d-flex align-items-center justify-content-center h-100">
          <CircularProgress />
        </div>
      </Box>
    );

  return (
    <>
      <Drawer anchor="right" variant="temporary" open={isOpenDetails} onClose={onClose}>
        {renderDetails}
      </Drawer>
      <SendEmail isOpenDrawer={isOpenSendEmail} setIsOpenDrawer={setIsOpenSendEmail} />
      <SendSMS isOpenDrawer={isOpenSendSMS} setIsOpenDrawer={setIsOpenSendSMS} />
    </>
  );
};

export { Details };

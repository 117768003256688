import React from 'react';
import { Button, IconButton, InputAdornment } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import useSetNewPass from 'app/pages/Authorization/SetNewPass/useSetNewPass';
import CustomInput from 'app/components/CustomInput';
import AuthCard from 'app/components/AuthCard';

interface Props {
  email: string;
}

const SetNewPass = (props: Props) => {
  const { email } = props;
  const {
    openSnackbar,
    setOpenSnackbar,
    showPassword,
    showConfirmPassword,
    handleMouseDownPassword,
    handleClickShowConfirmPassword,
    handleClickShowPassword,
    handleMouseDownConfirmPassword,
    errors,
    register,
    onFormSubmit,
    dataResetPass,
  } = useSetNewPass({ email });

  return (
    <AuthCard
      open={openSnackbar}
      setOpen={setOpenSnackbar}
      data={dataResetPass}
      header={'Set new password'}
      desc={'Must be at least 8 chracter'}
    >
      <form style={{ display: 'flex', flexDirection: 'column', gap: '3px' }} onSubmit={onFormSubmit}>
        <CustomInput
          id={'set-pass'}
          label={'Password'}
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          rest={{ ...register('password') }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              </IconButton>
            </InputAdornment>
          }
        />
        <CustomInput
          id={'set-confirm-pass'}
          label={'Confirm Password'}
          type={showConfirmPassword ? 'text' : 'password'}
          error={errors.newPassword}
          rest={{ ...register('newPassword') }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              </IconButton>
            </InputAdornment>
          }
        />
        <Button type={'submit'} sx={{ mt: '10px', mb: '30px' }}>
          Reset Password
        </Button>
      </form>
    </AuthCard>
  );
};

export default SetNewPass;

import React, { useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { blue, white } from 'app/components/ThemeProvider/colors';
import HomePageCarousel from 'app/components/HomePageCarousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

const HomePageInfo = () => {
  const dynamicText = [
    { title: 'Property Intel & Owner Insights' },
    { title: 'Deal Mastery & Progress Tracking' },
    { title: 'Engagement & Lead Generation' },
    { title: 'Confidential Listing & Buyer Connect' },
    { title: 'Strategic Outreach & Networking' },
  ];

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const txts = document.querySelector('.animate-text').children;
    const txtsLen = txts.length;
    let index = 0;
    const textInTimer = 3000;
    const textOutTimer = 2800;

    function animateText() {
      for (let i = 0; i < txtsLen; i++) {
        txts[i].classList.remove('active', 'non-active');
      }
      txts[index].classList.add('active');

      setTimeout(() => {
        txts[index].classList.add('non-active');
      }, textOutTimer);

      setTimeout(() => {
        if (index === txtsLen - 1) {
          index = 0;
        } else {
          index++;
        }
        animateText();
      }, textInTimer);
    }

    animateText();
  }, []);

  const handleRedirect = (to: string) => {
    navigate(`/${to}`);
  };

  return (
    <Box
      sx={{
        mt: '115px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

        '& .slide-in': {
          display: 'none',
          color: white,
          fontSize: '60px',
          fontWeight: 500,
          lineHeight: 1.5,

          '@media screen and (max-width: 992px)': {
            fontSize: '30px',
          },

          '&.active': {
            display: 'block',
            animation: 'active .5s ease',
          },

          '&.non-active': {
            animation: 'non-active .5s ease',
          },
        },
        '@keyframes active': {
          '0%': {
            transform: 'translateY(100%)',
          },
          '100%': {
            transform: 'translateY(0%)',
          },
        },

        '@keyframes non-active': {
          '0%': {
            transform: 'translateY(0%)',
          },
          '100%': {
            transform: 'translateY(-100%)',
          },
        },
      }}
    >
      <Box sx={{ overflow: 'hidden', padding: '0 26px', textAlign: 'center' }} className={'animate-text'}>
        {dynamicText.map((item, index) => (
          <Typography variant="h1" className={'slide-in'} key={index}>
            {item.title}
          </Typography>
        ))}
      </Box>
      <Typography
        sx={{
          color: white,
          width: '70%',
          mt: '30px',
          textAlign: 'center',
          mb: '40px',
          fontSize: '12px',
          lineHeight: '22px',

          '@media screen and (max-width: 992px)': {
            fontSize: '10px',
          },
        }}
      >
        UREXC is a versatile real estate platform that combines confidential property listing options with in-depth data
        access and powerful deal management tools. It streamlines the real estate process for investors, agents, and
        owners, providing advanced analytics and targeted communication channels for efficient and informed
        decision-making. UREXC revolutionizes property dealings with a focus on privacy, insight, and connectivity.
      </Typography>
      <Box>
        <Button
          sx={{
            background: white,
            mb: '76px',
            color: blue,
            display: 'flex',
            gap: '10px',
            width: '294px',
            fontSize: '18px',
            lineHeight: '26px',
            '&:hover': { background: white },
          }}
          onClick={(event: React.MouseEvent<any>) => {
            setAnchorEl(event.currentTarget);
            setOpenMenu(true);
          }}
          id="basic-button"
          aria-controls={openMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
        >
          Add listing <ArrowForwardIosIcon />
        </Button>
        <Menu
          sx={{ '& .MuiPaper-root': { width: '294px', mt: '10px' } }}
          id="basic-menu"
          open={openMenu}
          anchorEl={anchorEl}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          onClose={() => setOpenMenu(false)}
        >
          <MenuItem
            onClick={() =>
              handleRedirect(token !== null ? 'add-listing/commercial/property-information' : 'auth/login')
            }
          >
            Commercial
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleRedirect(token !== null ? 'add-listing/residential/property-information' : 'auth/login')
            }
          >
            Residential
          </MenuItem>
        </Menu>
      </Box>
      <HomePageCarousel />
    </Box>
  );
};

export default HomePageInfo;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useLoginService } from 'app/utils/services/authService';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../hook/AuthProvider';

interface JwtPayload {
  user_role: string;
}

const useLogin = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { dataLogin, loginMutation } = useLoginService();
  const { updateState, state } = useAuth();

  useEffect(() => {
    if (dataLogin?.message !== undefined) {
      setOpenSnackbar(true);
    }

    if (dataLogin?.token !== undefined) {
      const userRole = jwtDecode(dataLogin?.token) as JwtPayload;
      updateState({
        ...state,
        token: dataLogin?.token,
        refreshToken: dataLogin?.refreshToken,
      });
      if (userRole.user_role === 'Guest') {
        updateState({
          ...state,
          token: dataLogin?.token,
          refreshToken: dataLogin?.refreshToken,
        });
        navigate('/auth/registration?confirm=email');
      } else {
        localStorage.setItem('token', dataLogin?.token);
        localStorage.setItem('refreshToken', dataLogin?.refreshToken);
        updateState({ ...state, isAuth: true });
        navigate('/');
      }
    }
  }, [dataLogin]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (data: any) => {
    loginMutation({
      data: { email: data.email, password: data.password },
      headers: { device: 'test', ipAddress: 'test' },
    });
  };

  const onFormSubmit = handleSubmit(onSubmit);

  return {
    onFormSubmit,
    errors,
    register,
    openSnackbar,
    setOpenSnackbar,
    dataLogin,
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
  };
};

export default useLogin;
